import { gql } from '@apollo/client';
import { Contract } from '../types/entities/contract';
import { Credential } from '../types/entities/credential';
import { LocalMotorist } from '../types/entities/localMotorist';

export type GlobalSearchContractEntityResponse = {
  _id: string;
  name1: string | null;
  name2: string | null;
  ospContractId: string;
  isEnabled: boolean;
  category: Contract['category'];
  contractCount: number;
  spacesAllocated: number | null;
  spacesOccupied: number | null;
  linkedContractId: string | null;
  productId: string;
  parkingId: string;
  tenantContracts: Array<{ _id: string }>;
  startDate: string;
  stopDate: string;
  credentials: Credential[];
  reference: string | null;
};

export type GlobalSearchResponse = {
  localMotorists: Array<{
    _id: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    address1: string | null;
    address2: string | null;
    zipcode: string | null;
    city: string | null;
    country: string | string;
    phone: string | null;
    lang: string | null;
    status: LocalMotorist['status'];
    plates: Credential[];
    uids: Credential[];
    cards: Credential[];
    linkedContractId: string | null;
    activeAndTotalCount: {
      active: number;
      total: number;
    };
  }>;
  contracts: Array<GlobalSearchContractEntityResponse>;
};

export type GlobalSearch = {
  content: string;
};

export const globalSearchGQL = gql`
  query globalSearch(
    $content: String!
    $linkedContractId: String
    $tenantCanCreateSubContract: Boolean
  ) {
    globalSearch(
      content: $content
      linkedContractId: $linkedContractId
      tenantCanCreateSubContract: $tenantCanCreateSubContract
    ) {
      localMotorists {
        _id
        firstName
        lastName
        email
        address1
        address2
        zipcode
        city
        country
        phone
        lang
        status
        plates {
          type
          provider
          value
          description
        }
        uids {
          type
          provider
          value
          description
        }
        cards {
          type
          provider
          value
          description
        }
        activeAndTotalCount {
          active
          total
        }
      }
      contracts {
        _id
        parkingId
        name1
        name2
        ospContractId
        isEnabled
        category
        contractCount
        spacesAllocated
        spacesOccupied
        productId
        startDate
        linkedContractId
        stopDate
        reference
        credentials {
          type
          provider
          value
          description
        }
        tenantContracts {
          _id
        }
      }
    }
  }
`;
