import { dispatch } from '@gimlite/router';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { MotoristsCreateFormType } from '../../form/motorists-create/motorists-create.form';
import {
  CreateSeveralMotoristsServiceContextType,
  createSeveralMotoristsService,
} from './createSeveralMotorists.machine';
import { ConfirmationStep } from './steps/confirmation/confirmation.step';
import { MotoristsStep } from './steps/motorists/motorists.step';
import { SummaryStep } from './steps/summary/summary.step';

export type CreateSeveralMotoristsContext = {
  parkingId: string | null;
  motorists: {
    created: MotoristsCreateFormType.Data.Created[];
  };
};

export const CreateSeveralMotoristsWizard = () => {
  const { t, lang } = useTranslation();

  const { parkingId } = useSelector(
    createSeveralMotoristsService,
    ({ context }: { context: CreateSeveralMotoristsServiceContextType }) =>
      context,
  );

  return (
    <Wizard<CreateSeveralMotoristsContext>
      config={{
        title: t(`createSeveralMotorists`),
      }}
      data={{
        defaultContext: {
          parkingId,
          motorists: {
            created: [],
          },
        },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          dispatch('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item
        config={{ title: t('motorists'), component: MotoristsStep }}
      />
      <Wizard.Item
        config={{ title: t('confirmation'), component: ConfirmationStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
};
