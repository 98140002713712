import { EventObject, assign, createMachine, interpret } from 'xstate';
import { CreateNewSubPoolContractContext } from './createNewSubPoolContract.wizard';

export type CreateNewSubPoolContractServiceContextType = {
  parkingId: CreateNewSubPoolContractContext['parkingId'];
  mode: CreateNewSubPoolContractContext['mode'];
  error: string | null;
};

const createNewSubPoolContractServiceContext: CreateNewSubPoolContractServiceContextType =
  {
    parkingId: null,
    mode: 'operator',
    error: null,
  };

const machine = createMachine({
  id: 'createNewSubPoolContract',
  initial: 'off',
  context: createNewSubPoolContractServiceContext,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
          actions: assign({
            parkingId: (
              _,
              {
                parkingId,
              }: EventObject & {
                parkingId: CreateNewSubPoolContractServiceContextType['parkingId'];
              },
            ) => parkingId,
            mode: (
              _,
              {
                mode,
              }: EventObject & {
                mode: CreateNewSubPoolContractServiceContextType['mode'];
              },
            ) => mode || createNewSubPoolContractServiceContext.mode,
          }),
        } as any,
      },
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
      },
    },
    failure: {
      on: {
        RETRY: {
          actions: assign({ error: null }),
          target: 'idle',
        },
      },
    },
  },
});

export const createNewSubPoolContractService = interpret(machine).start();
