import {
  BadgeType,
  Empty,
  FormType,
  Widget,
  Zone,
  useReadOf,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { incidentGql } from '../../common/gql/incident.gql';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { TechnicalEvent } from '../../common/types/entities/technical-events';
import { StringFn, parseTokens } from '../../common/utils/tokens-2-payload';
import { ProfilDetails } from '../../components/profil-details/profil-details.component';
import { IncidentAction } from '../action/incident.action';
import { IncidentLineType } from '../line/incident.line';
class Static {
  static tokenMap = {
    DATE: (payload: any, t: StringFn) =>
      DateTime.fromISO(payload.date).toFormat('dd.LL.yy HH:mm'),
    UUU: (payload: any, t: StringFn) =>
      t(`global-providers-${payload.credential?.provider?.toLowerCase()}`),
    RFID: (payload: any, t: StringFn) =>
      payload.credential?.value.toUpperCase(),
    EID: (payload: any, t: StringFn) => payload.credential?.value.toUpperCase(),
    PL: (payload: any, t: StringFn) => payload.credential?.value.toUpperCase(),
    PN: (payload: any, t: StringFn) => payload.spacesAllocated,
    NNE: (payload: any, t: StringFn) => payload.openedSessions?.length,
    NDAY: (payload: any, t: StringFn) =>
      payload.rules?.cycleControl?.pastDaysCount,
    NC: (payload: any, t: StringFn) => payload.contractId,
  };

  static statusBadgeColorMap: Record<
    IncidentLineType.Data.Status,
    BadgeType.Config.Color
  > = {
    information: 'disabled',
    warning: 'warn',
    alarm: 'error',
  };
}

export type IncidentDetailsProps = {
  widget?: {
    title?: string;
  };
  technicalEventId: string | null;
};

export const IncidentDetails = ({
  technicalEventId,
  widget,
}: IncidentDetailsProps) => {
  const { details, setId } = useReadOf<TechnicalEvent>({
    gql: incidentGql,
    wsSubscriptions: ['sessions:updated'],
    queryBuilder: (id) => ({ id: id }),
  });
  useEffect(() => setId(technicalEventId || undefined), [technicalEventId]);
  const { t } = useTranslation();

  const [form, setForm] = useState<FormType.Data.Value>();

  const badges = [
    {
      color:
        Static.statusBadgeColorMap[
          !!details && details.criticity ? details.criticity : 'information'
        ],
      text: t(`${details?.criticity || 'information'}`),
    },
  ];

  if (details && details.archived)
    badges.push({
      text: t('archived'),
      color: 'disabled',
    });
  useEffect(() => {
    if (!!details) {
      setForm({
        problem:
          t(`incident-title-${details?.key}`) +
          `${
            details?.payload?.accessPoint?.shortCode
              ? ` : ${t('access-point')} ${
                  details?.payload?.accessPoint?.shortCode
                }`
              : ''
          }`,
        description: parseTokens(
          t(`incident-${details?.key}`),
          {
            date: details?.createdAt,
            ...details?.payload,
          },
          Static.tokenMap,
          t,
        ),
      });
    }
  }, [details]);

  return (
    <Widget.Group
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
      }}
    >
      {details && technicalEventId && form ? (
        <Zone
          config={{
            zones: [['profil']],
            rows: ['min-content'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'profil' }}>
            <Widget>
              <ProfilDetails
                handleEvent={{
                  submit: (form) => setForm(form),
                }}
                data={{
                  icon: iconGlobalEntity['incident'],
                  form: form,
                  badges: badges,
                }}
                config={{
                  form: {
                    title1: {
                      name: 'problem',
                      placeholder: t('problem'),
                      edit: false,
                    },
                    description1: {
                      name: 'description',
                      placeholder: t('description'),
                      edit: false,
                    },
                  },

                  actions: (
                    <IncidentAction
                      config={{ size: 'xlarge' }}
                      data={{ technicalEventId, payload: details.payload }}
                    />
                  ),
                }}
              />
            </Widget>
          </Zone.Area>
        </Zone>
      ) : (
        <Empty
          config={{
            mode: { name: 'select', text: `${t('selectIncident')} ...` },
          }}
        ></Empty>
      )}
    </Widget.Group>
  );
};
