import { ApolloError } from '@apollo/client';
import { MotoristLine } from '@gimlite/osp/composition/line/motorist.line';
import { requestGQL, useCascade } from '@gimlite/watermelon';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateLocalMotorists,
  CreateLocalMotoristsResponse,
  createLocalMotoristGQL,
} from '../../../../common/gql/createLocalMotorist.gql';
import {
  SummaryForm,
  SummaryFormType,
} from '../../../../form/summary/summary.form';
import { CreateSeveralMotoristsContext } from '../../createSeveralMotorists.wizard';

export const SummaryStep = ({
  context: { motorists },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateSeveralMotoristsContext>) => {
  const { t, lang } = useTranslation();

  const [created, setCreated] = useState<SummaryFormType.Data.Line[]>();
  const [createdToExport, setCreatedExport] =
    useState<Array<SummaryFormType.Data.Created>>();

  const { isFinish } = useCascade<CreateLocalMotoristsResponse>(
    motorists.created.map(
      ({
          _id,
          email,
          firstName,
          lang,
          lastName,
          plate,
          sendInvitation,
          uid,
          visibleId,
        }) =>
        async () => {
          try {
            const response = (await requestGQL({
              params: {
                input: {
                  email,
                  firstName,
                  lastName,
                  lang,
                  plates: plate
                    ? [{ value: plate.toUpperCase(), description: null }]
                    : null,
                  uids: uid ? [{ value: uid, description: visibleId }] : null,
                  sendInvitation,
                },
              } as CreateLocalMotorists,
              gql: createLocalMotoristGQL,
            })) as CreateLocalMotoristsResponse;

            setCreated((list) => [
              ...(list ? list : []),
              {
                _id: uuidv4(),
                items: {
                  component: (
                    <MotoristLine
                      data={{
                        firstName: response.motorist.firstName,
                        lastName: response.motorist.lastName,
                        status:
                          response.motorist.status === 'VERIFIED'
                            ? 'VERIFIED'
                            : 'UNVERIFIED',
                        email: response.motorist.email,
                      }}
                      config={{ action: false, icon: true }}
                    />
                  ),
                  state: { type: 'success' },
                },
              },
            ]);

            setCreatedExport((list) => [
              ...(list ? list : []),
              {
                firstName,
                lastName,
                plate,
                uid,
                email,
                visibleId,
                state: 'success',
              },
            ]);

            return { title: 'CreateSeveralMotorists', response };
          } catch (error: unknown) {
            setCreated((list) => [
              ...(list ? list : []),
              {
                _id: uuidv4(),
                items: {
                  component: (
                    <MotoristLine
                      data={{
                        firstName,
                        lastName,
                        status: 'UNVERIFIED',
                        email,
                      }}
                      config={{ action: false, icon: true }}
                    />
                  ),
                  state: {
                    type: 'error',
                    message:
                      error instanceof ApolloError
                        ? `${error.message}`
                        : `${error}`,
                  },
                },
              },
            ]);

            setCreatedExport((list) => [
              ...(list ? list : []),
              {
                firstName,
                lastName,
                plate,
                uid,
                email,
                visibleId,
                state: 'error',
              },
            ]);
            return {
              title: 'CreateSeveralMotorists',
              response: new Error(
                error instanceof ApolloError ? `${error.message}` : `${error}`,
              ),
            };
          }
        },
    ),
  );

  useEffect(() => {
    validate(isFinish);
  }, [isFinish]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['list']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'list' }}>
        <SummaryForm
          data={{
            list: created,
            created: createdToExport,
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
