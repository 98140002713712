import { dispatch } from '@gimlite/router';
import { useSearchOf } from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import {
  Wizard,
  WizardItemType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect } from 'react';
import { productsGql } from '../../../../common/gql/products.gql';
import { Product as ProductEntity } from '../../../../common/types/entities/product';
import { ChoiceCreateContractWizardContext } from '../../choiceCreateContract.wizard';

export const ActionStep = ({
  context: { parkingId, actions, mode },
  validate,
  submit,
}: WizardItemType.Config.Component<ChoiceCreateContractWizardContext>) => {
  const { t }: any = useTranslation();

  const { list, paging, setSearch } = useSearchOf<
    ProductEntity,
    { productPage: number; productLimit: number }
  >({
    gql: productsGql,
    wsSubscriptions: [],
  });

  useEffect(() => {
    setSearch({
      paging: {
        productPage: 1,
        productLimit: 10000,
      },
      params: { parkingId },
    });
  }, [parkingId]);

  const containsPool = !!list?.find(({ category }) =>
    category.includes('POOL'),
  );

  return (
    <Zone
      config={{
        zones: [['contain']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'contain' }}>
        <Widget
          config={{
            shadow: false,
          }}
        >
          <Wizard.CardGroup
            config={{ title: t('selectAction'), mode: 'wrapper' }}
          >
            {actions.createNewSingleContract && (
              <Wizard.CardLink
                handleEvent={{
                  click: () => {
                    dispatch('SHOW_WIZARD', {
                      id: 'createNewSingleContract',
                      store: {
                        parkingId,
                      },
                    });
                  },
                }}
                config={{
                  title: t(`createANewSingleContract`),
                  icon: 'customCreateNewSingleContract',
                }}
              />
            )}
            {containsPool && actions.createNewMasterPoolContract && (
              <Wizard.CardLink
                handleEvent={{
                  click: () => {
                    dispatch('SHOW_WIZARD', {
                      id: 'createNewMasterPoolContract',
                      store: {
                        parkingId,
                      },
                    });
                  },
                }}
                config={{
                  title: t(`createANewMasterPoolContract`),
                  icon: 'customCreateNewMasterPoolContract',
                }}
              />
            )}
            {containsPool && actions.createNewSubPoolContract && (
              <Wizard.CardLink
                handleEvent={{
                  click: () => {
                    dispatch('SHOW_WIZARD', {
                      id: 'createNewSubPoolContract',
                      store: {
                        parkingId,
                        mode,
                      },
                    });
                  },
                }}
                config={{
                  title: t(`createANewSubPoolContract`),
                  icon: 'customCreateNewSubPoolContract',
                }}
              />
            )}
            {containsPool && actions.addOneMotoristToAnExistingPoolContract && (
              <Wizard.CardLink
                handleEvent={{
                  click: () => {
                    dispatch('SHOW_WIZARD', {
                      id: 'addOneMotoristToAnExistingPoolContract',
                      store: {
                        parkingId,
                        mode,
                      },
                    });
                  },
                }}
                config={{
                  title: t(`addOneMotoristToAnExistingPoolContract`),
                  icon: 'customAddOneMotoristToAnExistingPoolContract',
                }}
              />
            )}
            {containsPool &&
              actions.addSeveralMotoristsToAnExistingPoolContract && (
                <Wizard.CardLink
                  handleEvent={{
                    click: () => {
                      dispatch('SHOW_WIZARD', {
                        id: 'addSeveralMotoristsToAnExistingPoolContract',
                        store: {
                          parkingId,
                          mode,
                        },
                      });
                    },
                  }}
                  config={{
                    title: t(`addSeveralMotoristsToAnExistingPoolContract`),
                    icon: 'customAddSeveralMotoristToAnExistingPoolContract',
                  }}
                />
              )}
          </Wizard.CardGroup>
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
