import { gql } from '@apollo/client'

export const localMotoristUpdateGql = gql`
  mutation updateLocalMotorist(
    $localMotoristId: String!
    $input: UpdateLocalMotoristsDto!
  ) {
    updateLocalMotorist(localMotoristId: $localMotoristId, input: $input) {
      _id
    }
  }
`
