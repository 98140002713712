import { makeAutoObservable } from 'mobx';
import { RootStoreMobX } from './root.store';

export type GlobalStoreContextType = {
  mode: 'operator' | 'tenant';
  ENV?: string;
  BFF_MOTORIST_HOST?: string;
  BFF_MOTORIST_TOKEN?: string;
};

export class GlobalStoreMobX {
  root: RootStoreMobX;
  mode: GlobalStoreContextType['mode'] = 'operator';
  ENV: GlobalStoreContextType['ENV'];
  BFF_MOTORIST_HOST: GlobalStoreContextType['BFF_MOTORIST_HOST'];
  BFF_MOTORIST_TOKEN: GlobalStoreContextType['BFF_MOTORIST_TOKEN'];
  load: boolean = false;

  constructor(root: RootStoreMobX) {
    this.root = root;
    makeAutoObservable(this);
  }

  public init() {}

  initBaseProps({
    ENV,
    BFF_MOTORIST_HOST,
    BFF_MOTORIST_TOKEN,
    mode,
  }: Pick<
    GlobalStoreContextType,
    'ENV' | 'BFF_MOTORIST_HOST' | 'BFF_MOTORIST_TOKEN' | 'mode'
  >) {
    this.ENV = ENV;
    this.BFF_MOTORIST_HOST = BFF_MOTORIST_HOST;
    this.BFF_MOTORIST_TOKEN = BFF_MOTORIST_TOKEN;
    this.mode = mode;
    this.load = true;
  }
}
