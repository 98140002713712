import { gql } from '@apollo/client'

export const loginGql = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      validationSteps
      user {
        _id
      }
    }
  }
`
