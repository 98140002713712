import {
  Color,
  Icon,
  IconType,
  Write,
  useTranslation
} from '@gimlite/watermelon'
import { useMemo } from 'react'
import './line-state.component.scss'

class Static {
  static stateMapper: Array<{
    key: LineStateType.Config.State
    color: Extract<Color, 'success' | 'error'>
    text: string
    icon: IconType.Config.Type
  }> = [
    {
      key: 'success',
      color: 'success',
      text: 'success',
      icon: 'faCheckSolid'
    },
    {
      key: 'error',
      color: 'error',
      text: 'error',
      icon: 'faXmarkSolid'
    }
  ]
}

export declare namespace LineStateType {
  type Props = {
    className?: string
    config: {
      state: LineStateType.Config.State
    }
  }

  namespace Config {
    type State = 'error' | 'success'
  }
}

export const LineState = ({
  className = '',
  config: { state }
}: LineStateType.Props) => {
  const { t } = useTranslation()
  const currentState = useMemo(
    () => Static.stateMapper.find(({ key }) => key === state),
    [state]
  )

  return (
    <div className={`line-state ${className}`}>
      <Icon
        className="line-state__icon"
        config={{ type: currentState?.icon, color: currentState?.color }}
      ></Icon>
      <Write
        className="line-state__text"
        data={{
          item: currentState?.text === 'error' ? t('seeError') : t('success')
        }}
        config={{
          mode: 'value-small',
          color: currentState?.color,
          wrap: false
        }}
      ></Write>
    </div>
  )
}
