import { useRootStore, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import {
  CredentialCard,
  CredentialCardType,
  CredentialMini,
} from '../../components/credential/credential.component';
import { CredentialType } from '../../common/mapper/credential.mapper';

export class TCSCredentialStatic {
  static info: CredentialType.Static<TCSCredentialCardType.Data> = {
    name: 'TCS',
    icon: 'partnerTCS',
    backgroundColor: { light: '#ffec01', dark: '#ffec01' },
    color: { light: '#333333', dark: '#333333' },
    category: 'RFID',
    primaryValue: 'externalId',
  };
}

export declare namespace TCSCredentialMiniType {
  type Props = {
    data?: {
      value?: string;
    };
  };
}

export const TCSCredentialMini = observer(
  ({ data: { value } = {} }: TCSCredentialMiniType.Props) => {
    const { ThemeStore } = useRootStore();

    return (
      <CredentialMini
        data={{ text: value }}
        config={{
          icon: TCSCredentialStatic.info.icon,
          backgroundColor:
            TCSCredentialStatic.info.backgroundColor[ThemeStore.theme],
          color: TCSCredentialStatic.info.color[ThemeStore.theme],
        }}
      />
    );
  },
);

export declare namespace TCSCredentialCardType {
  type Props = {
    handleEvent?: {
      event?: CredentialCardType.HandleEvent.Event['function'];
    };
    data: TCSCredentialCardType.Data;
    config?: {
      isActive?: boolean;
    };
  };

  type Data = {
    externalId: string;
  };
}

export const TCSCredentialCard = observer(
  ({
    handleEvent: { event } = {},
    data: { externalId },
    config: { isActive = true } = {},
  }: TCSCredentialCardType.Props) => {
    const { t, lang } = useTranslation();
    const { ThemeStore } = useRootStore();

    return (
      <CredentialCard.Context.Consumer>
        {({ place, edit }) => (
          <CredentialCard
            handleEvent={{ event, edit }}
            data={{
              name: TCSCredentialStatic.info.name,
              info: [
                {
                  key: 'externalId',
                  label: t('externalId'),
                  value: externalId,
                },
              ],
              category: TCSCredentialStatic.info.category,
              primaryValue: TCSCredentialStatic.info.primaryValue,
            }}
            config={{
              icon: TCSCredentialStatic.info.icon,
              backgroundColor:
                TCSCredentialStatic.info.backgroundColor[ThemeStore.theme],
              color: TCSCredentialStatic.info.color[ThemeStore.theme],
              disabled: !isActive,
              action:
                place === 'contractDisabled'
                  ? []
                  : !isActive
                  ? [{ label: t('import'), value: 'import' }]
                  : place === 'wizardMotoristExist'
                  ? [{ label: t('unload'), value: 'unload' }]
                  : place === 'contract'
                  ? [{ label: t('unload'), value: 'unload' }]
                  : place === 'motorist'
                  ? [
                      { label: t('edit'), value: 'edit' },
                      { label: t('delete'), value: 'delete', isCritical: true },
                    ]
                  : [],
            }}
          />
        )}
      </CredentialCard.Context.Consumer>
    );
  },
);
