import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { DateTime } from 'luxon';
import { ContractFormCreate } from '../../../../form/contract-create/contract-create.form';
import { CreateNewMasterPoolContractWizardContext } from '../../createNewMasterPoolContract.wizard';

export const ContractStep = ({
  context: { product, contract },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewMasterPoolContractWizardContext>) => {
  const { t, lang } = useTranslation();

  return (
    <Form
      data={{ value: contract }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          let contractFormatted = value;

          if (value.startDate && product.period) {
            contractFormatted = {
              ...contractFormatted,
              endDate: DateTime.fromISO(value.startDate)
                .set({ millisecond: product.period })
                .toISO(),
            };
          }

          if (contractFormatted.APBMode === 'NONE') {
            contractFormatted = {
              ...contractFormatted,
              APBNext: null,
            };
          } else if (
            contractFormatted.APBMode !== 'NONE' &&
            [null, undefined].includes(contractFormatted.APBNext)
          ) {
            contractFormatted = {
              ...contractFormatted,
              APBNext: true,
            };
          }

          submit({ contract: contractFormatted });
        },
        validate: (value: boolean) => {
          validate(value);
        },
      }}
    >
      <Zone
        config={{
          gap: {
            y: 1,
            x: 1,
          },
          zones: [['info', 'form']],
          rows: ['1fr'],
          columns: ['min-content', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'info' }}>
          <ContractWizardInfos
            data={{
              productName: product.id ? t(`product-${product.id}-name`) : null,
              APBMode: contract.APBMode,
              APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
              state: contract.state,
              startDate: contract.startDate,
              endDate: contract.endDate,
              description: contract.description,
              reference: contract.reference,
              capacity: contract.capacity,
            }}
          />
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          <ContractFormCreate
            data={{ contract }}
            config={{
              disabled: { endDate: typeof product.period === 'number' },
            }}
          />
        </Zone.Area>
      </Zone>
    </Form>
  );
};
