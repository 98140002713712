import {
  Row,
  Zone,
  toCapitalizeCase,
  useTranslation,
} from '@gimlite/watermelon';
import { Blurb } from '@gimlite/watermelon/components/blurb/blurb.component';
import { PoolAdminAction } from '../action/pool-admin.action';

export declare namespace PoolAdminLineType {
  type Props = {
    data: {
      firstName: string | null;
      lastName: string | null;
      email: string | null;
    };
  };
}

export const PoolAdminLine = ({
  data: { firstName, lastName, email },
}: PoolAdminLineType.Props) => {
  const { t } = useTranslation();

  return (
    <Zone
      config={{
        gap: {
          x: 1,
          y: 0,
        },
        zones: [['identity', 'action']],
        rows: ['1fr'],
        columns: ['1fr', 'min-content'],
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Zone.Area config={{ area: 'identity' }}>
        <Blurb
          data={{
            title:
              firstName && !lastName
                ? toCapitalizeCase(firstName)
                : !firstName && lastName
                ? toCapitalizeCase(lastName)
                : firstName && lastName
                ? `${toCapitalizeCase(firstName)} ${toCapitalizeCase(lastName)}`
                : t('unknown'),
            description: email ? `${email}` : '',
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'action' }}>
        <Row config={{ horizontal: 'center', vertical: 'center' }}>
          <PoolAdminAction config={{ size: 'list' }} />
        </Row>
      </Zone.Area>
    </Zone>
  );
};
