import { gql } from '@apollo/client';

export const loadCredGql = gql`
  mutation loadCredOnContract($contractId: String!, $credential: Credentials!) {
    loadCredOnContract(contractId: $contractId, credential: $credential) {
      _id
      credentials {
        type
        provider
        value
        description
      }
    }
  }
`;
