import { ProductList } from '@gimlite/osp';
import { Page, Zone } from '@gimlite/watermelon';
import { useParams } from 'react-router-dom';

export const ProductPage = () => {
  const { parkingId } = useParams();

  return (
    parkingId && (
      <Page>
        <Zone
          config={{
            zones: [['list']],
            rows: ['1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area
            config={{
              area: 'list',
            }}
          >
            <ProductList parkingId={parkingId} />
          </Zone.Area>
        </Zone>
      </Page>
    )
  );
};
