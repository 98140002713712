import { gql } from '@apollo/client'
import { APBModeGlobalName } from '../mapper/abp.mapper'
import { Credential } from '../types/entities/credential'
import { breakdownsCategory, breakdownsType } from '../mapper/breakdown.mapper'

export type CreateBreakdownResponse = {
  _id: string
  startDate: string
  endDate: string
  category: string
  description: string
  type: string
  createdAt: string
  updatedAt: string
}

export type CreateBreakdown = {
  input: {
    parkingId: string
    category: keyof typeof breakdownsCategory
    endDate: string
    description: string
    type: keyof typeof breakdownsType
  }
}

export const createBreakdownGQL = gql`
  mutation createBreakdown($input: CreateBreakdownDto!) {
    createBreakdown(input: $input) {
      _id
      startDate
      endDate
      category
      description
      type
      createdAt
      updatedAt
    }
  }
`
