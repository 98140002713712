import { gql } from "@apollo/client";

export const getProductsOptionsGql = gql`
  query products(
    $parkingId: String!
    $productLimit: Int
    $category: ProductCategoryEnum
  ) {
    products(
      limit: $productLimit
      parkingId: $parkingId
      category: $category
    ) {
      list {
        _id
        name
      }
    }
  }
`