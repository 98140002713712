export const deleteAllWithSameProperties = <
  T extends Record<string, string | number | null | undefined>,
>(
  array: T[],
  targetProperty: Partial<T>,
): T[] => {
  const targetPropertyEntries = Object.entries(targetProperty);

  return array.reduce((acc: T[], currentProperty: T) => {
    const isSame =
      targetPropertyEntries.reduce((acc: number, [key, value]) => {
        if (
          currentProperty?.[key] !== undefined &&
          currentProperty?.[key] === value
        )
          return acc + 1;
        return acc;
      }, 0) === targetPropertyEntries.length;

    if (!isSame) {
      acc.push(currentProperty);
    }

    return acc;
  }, []);
};

export const hasAtLeastOneWithSameProperties = <
  T extends Record<string, string | number | null | undefined>,
>(
  array: T[],
  targetProperty: Partial<T>,
): boolean => {
  const targetPropertyEntries = Object.entries(targetProperty);

  return array.some((currentProperty: T) => {
    return targetPropertyEntries.every(([key, value]) => {
      return currentProperty?.[key] === value;
    });
  });
};

export const findIndexFirstWithSameProperties = <
  T extends Record<string, string | number | null | undefined>,
>(
  array: T[],
  targetProperty: Partial<T>,
): number => {
  const targetPropertyEntries = Object.entries(targetProperty);

  return array.findIndex((currentProperty: T) => {
    return targetPropertyEntries.every(([key, value]) => {
      return currentProperty?.[key] === value;
    });
  });
};

export const findAndReplaceFirstWithSameProperties = <
  T extends Record<string, string | number | null | undefined>,
>(
  array: T[],
  targetProperty: Partial<T>,
  newProperty: T,
): T[] => {
  const index = findIndexFirstWithSameProperties(array, targetProperty);
  if (index === -1) return array;
  array[index] = newProperty;
  return array;
};

export const findAndDeleteFirstWithSameProperties = <
  T extends Record<string, string | number | null | undefined>,
>(
  array: T[],
  targetProperty: Partial<T>,
): T[] => {
  const index = findIndexFirstWithSameProperties(array, targetProperty);
  if (index === -1) return array;
  array.splice(index, 1);
  return array;
};
