import { ContractMasterPoolLine } from '../../../composition/line/contract-master-pool.line';
import { AddOneMotoristToAnExistingPoolContractWizardContext } from '../../../wizards/addOneMotoristToAnExistingPoolContract/addOneMotoristToAnExistingPoolContract.wizard';
import { AddSeveralMotoristsToAnExistingPoolContractWizardContext } from '../../../wizards/addSeveralMotoristsToAnExistingPoolContract/addSeveralMotoristsToAnExistingPoolContract.wizard';
import { CreateNewSubPoolContractContext } from '../../../wizards/createNewSubPoolContract/createNewSubPoolContract.wizard';
import { requestGQL, useSearchOf } from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  SingleCheckForm,
  SingleCheckFormType,
} from '../../../form/single-check/single-check.form';
import {
  GlobalSearchContractEntityResponse,
  GlobalSearchResponse,
  globalSearchGQL,
} from '../../gql/globalSearch.gql';
import {
  PoolContractAndSubResponse,
  poolContractAndSubGQL,
} from '../../gql/poolContractAndSub.gql';
import { poolContractsAndSubGQL } from '../../gql/poolContractsAndSub.gql';
import { ProductResponse, productGQL } from '../../gql/product.gql';
import { convertCredentialsBackToFrontName } from '../../mapper/credential.mapper';
import { APBModeDetectWithCycleControl } from '../../mapper/product.mapper';

export const MasterContractStep = ({
  context: {
    parkingId,
    masterPoolContractId,
    masterPoolExistedSave,
    machine,
    contract,
    mode,
  },
  validate,
  submit,
  withRuleTenantCanCreateSubContract = false,
  ignoreDefaultSub = false,
}: WizardItemType.Config.Component<
  | AddSeveralMotoristsToAnExistingPoolContractWizardContext
  | AddOneMotoristToAnExistingPoolContractWizardContext
  | CreateNewSubPoolContractContext
> & {
  withRuleTenantCanCreateSubContract?: boolean | null;
  ignoreDefaultSub?: boolean | null;
}) => {
  const pageLimit = 50;
  const { t, lang } = useTranslation();

  const [searchMasterPoolContractResult, setSearchMasterPoolContractResult] =
    useState<GlobalSearchContractEntityResponse[] | null>(null);

  const [selectMasterPoolId, setSelectMasterPoolId] = useState<string>();

  const { list, paging, setSearch } = useSearchOf<
    GlobalSearchContractEntityResponse,
    { page: number; limit: number }
  >({
    gql: poolContractsAndSubGQL,
    wsSubscriptions: [],
  });

  const masterContractFormattedLine = useCallback(
    (contracts: GlobalSearchContractEntityResponse[]) => {
      return contracts.map(
        ({
          _id,
          name1,
          name2,
          isEnabled,
          ospContractId,
          spacesAllocated,
          contractCount,
          spacesOccupied,
          reference,
        }) => ({
          _id,
          items: {
            component: (
              <ContractMasterPoolLine
                config={{
                  action: false,
                }}
                data={{
                  code: ospContractId,
                  name1: name1!,
                  name2,
                  status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                  carCounter: [spacesOccupied || 0, spacesAllocated!],
                  classification: reference,
                  contractCounter: contractCount,
                }}
              />
            ),
          },
        }),
      );
    },
    [],
  );

  const handleSearch = useCallback(
    async (value: string) => {
      if (value && value.length >= 3) {
        const { contracts: contractsNotFormatted } = (await requestGQL({
          params: {
            content: value,
            tenantCanCreateSubContract:
              (mode === 'tenant' && withRuleTenantCanCreateSubContract) || null,
          },
          gql: globalSearchGQL,
        })) as GlobalSearchResponse;

        const masterPoolContract = contractsNotFormatted.filter(
          (element) => element.category === 'OPERATOR_POOL',
        );

        setSearchMasterPoolContractResult(() => masterPoolContract);

        submit({
          masterPoolExistedSave: [
            ...masterPoolExistedSave,
            ...masterPoolContract,
          ].reduce(
            (acc: GlobalSearchContractEntityResponse[], { _id, ...rest }) => {
              if (!acc.find((element) => element._id === _id)) {
                return [...acc, { _id, ...rest }];
              }
              return acc;
            },
            [],
          ),
        });
        setSearch({
          paging: { page: 1, limit: pageLimit },
          params,
        });
      } else {
        setSearchMasterPoolContractResult(() => null);
      }
    },
    [searchMasterPoolContractResult, mode, withRuleTenantCanCreateSubContract],
  );

  const { fresh, paging: pagingFormatted } = useMemo((): {
    fresh: GlobalSearchContractEntityResponse[] | undefined;
    paging?: SingleCheckFormType.Props['data']['paging'];
  } => {
    if (!list) {
      return { fresh: undefined };
    } else {
      const listTarget = !searchMasterPoolContractResult
        ? list
        : searchMasterPoolContractResult;

      return {
        fresh: listTarget,
        paging: !searchMasterPoolContractResult
          ? paging
          : {
              count: searchMasterPoolContractResult.length,
              current: 1,
              limit: searchMasterPoolContractResult.length,
            },
      };
    }
  }, [list, paging, searchMasterPoolContractResult]);

  const params = useMemo(
    () => ({
      order: 'name1',
      parkingId,
      category: 'OPERATOR_POOL',
      tenantCanCreateSubContract:
        (mode === 'tenant' && withRuleTenantCanCreateSubContract) || null,
    }),
    [mode, withRuleTenantCanCreateSubContract],
  );

  useEffect(() => {
    if (masterPoolContractId && masterPoolContractId !== selectMasterPoolId) {
      setSelectMasterPoolId(() => masterPoolContractId);

      (async () => {
        const currentContract = (await requestGQL({
          params: {
            contractId: masterPoolContractId,
          },
          gql: poolContractAndSubGQL,
        })) as PoolContractAndSubResponse;

        machine.send('SKIP_SUB_CONTRACT', {
          payload: currentContract.contractCount <= 1,
        });

        let startDate = currentContract.startDate;
        let endDate = currentContract.stopDate;
        let APBMode = APBModeDetectWithCycleControl(
          currentContract?.rules?.cycleControl || null,
        );

        const currentSubPoolContractId = currentContract.tenantContracts[0]._id;

        if (currentContract.contractCount <= 1 && !ignoreDefaultSub) {
          submit({
            subPoolContractId: currentSubPoolContractId,
          });

          const currentSubContract = (await requestGQL({
            params: {
              contractId: currentSubPoolContractId,
            },
            gql: poolContractAndSubGQL,
          })) as PoolContractAndSubResponse;

          startDate = currentSubContract.startDate;
          endDate = currentContract.stopDate;
          APBMode = APBModeDetectWithCycleControl(
            currentSubContract?.rules?.cycleControl || null,
          );
        }

        const { rules } = (await requestGQL({
          params: {
            productId: currentContract.productId,
          },
          gql: productGQL,
        })) as ProductResponse;

        submit({
          product: {
            id: currentContract.productId,
            credentials: rules?.credentialsTypes?.types
              ? rules.credentialsTypes.types.map((item) =>
                  convertCredentialsBackToFrontName(item),
                )
              : [],
          },
          parentContract: {
            ...currentContract,
            startDate: currentContract.startDate,
            endDate: currentContract.stopDate,
            APBMode: APBModeDetectWithCycleControl(
              currentContract?.rules?.cycleControl || null,
            ),
            APBNext: null,
            state: currentContract.isEnabled,
          },
          contract: {
            ...contract,
            state: currentContract.isEnabled,
            APBMode,
            startDate,
            endDate,
          },
        });
      })();
    }
  }, [fresh, contract, masterPoolContractId, masterPoolExistedSave]);

  useEffect(() => {
    const newList = masterPoolExistedSave;

    if (list) {
      list.forEach(({ _id, ...rest }) => {
        if (
          masterPoolExistedSave.findIndex((element) => element._id === _id) ===
          -1
        ) {
          newList.push({ _id, ...rest });
        }
      });
    }

    submit({
      masterPoolExistedSave: newList,
    });
  }, [list, masterPoolExistedSave]);

  useEffect(() => {
    setSearch({
      paging: { page: 1, limit: pageLimit },
      params,
    });
  }, [params]);

  useEffect(() => {
    validate(!!masterPoolContractId);
  }, [masterPoolContractId]);

  return (
    <Zone
      config={{
        gap: {
          y: 0,
          x: 0,
        },
        zones: [['form']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'form' }}>
        <Widget
          config={{
            title: t('selectAMasterPoolContract'),
            shadow: false,
          }}
        >
          <SingleCheckForm
            handleEvent={{
              paging: (data) => {
                setSearch({
                  paging: { page: data.page, limit: data.limit },
                  params,
                });
              },
              checkItem: (id) => {
                submit({
                  masterPoolContractId: id,
                });
              },
              search: handleSearch,
            }}
            data={{
              check: masterPoolContractId,
              fresh: fresh ? masterContractFormattedLine(fresh) : undefined,
              saved: masterContractFormattedLine(masterPoolExistedSave),
              paging: pagingFormatted,
            }}
            config={{
              searchPlaceholder: t('searchAMasterPoolContract'),
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
