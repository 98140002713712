import { useRootStore as useRootStoreOSP } from '@gimlite/osp';
import {
  BadgeType,
  Icon,
  Row,
  Space,
  Write,
  Zone,
  useTranslation,
} from '@gimlite/watermelon';
import {
  Blurb,
  BlurbType,
} from '@gimlite/watermelon/components/blurb/blurb.component';
import { observer } from 'mobx-react-lite';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import {
  ContractBadgesType,
  useContractBadges,
} from '../../common/utils/contract-badges.hook';
import { CredentialMiniGroupType } from '../../components/credential/credential.component';

class Static {
  static statusBadgeColorMap: Record<
    ContractMasterPoolLineType.Data.Status,
    BadgeType.Config.Color
  > = {
    ACTIVE: 'disabled',
    SUSPENDED: 'warn',
    EXPIRED: 'disabled',
  };
}

export declare namespace ContractMasterPoolLineType {
  type Props = {
    data: {
      id?: string;
      isStopped?: boolean;
      isUpcoming?: boolean;
      parkingId?: string;
      name1: string | null;
      name2: string | null;
      code: string | null;
      status: ContractMasterPoolLineType.Data.Status;
      classification?: string | null;
      carCounter?: [number, number] | null;
      contractCounter?: number | null;
      credentialsList?: React.ReactElement<CredentialMiniGroupType.Props>;
    };
    config?: {
      action?: boolean;
    };
  };

  namespace Data {
    type Status = 'ACTIVE' | 'SUSPENDED' | 'EXPIRED';
  }
}

export const ContractMasterPoolLine = observer(
  ({
    data: {
      name1,
      name2,
      code,
      classification,
      status,
      carCounter,
      contractCounter,
      id,
      isStopped,
      isUpcoming,
      parkingId,
      credentialsList,
    },
    config: { action = true } = {},
  }: ContractMasterPoolLineType.Props) => {
    const { GlobalStore: GlobalStoreOSP } = useRootStoreOSP();
    const { t, lang } = useTranslation();
    const isEnabled = status === 'ACTIVE';

    let identityOptions: Pick<BlurbType.Props['data'], 'subcontain'> = {};

    if (credentialsList) {
      identityOptions = { ...identityOptions, subcontain: credentialsList };
    }

    const states: [ContractBadgesType.Data.State] = [
      isEnabled ? 'ACTIVE' : 'SUSPENDED',
    ];
    if (isUpcoming) states.push('UPCOMING');
    if (isStopped) states.push('EXPIRED');
    if (isStopped || isUpcoming) states.shift();
    const badges = useContractBadges({ states });

    return (
      <Zone
        config={{
          gap: {
            x: 1,
            y: 0,
          },
          zones: [['icon', 'identity', 'cars', 'contracts']],
          rows: ['1fr'],
          columns: ['min-content', '1fr', '100px', '65px', 'min-content'],
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Zone.Area config={{ area: 'icon' }}>
          <Icon
            config={{
              size: 'list',
              type: iconGlobalEntity.masterPoolContract,
            }}
          ></Icon>
        </Zone.Area>

        <Zone.Area config={{ area: 'identity' }}>
          <Blurb
            data={{
              ...identityOptions,
              title:
                !name1 && name2
                  ? name2
                  : name1 && !name2
                  ? name1
                  : name1 && name2
                  ? `${name2} ${name1}`
                  : t('unknown'),
              description: code
                ? `${code} ${classification ? ` • ${classification}` : ''}`
                : '',
              badge: badges,
            }}
          />
        </Zone.Area>

        <Zone.Area config={{ area: 'cars' }}>
          <Row config={{ horizontal: 'end', vertical: 'center' }}>
            {carCounter ? (
              <>
                <Write
                  config={{ wrap: false, mode: 'key-small-bold' }}
                  data={{ item: `${carCounter[0]} / ${carCounter[1]}` }}
                />
                <Space config={{ count: 0.5, way: 'horizontal' }} />
                <Icon config={{ type: 'faCarSolid', size: 'xxsmall' }} />
              </>
            ) : (
              <></>
            )}
          </Row>
        </Zone.Area>

        <Zone.Area config={{ area: 'contracts' }}>
          <Row config={{ horizontal: 'end', vertical: 'center' }}>
            {contractCounter ? (
              <>
                <Write
                  config={{ wrap: false, mode: 'key-small-bold' }}
                  data={{ item: `${contractCounter}` }}
                />
                <Space config={{ count: 0.5, way: 'horizontal' }} />
                <Icon
                  config={{
                    type: iconGlobalEntity.motoristContractPool,
                    size: 'xsmall',
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </Row>
        </Zone.Area>
      </Zone>
    );
  },
);
