import { useCallback, useEffect } from 'react'
import { findId } from '../functions/entities.function'
import { Identifiable } from '../types/entities.type'
import { useRootStore } from './useRootStore.hook'

export type CacheProps<T> = {
  maxSize?: number
  maxAgeMs?: number
  fn?: (...args: any[]) => Promise<T | T[]>
  cacheInvalidationSubscriptions?: string[]
  cacheKey?: string
}

export const useCache = <T extends Identifiable | Identifiable[]>({
  maxAgeMs = 60_000,
  fn,
  cacheInvalidationSubscriptions = [],
  cacheKey
}: CacheProps<T>) => {

  const { GlobalStore } = useRootStore()
  const { cache, socket } = GlobalStore

  const cacheInvalidationCallback = useCallback((event) => {
    const id = findId(event)
    if (id) {
      cache?.invalidate(id)
    }
  }, [])

  useEffect(() => {
    cacheInvalidationSubscriptions.forEach(sub => socket?.on(sub, cacheInvalidationCallback))

    return () => {
      cacheInvalidationSubscriptions.forEach(sub => socket?.off(sub, cacheInvalidationCallback))
    }
  }, [])

  return {
    set: (key: string, value: T, ttlMs: number) => cache?.put(key, value, ttlMs),
    get: (key: string) => cache?.get(key) as T | undefined,
    passThrough: fn ? async <T>(...args: any[]) => {
      const key = (cacheKey || fn.toString())
        + (args && args.length != 0 ? '__' + JSON.stringify(args) : '')

      if (cache?.hasKey(key)) {
        return cache?.get(key) as T
      }

      const value = (await fn(...args)) as Identifiable | Identifiable[]
      cache?.put(key, value, maxAgeMs)
      return value
    } : (...args: any[]) => args,
  }

}
