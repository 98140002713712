/* eslint-disable */
import { Color, Write } from '@gimlite/watermelon';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import './progress.component.scss';

export declare namespace ProgressType {
  type Props = {
    className?: string;
    config: {
      color: Extract<Color, 'success' | 'warn' | 'error' | 'yang'>;
      name: string;
      value: number;
      isReference?: boolean;
    };
  };
}

export const Progress = ({
  config: { color, name, value, isReference = false },
}: ProgressType.Props) => {
  const { total } = useContext(Progress.Context);

  const pourcent = useMemo(() => {
    return ((value / total) * 100).toFixed(2);
  }, [total, value]);

  return (
    <div className={`progress progress--background--${color}`}>
      <div className="progress__name">
        <Write
          data={{ item: name }}
          config={{ mode: 'value-medium', color: 'label' }}
        ></Write>
      </div>

      <div style={{ width: `${pourcent}%` }} className={`progress__bar`}>
        <div
          className={`progress__bar__color progress__bar__color--${
            isReference ? 'reference' : 'data'
          }`}
        ></div>
        <div className="progress__bar__count">
          <Write
            className="progress__bar__count__text"
            data={{
              item: `${value} ${isReference ? '=' : '/'} ${pourcent}%`,
            }}
            config={{ mode: 'value-small', wrap: false }}
          ></Write>
        </div>
      </div>
    </div>
  );
};

export declare namespace ProgressGroupType {
  type Props = {
    className?: string;
    config: {
      total: number;
    };
    children: React.ReactNode | React.ReactNode[];
  };

  namespace Config {
    type Total = number;
  }
}

Progress.Group = ({
  className = '',
  children,
  config: { total },
}: ProgressGroupType.Props) => {
  const overWidth = useMemo(() => {
    const theGreatestValue = (
      Array.isArray(children) ? children : [children]
    ).reduce((acc: number, element: any) => {
      const currentValue = element!.props.config.value;
      return currentValue > acc ? currentValue : acc;
    }, 0);

    return theGreatestValue <= total
      ? 0
      : ((theGreatestValue - total) / theGreatestValue) * 100;
  }, [children, total]);

  const leftStyle = useMemo(() => {
    return {
      width: `calc(${100 - overWidth}%)`,
      paddingRight: !!overWidth ? 0 : '10px',
      paddingLeft: '10px',
    };
  }, [overWidth]);

  const rigthStyle = useMemo(() => {
    return {
      display: !!overWidth ? 'block' : 'none',
      width: `calc(${overWidth}% + ${4}rem)`,
    };
  }, [overWidth]);

  return (
    <Progress.Context.Provider value={{ total }}>
      <div className={`progressgroup ${className}`}>
        <div className="progressgroup__contain">
          <div style={leftStyle} className="progressgroup__contain__bars">
            {children}
          </div>
          <div
            style={rigthStyle}
            className="progressgroup__contain__placeholder"
          ></div>
        </div>
        <div className="progressgroup__background">
          <div
            style={leftStyle}
            className="progressgroup__background__placeholder"
          ></div>
          <div style={rigthStyle} className="progressgroup__background__over">
            <span className="progressgroup__background__over__text">
              OVERBOOK AREA
            </span>
          </div>
        </div>
      </div>
    </Progress.Context.Provider>
  );
};

Progress.Context = createContext<{
  total: ProgressGroupType.Config.Total;
}>({
  total: 0,
});
