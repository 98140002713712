import { gql } from '@apollo/client';

export type ContractsResponse = {
  list: Array<{
    _id: string;
    ospContractId: string;
    isEnabled: boolean;
    productId: string;
    name1: string | null;
    name2: string | null;
    contractCount: number;
    spacesAllocated: number;
    parkingId: string;
    category: string;
    isInside: boolean;
    motoristId: string | null;
    motorist: {
      firstName: string | null;
      lastName: string | null;
    } | null;
  }>;
  paging: {
    current: number;
    count: number;
    limit: number;
  };
};

export type ContractsParams = {
  parkingId: string | null;
  motoristId: string | null;
  freeflagId: string | null;
  order: string | null;
};

export const contractsGql = gql`
  query contracts(
    $parkingId: String
    $motoristId: String
    $freeflagId: String
    $order: String
    $isMotoristIn: Boolean
    $isActive: Boolean
    $contractMotoristPage: Int
    $contractMotoristLimit: Int
    $categories: [String]
  ) {
    contracts(
      parkingId: $parkingId
      motoristId: $motoristId
      freeflagId: $freeflagId
      order: $order
      isActive: $isActive
      isMotoristIn: $isMotoristIn
      categories: $categories
      page: $contractMotoristPage
      limit: $contractMotoristLimit
    ) {
      list {
        _id
        ospContractId
        isEnabled
        productId
        name1
        name2
        contractCount
        spacesAllocated
        stopDate
        startDate
        parkingId
        category
        isInside
        motoristId
        spacesOccupied

        freeflag {
          _id
        }

        motorist {
          firstName
          lastName
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
