import {
  FilterReduced,
  Form,
  IconType,
  ListFlatInfo,
  Select,
  Widget,
  useMyUrl,
  useSearchOf,
  useTranslation,
} from '@gimlite/watermelon';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { technicalEventsGql } from '../../common/gql/incidents.gql';
import { TechnicalEvent } from '../../common/types/entities/technical-events';
import { IncidentLine } from '../line/incident.line';

export type IncidentListProps = {
  widget?: {
    title?: string;
    icon?: IconType.Config.Type;
  };
  parkingId: string;
  eventHandler?: {
    select?: (id: string) => void;
  };
  setSelectedIncident?: (id: string) => void;
};

export const IncidentList = ({
  widget,
  parkingId,
  setSelectedIncident,
}: IncidentListProps) => {
  const { t } = useTranslation();
  const { getParamsUrl, setParamsUrl } = useMyUrl({});
  const { incidentId } = useParams();
  useEffect(() => {
    setParamsUrl({
      ...getParamsUrl,
      archived: 'active',
      incidentPage: 1,
      incidentLimit: 30,
      parkingId,
    });
  }, [parkingId]);
  const { archived, criticity, category, incidentPage, incidentLimit } =
    useMemo(() => {
      // get filters params here
      const { archived, criticity, category, incidentPage, incidentLimit } =
        getParamsUrl;

      return {
        parkingId,
        incidentPage,
        incidentLimit,
        archived,
        criticity: !criticity
          ? undefined
          : Array.isArray(criticity)
          ? criticity.filter((crit) => !!crit)
          : [criticity],
        category: !category
          ? undefined
          : Array.isArray(category)
          ? category.filter((cat) => !!cat)
          : [category],
      };
    }, [getParamsUrl]);

  const { list, paging, setSearch } = useSearchOf<
    TechnicalEvent,
    { incidentPage: number; incidentLimit: number }
  >({
    gql: technicalEventsGql,
    wsSubscriptions: ['technical-events:created', 'technical-events:updated'],
    limit: 30,
  });

  useEffect(() => {
    setSearch({
      paging: {
        incidentPage: incidentPage || 1,
        incidentLimit: incidentLimit || 30,
      },
      params: {
        archived,
        criticity,
        category,
        incidentPage,
        incidentLimit,
        parkingId,
      },
    });
  }, [archived, criticity, category, incidentPage, incidentLimit, parkingId]);
  return (
    <Widget
      config={{
        title: widget?.title,
        icon: widget?.icon,
        backtitle: !!widget?.title,
      }}
    >
      <ListFlatInfo<any>
        data={{
          defaultSelected: incidentId,
          list:
            list &&
            list.map(
              ({
                _id,
                key,
                createdAt,
                payload,
                category,
                criticity,
                archived,
              }: TechnicalEvent) => ({
                _id,
                items: {
                  component: (
                    <IncidentLine
                      data={{
                        code: key,
                        payload,
                        device: payload?.accessPoint?.shortCode,
                        date: createdAt,
                        category,
                        criticity,
                        isArchived: archived,
                        technicalEventId: _id,
                      }}
                    />
                  ),
                },
              }),
            ),
          paging: paging,
        }}
        config={{
          pagination: 'scroll',
          columns: [
            { key: 'component', label: t('identity'), size: '1fr' },
            { key: 'component', label: t('category'), size: 'min-content' },
          ],
          header: true,
          filter: (
            <FilterReduced
              data={{
                value: {
                  archived,
                  criticity: criticity || undefined,
                  category: category || undefined,
                },
              }}
              handleEvent={{
                submit: (submit) => {
                  setParamsUrl({ ...getParamsUrl, ...submit, incidentPage: 1 });
                },
                clear: () => {
                  setParamsUrl({
                    ...getParamsUrl,
                    archived: 'active',
                    criticity: undefined,
                    category: undefined,
                    incidentPage: 1,
                    incidentLimit: 30,
                    parkingId,
                  });
                },
              }}
            >
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('show'),
                  name: 'archived',
                }}
                data={{ defaultValue: archived || 'active' }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false }}
                  data={{
                    items: [
                      {
                        label: t('active'),
                        value: 'active',
                      },
                      {
                        label: t('archived'),
                        value: 'archived',
                      },
                    ],
                  }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('severity'),
                  name: 'criticity',
                }}
                data={{ defaultValue: getParamsUrl.criticity }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false, mode: 'multiple' }}
                  data={{
                    items: [
                      {
                        label: t('alarm'),
                        value: 'alarm',
                      },
                      {
                        label: t('warning'),
                        value: 'warning',
                      },
                      {
                        label: t('information'),
                        value: 'information',
                      },
                    ],
                  }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('category'),
                  name: 'category',
                }}
                data={{ defaultValue: getParamsUrl.category }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false, mode: 'multiple' }}
                  data={{
                    items: [
                      {
                        label: t('contract'),
                        value: 'contract',
                      },
                      {
                        label: t('anti-passback'),
                        value: 'anti-passback',
                      },
                      {
                        label: t('payment'),
                        value: 'payment',
                      },
                      {
                        label: t('credential'),
                        value: 'credential',
                      },
                    ],
                  }}
                />
              </Form.Item>
            </FilterReduced>
          ),
        }}
        handleEvent={{
          click: setSelectedIncident,
          paging: (paging) => {
            setParamsUrl({
              ...getParamsUrl,
              incidentPage: paging.page,
              incidentLimit: paging.limit,
            });
          },
        }}
      />
    </Widget>
  );
};
