import {
  Button,
  Col,
  ListFlatSingleCheck,
  ListFlatSingleCheckType,
  Search,
  SearchType,
  Zone,
  useTranslation,
} from '@gimlite/watermelon';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import { useMemo } from 'react';
import { PopupInfo } from '../../components/popup-info/popup-info.component';
import './single-check.form.scss';

export declare namespace SingleCheckFormType {
  type Props = {
    handleEvent: {
      search: SearchType.HandleEvent.Search['function'];
      checkItem?: ListFlatSingleCheckType.HandleEvent.CheckItem<any>['function'];
      paging?: ListFlatSingleCheckType.HandleEvent.Paging['function'];
    };
    data: {
      fresh?: SingleCheckFormType.Data.Line[];
      saved: SingleCheckFormType.Data.Line[];
      paging?: ListFlatSingleCheckType.Data.Paging;
      check?: SingleCheckFormType.Data.Check;
    };
    config: {
      searchPlaceholder: string;
    };
  };

  namespace Data {
    type Line = {
      _id: ListFlatSingleCheckType.Data.Line<any>['_id'];
      items: {
        component: ListFlatSingleCheckType.Data.Item;
      };
    };

    type Check = ListFlatSingleCheckType.Data.Line<any>['_id'] | null;
  }
}

export const SingleCheckForm = ({
  handleEvent: { checkItem, paging, search },
  data,
  config: { searchPlaceholder },
}: SingleCheckFormType.Props) => {
  const { t } = useTranslation();

  const dataFresh = data?.fresh;
  const dataSaved = data?.saved;
  const dataCheck = data?.check;
  const dataPaging = data?.paging;
  const allData = useMemo(() => {
    return [...dataSaved, ...(dataFresh ? dataFresh : [])].reduce(
      (acc: ListFlatSingleCheckType.Data.Line<any>[], { _id, ...rest }) => {
        if (!acc.find((element) => element._id === _id)) {
          return [...acc, { _id, ...rest }];
        }
        return acc;
      },
      [],
    );
  }, [dataSaved, dataFresh]);
  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 0,
        },
        zones: [
          ['selection', 'search'],
          ['list', 'list'],
        ],
        rows: ['min-content', '1fr'],
        columns: ['1fr', 'min-content'],
      }}
    >
      <Zone.Area config={{ area: 'selection' }}>
        <Col config={{ vertical: 'end', horizontal: 'start' }}>
          <Popover
            config={{ minWidth: 'medium' }}
            data={
              <PopupInfo
                data={{
                  node: (
                    <ListFlatSingleCheck<any>
                      config={{
                        showOnlyCheck: true,
                        border: true,
                        pagination: 'none',
                        columns: [{ key: 'component', size: '1fr' }],
                      }}
                      handleEvent={{
                        paging,
                        click: () => {},
                      }}
                      data={{
                        checkItem: dataCheck,
                        list: allData.reduce(
                          (
                            acc: ListFlatSingleCheckType.Data.Line<any>[],
                            { _id, ...rest },
                          ) => {
                            if (dataCheck === _id) {
                              return [...acc, { _id, ...rest }];
                            }

                            return acc;
                          },
                          [],
                        ),
                      }}
                    />
                  ),
                }}
                config={{
                  title: t('currentSelection'),
                  description: {
                    key: 'total',
                    label: t('total'),
                    value: `${dataCheck ? 1 : 0}`,
                  },
                }}
              />
            }
          >
            <Button
              config={{
                size: 'small',
                mode: 'stroke',
                text: t('SEE_SELECTION').replace(
                  '[count]',
                  `${dataCheck ? 1 : 0}`,
                ),
              }}
            ></Button>
          </Popover>
        </Col>
      </Zone.Area>

      <Zone.Area config={{ area: 'search' }}>
        <Col config={{ vertical: 'end' }}>
          <Search
            handleEvent={{
              search,
              clear: () => search(''),
            }}
            config={{
              height: 'xsmall',
              width: 'xlarge',
              placeholder: searchPlaceholder,
            }}
          />
        </Col>
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <ListFlatSingleCheck<any>
          handleEvent={{
            checkItem: (check) => {
              checkItem?.(check);
            },
            paging,
          }}
          data={{
            checkItem: dataCheck,
            list: dataFresh,
            paging: dataPaging,
          }}
          config={{
            pagination: 'scroll',
            columns: [{ key: 'component', size: '1fr' }],
            border: true,
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
