import {
  ActionInline,
  ActionInlineType,
} from '@gimlite/osp/components/action-inline/action-inline.component';
import { useNavigate } from '@gimlite/router';
import { requestGQL, useTranslation } from '@gimlite/watermelon';
import { archiveTechnicalEventsGql } from '../../common/gql/incidents.gql';

export declare namespace IncidentActionType {
  type Props = {
    config: {
      size: ActionInlineType.Config.Size;
    };
    data: {
      technicalEventId: string;
      payload: any;
      isArchived?: boolean;
    };
  };
}

export const IncidentAction = ({
  config: { size },
  data: { technicalEventId, payload, isArchived },
}: IncidentActionType.Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const actions: ActionInlineType.Data[] = [
    {
      key: 'showMotoristContract',
      text: t('showMotoristContract'),
      color: 'label',
      show: !!payload?.contractId && !!payload?.parkingId,
      event: () =>
        navigate(`/${payload?.parkingId}/contracts/${payload?.contractId}`),
    },
    {
      key: 'archiveIncident',
      text: t('archiveIncident'),
      color: 'label',
      show: !!technicalEventId && !isArchived,
      event: () => {
        requestGQL({
          params: {
            technicalEventId: technicalEventId,
          },
          gql: archiveTechnicalEventsGql,
        });
      },
    },
  ];

  return <ActionInline config={{ size }} data={actions} />;
};
