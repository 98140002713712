import { gql } from '@apollo/client';

export const breakdownGql = gql`
  query breakdown($breakdownId: String!) {
    breakdown(breakdownId: $breakdownId) {
      _id
      startDate
      endDate
      category
      description
      type
      createdAt
      updatedAt
      contractsNeutralized {
        list {
          _id
        }
      }
    }
  }
`;
