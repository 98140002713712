import {
  addOneMotoristToAnExistingPoolContractService,
  AddOneMotoristToAnExistingPoolContractWizard,
  addSeveralMotoristsToAnExistingPoolContractService,
  AddSeveralMotoristsToAnExistingPoolContractWizard,
  choiceCreateContractService,
  ChoiceCreateContractWizard,
  createNewMasterPoolContractService,
  CreateNewMasterPoolContractWizard,
  createNewSingleContractService,
  CreateNewSingleContractWizard,
  createNewSubPoolContractService,
  CreateNewSubPoolContractWizard,
  createSeveralMotoristsService,
  CreateSeveralMotoristsWizard,
  reportTechnicalDeviceFailureService,
  ReportTechnicalDeviceFailureWizard,
} from '@gimlite/osp';
import { Config as ConfigWatermelon } from '@gimlite/watermelon/config';
import { Config as ConfigOSP } from '@gimlite/osp';
import { createRoot } from 'react-dom/client';
import config from './config';
import { CustomRouter } from './CustomRouter';

createRoot(document.getElementById('root') as HTMLElement).render(
  <ConfigOSP
    mode="operator"
    ENV={config.ENV}
    BFF_MOTORIST_HOST={config.REACT_APP_HOST_BFF_OSP_MOTORISTS}
    BFF_MOTORIST_TOKEN={config.BFF_OSP_MOTORIST_TOKEN}
  >
    <ConfigWatermelon
      custom={{
        env: {
          FRONT_OSP_MOTORISTS_HOST: config.FRONT_OSP_MOTORISTS_HOST,
        },
      }}
      debug={false}
      info={{
        env: config.ENV,
        version: {
          front: config.VERSION_FRONT,
        },
      }}
      isResponsive={false}
      server={{
        bff: config.BFF_HOST,
        ws: config.BFF_WS_HOST,
      }}
      authorization={config.BFF_TOKEN}
      tfa={{
        label: 'OSP: operator-portal',
      }}
      updatePassword={true}
      theme={{
        color: {
          dark: '#fe1900',
          light: '#c81400',
        },
        defaultTheme: 'light',
      }}
      wizards={[
        {
          id: 'choiceCreateContract',
          machine: choiceCreateContractService,
          component: ChoiceCreateContractWizard,
        },
        {
          id: 'createNewSingleContract',
          machine: createNewSingleContractService,
          component: CreateNewSingleContractWizard,
        },
        {
          id: 'createNewMasterPoolContract',
          machine: createNewMasterPoolContractService,
          component: CreateNewMasterPoolContractWizard,
        },
        {
          id: 'addSeveralMotoristsToAnExistingPoolContract',
          machine: addSeveralMotoristsToAnExistingPoolContractService,
          component: AddSeveralMotoristsToAnExistingPoolContractWizard,
        },
        {
          id: 'addOneMotoristToAnExistingPoolContract',
          machine: addOneMotoristToAnExistingPoolContractService,
          component: AddOneMotoristToAnExistingPoolContractWizard,
        },
        {
          id: 'createNewSubPoolContract',
          machine: createNewSubPoolContractService,
          component: CreateNewSubPoolContractWizard,
        },
        {
          id: 'createSeveralMotorists',
          machine: createSeveralMotoristsService,
          component: CreateSeveralMotoristsWizard,
        },
        {
          id: 'reportTechnicalDeviceFailure',
          machine: reportTechnicalDeviceFailureService,
          component: ReportTechnicalDeviceFailureWizard,
        },
      ]}
    >
      <CustomRouter />
    </ConfigWatermelon>
  </ConfigOSP>,
);
