import { gql } from '@apollo/client'

export const validate2faGql = gql`
  mutation validate2fa($userId: String!, $token: String!) {
    validate2fa(userId: $userId, token: $token) {
      granted
      token
    }
  }
`
