import { IncidentDetails, IncidentList, iconGlobalEntity } from '@gimlite/osp';
import { useNavigate } from '@gimlite/router';
import { Page, Zone, useMyUrl, useTranslation } from '@gimlite/watermelon';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const IncidentPage = () => {
  const { t } = useTranslation();
  const { parkingId, incidentId = null } = useParams();
  const navigate = useNavigate();
  const { getParamsUrlString } = useMyUrl({});

  const [selectedIncident, setSelectedIncident] = useState<string | null>(
    incidentId,
  );

  useEffect(() => {
    if (selectedIncident) {
      navigate(
        `/${parkingId}/incidents/${selectedIncident}?${getParamsUrlString}`,
      );
    }
  }, [selectedIncident]);

  useEffect(() => {
    setSelectedIncident(null);
  }, [parkingId]);

  return (
    parkingId && (
      <Page>
        <Zone
          config={{
            zones: [['list', 'details']],
            rows: ['1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area
            config={{
              area: 'list',
            }}
          >
            <IncidentList
              widget={{
                title: t('listOfIncidents'),
                icon: iconGlobalEntity['incident'],
              }}
              parkingId={parkingId}
              setSelectedIncident={(id: string) => setSelectedIncident(id)}
            />
          </Zone.Area>

          <Zone.Area
            config={{
              area: 'details',
            }}
          >
            <IncidentDetails
              widget={{ title: t('detailsOfIncidents') }}
              technicalEventId={selectedIncident}
            />
          </Zone.Area>
        </Zone>
      </Page>
    )
  );
};
