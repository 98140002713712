import {
  Color as ColorGlobal,
  Icon,
  IconType,
  Size,
  Write,
  getCSS
} from '@gimlite/watermelon'
import Color from 'color'
import { createContext, useId } from 'react'
import './timeline.component.scss'

const TimelineContext = createContext<TimelineGroupType.Config.Height>('large')

class Static {
  static convertSizeIcon: Record<
    TimelineGroupType.Config.Height,
    IconType.Config.Size
  > = {
    medium: 'xsmall',
    large: 'medium'
  }
}
export declare namespace TimelineType {
  type Props = {
    className?: string
    data: {
      date?: TimelineType.Data.Date
      device?: TimelineType.Data.Device
      title?: string
      subtitle?: TimelineType.Data.SubTitle
      extra?: TimelineType.Data.Extra
      icon?: IconType.Config.Type
    }
    config: {
      animation?: boolean
      color: Extract<ColorGlobal, 'success' | 'error' | 'label' | 'info'>
    }
  }
  namespace Data {
    type Date = string
    type Extra = React.ReactNode | string
    type Device = string
    type SubTitle = string
  }
}

export const Timeline = ({
  className = '',
  data: { date, device, title, subtitle, extra, icon },
  config: { color, animation = false }
}: TimelineType.Props) => {
  const id = useId()
  const formattedId = `area-${id.replace(/:/g, '-')}-gen`

  return (
    <TimelineContext.Consumer>
      {(size) => (
        <>
          <style>
            {` 
          #${formattedId} {
            .timeline__details,
            .timeline__step__subject {
              background-color: ${new Color(getCSS(`--color-${color}`))
                .lightness(90)
                .toString()};!important
            }
          }
        `}
          </style>

          <div
            id={formattedId}
            className={`
                timeline 
                timeline--color--${color} 
                timeline--size--${size} 
              ${className}`}
          >
            <div className="timeline__date">
              {date && (
                <Write
                  data={{ item: date }}
                  config={{
                    mode: 'key-small-bold',
                    color: 'disabled'
                  }}
                ></Write>
              )}
            </div>
            <div className="timeline__step">
              <div className="timeline__step__linetop"></div>
              <div className="timeline__step__subject">
                {icon && (
                  <Icon
                    className={`timeline__step__subject__item ${
                      animation
                        ? 'timeline__step__subject__item--animation'
                        : ''
                    } `}
                    config={{
                      type: icon,
                      color: color,
                      size: Static.convertSizeIcon[size]
                    }}
                  />
                )}
              </div>
              <div className="timeline__step__linebottom"></div>
            </div>
            {title && device && (
              <div className="timeline__details">
                <div className="timeline__details__code">
                  {title && (
                    <Write
                      data={{ item: title }}
                      config={{
                        mode: size === 'large' ? 'namise' : 'key-small-bold',
                        color: 'black'
                      }}
                    ></Write>
                  )}
                  {subtitle && (
                    <Write
                      data={{ item: subtitle }}
                      config={{
                        mode:
                          size === 'large'
                            ? 'namise-description'
                            : 'key-small-regular',
                        color: 'error'
                      }}
                    ></Write>
                  )}
                </div>
                <div className="timeline__details__more">
                  {extra && (
                    <div className="timeline__details__more__left">
                      {typeof extra === 'string' ? (
                        <Write
                          data={{ item: extra }}
                          config={{
                            mode:
                              size === 'large' ? 'namise' : 'key-small-bold',
                            color: 'black'
                          }}
                        />
                      ) : (
                        extra
                      )}
                    </div>
                  )}
                  {device && (
                    <Write
                      data={{ item: device }}
                      config={{
                        mode: size === 'large' ? 'namise' : 'key-small-bold',
                        color: 'black'
                      }}
                    ></Write>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </TimelineContext.Consumer>
  )
}

export declare namespace TimelineGroupType {
  type Props = {
    className?: string
    children: React.ReactNode | React.ReactNode[]
    config: {
      height: TimelineGroupType.Config.Height
      width?: TimelineGroupType.Config.Width
    }
  }

  namespace Config {
    type Height = Extract<Size, 'medium' | 'large'>
    type Width = Extract<Size, 'small' | 'full'>
  }
}

export const TimelineGroup = ({
  className = '',
  children,
  config: { height, width = 'full' }
}: TimelineGroupType.Props) => {
  return (
    <TimelineContext.Provider value={height}>
      <div
        className={`
        timeline-group
        timeline-group--width--${width}
        ${className}
      `}
      >
        {children}
      </div>
    </TimelineContext.Provider>
  )
}
