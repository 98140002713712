import { ApolloError } from '@apollo/client';
import { ContractMasterPoolLine } from '@gimlite/osp/composition/line/contract-master-pool.line';
import { ContractMotoristLine } from '@gimlite/osp/composition/line/contract-motorist.line';
import { MotoristLine } from '@gimlite/osp/composition/line/motorist.line';
import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import { UseCascadeResult, requestGQL, useCascade } from '@gimlite/watermelon';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateLocalMotorists,
  CreateLocalMotoristsResponse,
  createLocalMotoristGQL,
} from '../../../../common/gql/createLocalMotorist.gql';
import {
  CreateMotorist,
  CreateMotoristResponse,
  createMotoristGQL,
} from '../../../../common/gql/createMotorist.gql';
import {
  CreatePool,
  CreatePoolResponse,
  createPoolGQL,
} from '../../../../common/gql/createPool.gql ';
import {
  CredentialType,
  convertCredentialsFrontToBack,
} from '../../../../common/mapper/credential.mapper';
import {
  SummaryForm,
  SummaryFormType,
} from '../../../../form/summary/summary.form';
import { CreateNewMasterPoolContractWizardContext } from '../../createNewMasterPoolContract.wizard';

type ResultPosibility =
  | CreatePoolResponse
  | CreateMotoristResponse
  | CreateLocalMotoristsResponse;

export const SummaryStep = ({
  context: {
    parkingId,
    contract,
    product,
    motoristMode,
    motorists,
    tenant,
    poolAdmin,
  },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewMasterPoolContractWizardContext>) => {
  const { t, lang } = useTranslation();

  const [created, setCreated] = useState<SummaryFormType.Data.Line[]>();
  const [createdToExport, setCreatedExport] =
    useState<Array<SummaryFormType.Data.Created>>();

  const { isFinish } = useCascade<ResultPosibility>([
    async () => {
      const _id = uuidv4();
      try {
        const response = (await requestGQL({
          params: {
            input: {
              tenant: {
                name1: tenant.name1 as string,
                name2: tenant.name2,
                address1: tenant.address1,
                address2: tenant.address2,
                city: tenant.city,
                country: tenant.country,
                tenantCanCloseSession: null,
                tenantCanCreateSubContract: tenant.canCreateSubContract,
                zipcode: tenant.zipCode,
              },
              pool: {
                isEnabled: contract.state,
                parkingId,
                productId: product.id,
                spacesAllocated: contract.capacity,
                startDate: contract.startDate,
                stopDate: contract.endDate,
                reference: contract.reference,
                description: contract.description,
                antiPassBackMode: contract.APBMode,
              },
              user: {
                firstName: poolAdmin.firstName,
                lang: poolAdmin.lang,
                lastName: poolAdmin.lastName,
                username: poolAdmin.username,
                phone: poolAdmin.phone,
              },
            },
          } as CreatePool,
          gql: createPoolGQL,
        })) as CreatePoolResponse;

        setCreated((list) => [
          ...(list ? list : []),
          {
            _id,
            items: {
              component: (
                <ContractMasterPoolLine
                  data={{
                    code: response.ospContractId,
                    name1: response.name1,
                    name2: response.name2,
                    status: response.isEnabled ? 'ACTIVE' : 'SUSPENDED',
                  }}
                  config={{ action: false }}
                />
              ),
              state: { type: 'success' },
            },
          },
        ]);

        return { title: 'CreatePool', response };
      } catch (error: unknown) {
        setCreated((list) => [
          ...(list ? list : []),
          {
            _id,
            items: {
              component: (
                <ContractMasterPoolLine
                  data={{
                    code: null,
                    name1: tenant.name1!, //! Les validateurs forcent la présence
                    name2: tenant.name2,
                    status: contract.state ? 'ACTIVE' : 'SUSPENDED', //! Il n'existe pas en response
                  }}
                  config={{ action: false }}
                />
              ),
              state: {
                type: 'error',
                message:
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
              },
            },
          },
        ]);
        return {
          title: 'CreatePool',
          response: new Error(
            error instanceof ApolloError ? `${error.message}` : `${error}`,
          ),
        };
      }
    },
    ...(motoristMode === 'create'
      ? motorists.created.flatMap((motorist) => [
          async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
            try {
              if (
                prevResult[0].response instanceof Error ||
                !('_id' in prevResult[0].response)
              )
                throw t('poolWasNotCreated');

              const response = (await requestGQL({
                params: {
                  input: {
                    address1: null,
                    address2: null,
                    city: null,
                    country: null,
                    email: motorist.email,
                    firstName: motorist.firstName,
                    lang: motorist.lang,
                    lastName: motorist.lastName,
                    sendInvitation: motorist.sendInvitation,
                    zipcode: null,
                    plates: convertCredentialsFrontToBack(
                      motorist.plate
                        ? [
                            {
                              name: 'PLATE',
                              plate: motorist.plate.toUpperCase(),
                              description: '',
                            },
                          ]
                        : [],
                    ).map(({ provider, ...rest }) => ({ ...rest })),
                    uids: convertCredentialsFrontToBack(
                      motorist.uid || motorist.visibleId
                        ? [
                            {
                              name: 'RFID',
                              visibleId: motorist.visibleId || '',
                              uid: motorist.uid || '',
                            },
                          ]
                        : [],
                    ),
                    cards: [],
                    phone: null,
                    linkedContractId: null,
                  },
                } as CreateLocalMotorists,
                gql: createLocalMotoristGQL,
              })) as CreateLocalMotoristsResponse;

              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <MotoristLine
                        data={{
                          firstName: response.motorist.firstName,
                          lastName: response.motorist.lastName,
                          email: response.motorist.email,
                          status:
                            response.motorist.status === 'VERIFIED'
                              ? 'VERIFIED'
                              : 'UNVERIFIED',
                        }}
                        config={{ action: false, icon: true }}
                      />
                    ),
                    state: { type: 'success' },
                  },
                },
              ]);

              setCreatedExport((list) => [
                ...(list ? list : []),
                {
                  firstName: motorist.firstName,
                  lastName: motorist.lastName,
                  plate: motorist.plate,
                  uid: motorist.uid,
                  email: motorist.email,
                  visibleId: motorist.visibleId,
                  state: 'success',
                },
              ]);

              return { title: 'CreateLocalMotorists', response };
            } catch (error: unknown) {
              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <MotoristLine
                        data={{
                          firstName: motorist.firstName,
                          lastName: motorist.lastName,
                          email: motorist.email,
                          status: 'UNVERIFIED',
                        }}
                        config={{ action: false, icon: true }}
                      />
                    ),
                    state: {
                      type: 'error',
                      message:
                        error instanceof ApolloError
                          ? `${error.message}`
                          : `${error}`,
                    },
                  },
                },
              ]);

              setCreatedExport((list) => [
                ...(list ? list : []),
                {
                  firstName: motorist.firstName,
                  lastName: motorist.lastName,
                  plate: motorist.plate,
                  uid: motorist.uid,
                  email: motorist.email,
                  visibleId: motorist.visibleId,
                  state: 'error',
                },
              ]);

              return {
                title: 'CreateLocalMotorists',
                response: new Error(
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
                ),
              };
            }
          },
          async (
            prevResult: Array<UseCascadeResult<ResultPosibility>>,
            index: number,
          ) => {
            try {
              if (
                prevResult[0].response instanceof Error ||
                !('_id' in prevResult[0].response)
              )
                throw t('poolWasNotCreated');

              const prevNearResut = prevResult?.[index - 1];

              const response = (await requestGQL({
                params: {
                  input: {
                    address1: null,
                    address2: null,
                    city: null,
                    country: null,
                    firstName: motorist.firstName,
                    lang: motorist.lang,
                    lastName: motorist.lastName,
                    username: motorist.email,
                    zipcode: null,
                    contract: {
                      linkedContractId: prevResult[0].response.defaultPoolId,
                      sendInvitation: motorist.sendInvitation,
                      antiPassBackMode: contract.APBMode,
                      description: null,
                      isEnabled: contract.state,
                      startDate: contract.startDate!,
                      stopDate: contract.endDate,
                      reference: null,
                      freeFlagIsEnabled: true,
                      productId: product.id!,
                    },
                    localMotoristId:
                      prevNearResut &&
                      !(prevNearResut.response instanceof Error) &&
                      'motorist' in prevNearResut.response &&
                      prevNearResut.response?.motorist?._id
                        ? prevNearResut.response.motorist._id
                        : null,
                    credentials: convertCredentialsFrontToBack(
                      [
                        {
                          value: motorist.visibleId,
                          description: motorist.uid,
                          type: 'uid',
                        },
                        {
                          value: motorist.plate,
                          description: null,
                          type: 'plate',
                        },
                      ].reduce(
                        (
                          acc: CredentialType.CredentialFromFront[],
                          { value, type, description },
                        ) => {
                          if (type === 'plate' && value) {
                            return [
                              ...acc,
                              {
                                name: 'PLATE',
                                plate: value.toUpperCase(),
                                description: '',
                              } as CredentialType.CredentialFromFront,
                            ];
                          } else if (type === 'uid' && value) {
                            return [
                              ...acc,
                              {
                                name: 'RFID',
                                visibleId: value,
                                uid: description,
                              } as CredentialType.CredentialFromFront,
                            ];
                          } else {
                            return acc;
                          }
                        },
                        [],
                      ),
                    ),
                  },
                } as CreateMotorist,
                gql: createMotoristGQL,
              })) as CreateMotoristResponse;

              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <ContractMotoristLine
                        data={{
                          firstName: response.motorist.firstName,
                          contractId: response.contract.ospContractId,
                          lastName: response.motorist.lastName,
                          status: response.contract.isEnabled
                            ? 'ACTIVE'
                            : 'SUSPENDED',
                        }}
                        config={{ action: false }}
                      />
                    ),
                    state: { type: 'success' },
                  },
                },
              ]);

              return { title: 'CreateMotorist', response };
            } catch (error: unknown) {
              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <ContractMotoristLine
                        data={{
                          firstName: motorist.firstName,
                          contractId: null,
                          lastName: motorist.lastName,
                          status: contract.state ? 'ACTIVE' : 'SUSPENDED',
                        }}
                        config={{ action: false }}
                      />
                    ),
                    state: {
                      type: 'error',
                      message:
                        error instanceof ApolloError
                          ? `${error.message}`
                          : `${error}`,
                    },
                  },
                },
              ]);

              return {
                title: 'CreateMotorist',
                response: new Error(
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
                ),
              };
            }
          },
        ])
      : motorists.checked.map(
          (motorist) =>
            async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
              const _id = uuidv4();

              try {
                if (
                  prevResult[0].response instanceof Error ||
                  !('_id' in prevResult[0].response)
                ) {
                  throw t('poolWasNotCreated');
                }

                const response = (await requestGQL({
                  params: {
                    input: {
                      address1: motorist.address1,
                      address2: motorist.address2,
                      city: motorist.city,
                      country: motorist.country,
                      firstName: motorist.firstName,
                      lang: motorist.lang,
                      lastName: motorist.lastName,
                      username: motorist.email,
                      zipcode: `${motorist.zipcode}` ? motorist.zipcode : null,
                      contract: {
                        linkedContractId: prevResult[0].response.defaultPoolId,
                        sendInvitation: true,
                        antiPassBackMode: contract.APBMode,
                        description: null,
                        isEnabled: contract.state,
                        startDate: contract.startDate!,
                        stopDate: contract.endDate,
                        reference: null,
                        freeFlagIsEnabled: true,
                        productId: product.id!,
                      },
                      localMotoristId: motorist._id,
                      credentials: convertCredentialsFrontToBack([
                        ...motorist.uids.map(
                          ({ value, description }) =>
                            ({
                              name: 'RFID',
                              visibleId: value,
                              uid: description,
                            } as CredentialType.CredentialFromFront),
                        ),
                        ...motorist.plates.map(
                          ({ value, description }) =>
                            ({
                              name: 'PLATE',
                              plate: value,
                              description: description,
                            } as CredentialType.CredentialFromFront),
                        ),
                      ]),
                    },
                  } as CreateMotorist,
                  gql: createMotoristGQL,
                })) as CreateMotoristResponse;

                setCreated((list) => [
                  ...(list ? list : []),
                  {
                    _id,
                    items: {
                      component: (
                        <ContractMotoristLine
                          data={{
                            firstName: response.motorist.firstName,
                            contractId: response.contract.ospContractId,
                            lastName: response.motorist.lastName,
                            status: response.contract.isEnabled
                              ? 'ACTIVE'
                              : 'SUSPENDED',
                          }}
                          config={{ action: false }}
                        />
                      ),
                      state: { type: 'success' },
                    },
                  },
                ]);

                return { title: 'CreateMotorist', response };
              } catch (error: unknown) {
                setCreated((list) => [
                  ...(list ? list : []),
                  {
                    _id,
                    items: {
                      component: (
                        <ContractMotoristLine
                          data={{
                            firstName: motorist.firstName,
                            contractId: null,
                            lastName: motorist.lastName,
                            status: contract.state ? 'ACTIVE' : 'SUSPENDED',
                          }}
                          config={{ action: false }}
                        />
                      ),
                      state: {
                        type: 'error',
                        message:
                          error instanceof ApolloError
                            ? `${error.message}`
                            : `${error}`,
                      },
                    },
                  },
                ]);

                return {
                  title: 'CreateMotorist',
                  response: new Error(
                    error instanceof ApolloError
                      ? `${error.message}`
                      : `${error}`,
                  ),
                };
              }
            },
        )),
  ]);

  useEffect(() => {
    validate(isFinish);
  }, [isFinish]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            productName: product.id ? t(`product-${product.id}-name`) : null,
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <SummaryForm
          data={{
            list: created,
            created: createdToExport,
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
