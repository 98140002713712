import { gql } from "@apollo/client"

export const removeCredGql = gql`
    mutation removeCredOnContract(
        $contractId: String!,
        $value: String!
    ) {
    
        removeCredOnContract(
            contractId: $contractId,
            value: $value
        ) {
            _id
            credentials {
                type
                provider
                value
                description
            }
        }
    
    }
`