import { gql } from '@apollo/client';
import { Motorist } from '../types/entities/motorist';

export type CreateLocalMotorists = {
  input: {
    email: string | null;
    plates: Array<{
      description: string | null;
      value: string | null;
      type: 'PLATE';
    }>;
    uids: Array<{
      description: string | null;
      value: string | null;
      provider: string;
      type: 'RFID';
    }>;
    cards: Array<{
      description: string | null;
      value: string | null;
      provider: string;
      type: 'PROVIDER_EXTERNAL_ID';
    }>;
    firstName: string | null;
    lastName: string | null;
    address1: string | null;
    address2: string | null;
    zipcode: string | null;
    city: string | null;
    country: string | null;
    phone: string | null;
    lang: string | null;
    sendInvitation: boolean | null;
    linkedContractId: string | null;
  };
};

export type CreateLocalMotoristsResponse = {
  motorist: {
    _id: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    lang: string | null;
    status: Motorist['state'];
    linkedMotoristId: string | null;
  };
};

export const createLocalMotoristGQL = gql`
  mutation createLocalMotorist($input: CreateLocalMotoristsDto!) {
    createLocalMotorist(input: $input) {
      motorist {
        _id
        firstName
        lastName
        email
        lang
        status
        linkedMotoristId
      }
    }
  }
`;
