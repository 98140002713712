import { gql } from "@apollo/client";

export const updatePoolContractGql = gql`
    mutation updatePoolContract(
        $contractId: String!
        $input: UpdatePoolContractDto!
    ) {
        updatePoolContract(contractId: $contractId, input: $input) {
            _id
            spacesOccupied
            spacesAllocated
        }
    }
`