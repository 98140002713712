import { ContractMasterPoolLine } from '@gimlite/osp/composition/line/contract-master-pool.line';
import { ContractMotoristLine } from '@gimlite/osp/composition/line/contract-motorist.line';
import { MotoristLine } from '@gimlite/osp/composition/line/motorist.line';
import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationForm } from '../../../../form/confirmation/confirmation.form';
import { CreateNewMasterPoolContractWizardContext } from '../../createNewMasterPoolContract.wizard';

export const ConfirmationStep = ({
  context: {
    contract,
    product,
    motoristMode,
    motorists,
    tenant,
    motoristExistedSave,
  },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewMasterPoolContractWizardContext>) => {
  const { t, lang } = useTranslation();

  const [allFeatures, setAllFeatures] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const features: JSX.Element[] = [];

    setAllFeatures(() => []);
    features.push(
      <ContractMasterPoolLine
        config={{
          action: false,
        }}
        data={{
          name1: tenant.name1 as string,
          name2: tenant.name2,
          status: contract.state ? 'ACTIVE' : 'SUSPENDED',
          code: t('willBeGenerated'),
        }}
      />,
    );

    if (motoristMode === 'create') {
      motorists.created.forEach(({ email, firstName, lastName }) => {
        features.push(
          <MotoristLine
            data={{
              firstName,
              lastName,
              status: 'UNVERIFIED',
              email,
            }}
            config={{ icon: true, action: false }}
          />,
        );

        features.push(
          <ContractMotoristLine
            config={{
              action: false,
            }}
            data={{
              firstName,
              lastName,
              status: contract.state ? 'ACTIVE' : 'SUSPENDED',
              contractId: t('willBeGenerated'),
            }}
          />,
        );
      });
    } else {
      motorists.checked.forEach(({ lastName, firstName }) => {
        features.push(
          <ContractMotoristLine
            config={{
              action: false,
            }}
            data={{
              firstName,
              lastName,
              status: contract.state ? 'ACTIVE' : 'SUSPENDED',
              contractId: t('willBeGenerated'),
            }}
          />,
        );
      });
    }

    setAllFeatures(features);
  }, [lang]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            productName: product.id ? t(`product-${product.id}-name`) : null,
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <ConfirmationForm
          data={{
            list: allFeatures.map((node) => {
              return {
                _id: uuidv4(),
                items: {
                  component: node,
                },
              };
            }),
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
