import { getOperationName } from '@apollo/client/utilities';
import {
  ActionInline,
  ActionInlineType,
} from '@gimlite/osp/components/action-inline/action-inline.component';
import { requestGQL, useTranslation } from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { breakdownUpdateGql } from '../../common/gql/breakdown-update.gql';

export declare namespace BreakdownActionType {
  type Props = {
    config: {
      size: ActionInlineType.Config.Size;
    };
    data: {
      breakdownId: string;
      endDate?: string;
    };
  };
}

export const BreakdownAction = ({
  config: { size },
  data: { breakdownId, endDate },
}: BreakdownActionType.Props) => {
  const { t } = useTranslation();

  return (
    <ActionInline
      config={{ size }}
      data={[
        {
          key: 'stopBreakdown',
          text: t('stopBreakdown'),
          color: 'error',
          isCritical: true,
          show: DateTime.fromISO(endDate || '') > DateTime.now(),
          event: () =>
            requestGQL({
              operationName: getOperationName(breakdownUpdateGql)!!,
              params: {
                breakdownId,
                input: {
                  endDate: DateTime.now().toISO(),
                },
              },
              gql: breakdownUpdateGql,
            }),
        },
      ]}
    />
  );
};
