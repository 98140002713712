import { EventObject, assign, createMachine, interpret } from 'xstate';
import { ChoiceCreateContractWizardContext } from './choiceCreateContract.wizard';

export type ChoiceCreateContractServiceContextType = {
  parkingId: ChoiceCreateContractWizardContext['parkingId'];
  actions: ChoiceCreateContractWizardContext['actions'];
  mode: ChoiceCreateContractWizardContext['mode'];
  error: string | null;
};

const choiceCreateContractServiceContext: ChoiceCreateContractServiceContextType =
  {
    parkingId: null,
    actions: {
      createNewSingleContract: true,
      createNewMasterPoolContract: true,
      createNewSubPoolContract: true,
      addOneMotoristToAnExistingPoolContract: true,
      addSeveralMotoristsToAnExistingPoolContract: true,
    },
    mode: 'operator',
    error: null,
  };

const machine = createMachine({
  id: 'choiceCreateContract',
  initial: 'off',
  context: choiceCreateContractServiceContext,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
          actions: assign({
            parkingId: (
              _,
              {
                parkingId,
              }: EventObject & {
                parkingId: ChoiceCreateContractServiceContextType['parkingId'];
              },
            ) => parkingId,
            actions: (
              _,
              {
                actions,
              }: EventObject & {
                actions: ChoiceCreateContractServiceContextType['actions'];
              },
            ) => {
              return {
                ...choiceCreateContractServiceContext.actions,
                ...(actions ? actions : {}),
              };
            },
            mode: (
              _,
              {
                mode,
              }: EventObject & {
                mode: ChoiceCreateContractServiceContextType['mode'];
              },
            ) => {
              return mode || choiceCreateContractServiceContext.mode;
            },
          }),
        },
      } as any,
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
      },
    },
    failure: {
      on: {
        RETRY: {
          actions: assign({ error: null }),
          target: 'idle',
        },
      },
    },
  },
});

export const choiceCreateContractService = interpret(machine).start();
