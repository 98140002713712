import { gql } from '@apollo/client';
import { APBModeGlobalName } from '../mapper/abp.mapper';
import { Credential } from '../types/entities/credential';

export type CreateMotoristResponse = {
  motorist: {
    _id: string;
    username: string;
    firstName: string;
    lastName: string;
    state: 'VERIFIED' | 'TO_VERIFY';
  };
  contract: {
    ospContractId: string | null;
    isEnabled: boolean;
  };
};

export type CreateMotorist = {
  input: {
    username: string | null;
    firstName: string | null;
    lastName: string | null;
    password: string | null;
    birthDate: string | null;
    address1: string | null;
    address2: string | null;
    zipcode: string | null;
    city: string | null;
    country: string | null;
    phone: string | null;
    lang: string | null;
    gender: string | null;
    contract: {
      productId: string;
      startDate: string;
      stopDate: string | null;
      isEnabled: boolean;
      freeFlagIsEnabled: boolean | null;
      linkedContractId: string | null;
      sendInvitation: boolean | null;
      antiPassBackMode: APBModeGlobalName | null;
      reference: string | null;
      description: string | null;
    };
    credentials: Credential[];
    localMotoristId: string | null;
  };
};

export const createMotoristGQL = gql`
  mutation createMotorist($input: CreateMotoristDto!) {
    createMotorist(input: $input) {
      motorist {
        _id
        username
        firstName
        lastName
        state
      }
      contract {
        ospContractId
        isEnabled
      }
    }
  }
`;
