import { gql } from '@apollo/client';

export const technicalEventsGql = gql`
  query technicalEvents(
    $parkingId: String!
    $incidentPage: Int
    $incidentLimit: Int = 30
    $archived: String
    $criticity: [String]
    $category: [String]
  ) {
    technicalEvents(
      parkingId: $parkingId
      page: $incidentPage
      limit: $incidentLimit
      archived: $archived
      criticity: $criticity
      category: $category
    ) {
      list {
        _id
        key
        from
        category
        criticity
        createdAt
        updatedAt
        archived
        to {
          channel
          _id
        }
        payload
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
export const archiveTechnicalEventsGql = gql`
  mutation archiveTechnicalEvent($technicalEventId: String!) {
    archiveTechnicalEvent(technicalEventId: $technicalEventId) {
      archived
    }
  }
`;
