import {
  Col,
  Switch,
  SwitchType,
  Write,
  WriteType,
  useTranslation,
} from '@gimlite/watermelon';
import { Popover } from '@gimlite/watermelon';
import './action.component.scss';

export declare namespace ActionType {
  type Props = {
    className?: string;
    data: ActionType.Data[];
  };

  type Data = {
    key: string;
    text: string;
    show?: boolean;
    color?: Extract<WriteType.Config.Color, 'label' | 'error'>;
    isCritical?: boolean;
    event?: ({
      key,
      type,
      value,
    }: {
      key: ActionType.Data['key'];
      type: 'click' | 'switch';
      value?: SwitchType.Data.DefaultValue;
    }) => void;
    switch?: {
      isActive: SwitchType.Data.DefaultValue;
      text: SwitchType.Config.Text;
      color: SwitchType.Config.Color;
    };
  };
}

export const Action = ({ data, className = '' }: ActionType.Props) => {
  const { t, lang } = useTranslation();

  return (
    <Popover.Context.Consumer>
      {({ close }) => {
        return (
          <Col>
            {data.map((element, index) => {
              const {
                key,
                text,
                color,
                event,
                show = true,
                isCritical = false,
              } = element;

              const node = (
                <div className="action__item">
                  <Write
                    className={`action__item__text ${
                      !element?.switch ? 'action__item__text--pure' : ''
                    }`}
                    key={index}
                    data={{ item: text }}
                    config={{
                      color: color,
                      mode: 'namise-description',
                    }}
                  ></Write>

                  {element?.switch && (
                    <Switch
                      className="action__item__switch"
                      handleEvent={{
                        change: (isActive) => {
                          event?.({ key, type: 'switch', value: isActive });
                          close?.();
                        },
                      }}
                      data={{ defaultValue: element.switch.isActive }}
                      config={{
                        size: 'small',
                        color: element.switch.color,
                        text: element.switch.text,
                      }}
                    />
                  )}
                </div>
              );

              return (
                show &&
                (isCritical ? (
                  <Popover.Confirm
                    key={`action-item-${key}`}
                    handleEvent={{
                      confirm: () => {
                        if (!element?.switch) event?.({ key, type: 'click' });
                        close?.();
                      },
                    }}
                    config={{
                      title: t('importantAction'),
                      description: t('areYouSureAboutThis?'),
                    }}
                  >
                    {node}
                  </Popover.Confirm>
                ) : (
                  <div
                    className="action"
                    key={`action-item-${key}`}
                    onClick={() => {
                      if (!element?.switch) event?.({ key, type: 'click' });
                      close?.();
                    }}
                  >
                    {node}
                  </div>
                ))
              );
            })}
          </Col>
        );
      }}
    </Popover.Context.Consumer>
  );
};
