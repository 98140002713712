import { gql } from "@apollo/client";

export const regenQRGql = gql`
    mutation regenQR($motoristId: String!) {
        regenerateQrcode(motoristId: $motoristId) {
            motorist {
                _id
                credentials {
                    type
                    provider
                    value
                }
            }
        }
    }
`