import { MotoristLine } from '@gimlite/osp/composition/line/motorist.line';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationForm } from '../../../../form/confirmation/confirmation.form';
import { CreateSeveralMotoristsContext } from '../../createSeveralMotorists.wizard';

export const ConfirmationStep = ({
  context: {
    motorists: { created },
  },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateSeveralMotoristsContext>) => {
  const { t, lang } = useTranslation();

  const [allFeatures, setAllFeatures] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const features: JSX.Element[] = [];

    created.forEach(({ _id, email, firstName, lastName }) => {
      features.push(
        <MotoristLine
          data={{
            firstName: firstName,
            lastName: lastName,
            status: 'UNVERIFIED',
            email,
          }}
          config={{ action: false, icon: true }}
        />,
      );
    });

    setAllFeatures(features);
  }, [lang]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['list']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'list' }}>
        <ConfirmationForm
          data={{
            list: allFeatures.map((node) => {
              return {
                _id: uuidv4(),
                items: {
                  component: node,
                },
              };
            }),
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
