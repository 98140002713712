import {
  BadgeType,
  Row,
  Write,
  Zone,
  useTranslation,
} from '@gimlite/watermelon';
import { Blurb } from '@gimlite/watermelon/components/blurb/blurb.component';
import { DateTime } from 'luxon';
import { BreakdownAction } from '../action/breakdown.action';

class Static {
  static statusBadgeColorMap: Record<
    BreakdownLineType.Data.Status,
    BadgeType.Config.Color
  > = {
    FINISHED: 'success',
    PROGRESS: 'error',
  };
}

export declare namespace BreakdownLineType {
  type Props = {
    data: {
      problem: string;
      status: BreakdownLineType.Data.Status;
      access: BreakdownLineType.Data.Access;
      startDate: string;
      endDate?: string;
      breakdownId: string;
    };
  };

  namespace Data {
    type Status = 'FINISHED' | 'PROGRESS';
    type Access = 'ENTRY' | 'EXIT';
  }
}

export const BreakdownLine = ({
  data: { problem, status, access, startDate, endDate, breakdownId },
}: BreakdownLineType.Props) => {
  const { t } = useTranslation();

  return (
    <Zone
      config={{
        gap: {
          x: 1,
          y: 0,
        },
        zones: [['identity', 'startDate', 'endDate', 'action']],
        rows: ['1fr'],
        columns: ['1fr', '1fr', '1fr', 'min-content'],
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Zone.Area config={{ area: 'identity' }}>
        <Blurb
          data={{
            title: t(access).toUpperCase(),
            description: problem,
            badge: [
              {
                text: t(status).toUpperCase(),
                color: Static.statusBadgeColorMap[status],
              },
            ],
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'startDate' }}>
        <Row config={{ horizontal: 'start', vertical: 'center' }}>
          <Write
            data={{
              item: DateTime.fromISO(startDate).toFormat('dd/MM/yyyy HH:mm:ss'),
            }}
            config={{ mode: 'key-small-bold' }}
          ></Write>
        </Row>
      </Zone.Area>

      <Zone.Area config={{ area: 'endDate' }}>
        <Row config={{ horizontal: 'start', vertical: 'center' }}>
          {endDate && (
            <Write
              data={{
                item: DateTime.fromISO(endDate).toFormat('dd/MM/yyyy HH:mm:ss'),
              }}
              config={{ mode: 'key-small-bold' }}
            ></Write>
          )}
        </Row>
      </Zone.Area>

      <Zone.Area config={{ area: 'action' }}>
        <Row config={{ horizontal: 'center', vertical: 'center' }}>
          <BreakdownAction
            config={{ size: 'list' }}
            data={{ breakdownId, endDate }}
          />
        </Row>
      </Zone.Area>
    </Zone>
  );
};
