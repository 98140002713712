import { gql } from '@apollo/client';

export const globalSearchMotoristGql = gql`
  query globalSearch($content: String!) {
    globalSearch(content: $content) {
      motorists {
        _id
        username
        firstName
      }
    }
  }
`;
