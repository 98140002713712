import {
  ListFlatInfo,
  Widget,
  useMyUrl,
  useSearchOf,
  useTranslation,
} from '@gimlite/watermelon';

import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { breakdownsGql } from '../../common/gql/breakdowns.gql';
import { Breakdown } from '../../common/types/entities/breakdowns';
import { BreakdownLine, BreakdownLineType } from '../line/breakdown.line';

type BreakdownListProps = {
  parkingId: string;
  widget?: {
    title?: string;
    icon?: string;
  };
  setSelectedBreakdown?: (id: string) => void;
};

export const BreakdownList = ({
  widget,
  parkingId,
  setSelectedBreakdown,
}: BreakdownListProps) => {
  const { getParamsUrl, getParamsUrlString, setParamsUrl } = useMyUrl({});
  const { t } = useTranslation();
  const { breakdownId } = useParams();
  const paramsFormatted = useMemo(() => {
    // get filters params here
    const {} = getParamsUrl;

    return {};
  }, [getParamsUrl]);
  useEffect(() => {
    setParamsUrl({
      ...getParamsUrl,
      breakDownPage: 1,
      breakDownLimit: 15,
      parkingId,
    });
  }, [parkingId]);
  useEffect(() => {
    setSearch({
      paging: { breakDownPage: 1, breakDownLimit: 15 },
      params: { ...getParamsUrl, parkingId },
    });
  }, [paramsFormatted, parkingId]);
  const { list, paging, setSearch } = useSearchOf<
    Breakdown,
    { breakDownPage: number; breakDownLimit: number }
  >({
    gql: breakdownsGql,
    wsSubscriptions: ['breakdowns:created', 'breakdowns:updated'],
  });
  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
    >
      <ListFlatInfo<any>
        config={{
          pagination: 'scroll',
          columns: [
            { key: 'component', label: t('type'), size: '1fr' },
            { key: 'component', label: t('startDate'), size: '1fr' },
            { key: 'component', label: t('endDate'), size: '1fr' },
          ],
          header: true,
        }}
        handleEvent={{
          paging: (paging) => {
            setParamsUrl({
              ...getParamsUrl,
              breakDownPage: paging.page,
              breakDownLimit: paging.limit,
            });
            setSearch({
              paging: {
                ...getParamsUrl,
                breakDownPage: paging.page,
                breakDownLimit: paging.limit,
              },
              params: { ...paramsFormatted, parkingId },
            });
          },
          click: (breakdownId) => setSelectedBreakdown?.(breakdownId),
        }}
        data={{
          defaultSelected: breakdownId,
          list: list
            ? list.map(({ _id, description, startDate, endDate, type }) => ({
                _id,
                items: {
                  component: (
                    <BreakdownLine
                      data={{
                        problem: description,
                        status:
                          DateTime.fromISO(endDate) < DateTime.now()
                            ? 'FINISHED'
                            : 'PROGRESS',
                        access:
                          type.toLowerCase() as BreakdownLineType.Data.Access,
                        startDate,
                        endDate,
                        breakdownId: _id,
                      }}
                    />
                  ),
                },
              }))
            : undefined,
          paging: paging,
        }}
      />
    </Widget>
  );
};
