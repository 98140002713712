import { assign, createMachine, interpret } from 'xstate';

const initialContext = {
  bffError: null,
};

const appMachine = createMachine({
  id: 'app',
  context: initialContext,
  predictableActionArguments: true,
  preserveActionOrder: true,
  initial: 'idle',
  states: {
    idle: {
      on: {
        BFF_ERROR: {
          actions: assign({
            bffError: (_, { error }) => error,
          }),
        },
      },
    },
  },
});

export const appService = interpret(appMachine).start();
