import { gql } from "@apollo/client";

export const contractWParentsGql = gql`
    query contractWParents($parkingId: String!, $contractId: String!) {
        contractWParents(parkingId: $parkingId, contractId: $contractId) {
            _id
            
            parent {
                _id
                
                parent {
                    _id
                }
            }
        }
    }
`