import { ApolloError } from '@apollo/client';
import { ContractSubPoolLine } from '@gimlite/osp/composition/line/contract-sub-pool.line';
import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import { requestGQL, useCascade } from '@gimlite/watermelon';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateSubPool,
  CreateSubPoolResponse,
  createSubPoolGQL,
} from '../../../../common/gql/createSubPool.gql';
import {
  SummaryForm,
  SummaryFormType,
} from '../../../../form/summary/summary.form';
import { CreateNewSubPoolContractContext } from '../../createNewSubPoolContract.wizard';

type ResultPosibility = CreateSubPoolResponse;

export const SummaryStep = ({
  context: { contract, informations, masterPoolContractId },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewSubPoolContractContext>) => {
  const { t, lang }: any = useTranslation();
  const [created, setCreated] = useState<SummaryFormType.Data.Line[]>();

  const { isFinish } = useCascade<ResultPosibility>([
    async () => {
      try {
        const response = (await requestGQL({
          params: {
            input: {
              contractId: masterPoolContractId!,
              newContractSpacesAllocated: contract.capacity!, //! Les valiteurs force la présence
              contractName: informations.name1!, //! Les valiteurs force la présence
              startDate: contract.startDate!, //! Les valiteurs force la présence
              stopDate: contract.endDate!, //! Les valiteurs force la présence
              isEnabled: contract.state,
              reference: contract.reference,
              description: contract.description,
              antiPassBackMode: contract.APBMode,
              name2: informations.name2,
            },
          } as CreateSubPool,
          gql: createSubPoolGQL,
        })) as CreateSubPoolResponse;

        setCreated(() => [
          {
            _id: uuidv4(),
            items: {
              component: (
                <ContractSubPoolLine
                  data={{
                    name1: response.name1,
                    name2: response.name2,
                    code: response.ospContractId,
                    status: response.isEnabled ? 'ACTIVE' : 'SUSPENDED',
                  }}
                  config={{ action: false }}
                />
              ),
              state: {
                type: 'success',
              },
            },
          },
        ]);

        return { title: 'CreateSubPool', response };
      } catch (error: unknown) {
        setCreated(() => [
          {
            _id: uuidv4(),
            items: {
              component: (
                <ContractSubPoolLine
                  data={{
                    name1: informations.name1!,
                    name2: informations.name2,
                    status: contract.state ? 'ACTIVE' : 'SUSPENDED',
                    code: null,
                  }}
                  config={{ action: false }}
                />
              ),
              state: {
                type: 'error',
                message:
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
              },
            },
          },
        ]);

        return {
          title: 'CreateSubPool',
          response: new Error(
            error instanceof ApolloError ? `${error.message}` : `${error}`,
          ),
        };
      }
    },
  ]);

  useEffect(() => {
    validate(isFinish);
  }, [isFinish]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <SummaryForm
          data={{
            list: created,
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
