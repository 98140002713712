import { useTranslation } from '@gimlite/watermelon';
import {
  Timeline,
  TimelineType,
} from '../../components/timeline/timeline.component';

export declare namespace ExitedSessionTimelineType {
  type Props = {
    data: {
      date: TimelineType.Data.Date;
      device: TimelineType.Data.Device;
    };
  };
}

export const ExitedSessionTimeline = ({
  data: { date, device },
}: ExitedSessionTimelineType.Props) => {
  const { t } = useTranslation();
  return (
    <Timeline
      config={{
        color: 'success',
      }}
      data={{
        date,
        device,
        title: t('global-state-exited'),
      }}
    />
  );
};
