import {
  Col,
  DatePicker,
  DatePickerType,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Widget,
  Write,
  Zone,
  useTranslation,
  TextArea,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import {
  APBModeGlobal,
  APBModeGlobalName,
} from '../../common/mapper/abp.mapper';
import './contract-create.form.scss';

export declare namespace ContractCreateFormType {
  type Props = {
    data: {
      contract: ContractCreateFormType.Data.ContractMotorist &
        ContractCreateFormType.Data.ContractPool;
    };
    config?: {
      parentContract?: ContractCreateFormType.Data.ContractPool;
      disabled?: {
        endDate?: boolean;
      };
    };
  };

  namespace Data {
    type ContractMotorist = {
      startDate: string | null;
      endDate: string | null;
      APBMode: APBModeGlobalName | null;
      APBNext: boolean | null;
      reference: string | null;
      description: string | null;
      state: boolean;
    };

    type ContractPool = {
      startDate: string | null;
      endDate: string | null;
      APBMode: APBModeGlobalName | null;
      APBNext: boolean | null;
      reference: string | null;
      description: string | null;
      state: boolean;
      capacity?: number | null;
    };
  }
}

export const ContractFormCreate = ({
  data: { contract },
  config: { disabled, parentContract } = {},
}: ContractCreateFormType.Props) => {
  const { t } = useTranslation();

  const startDateLimit = useMemo((): {
    min: DatePickerType.Config.Min | null;
    max: DatePickerType.Config.Max | null;
  } => {
    return {
      min: parentContract?.startDate || new Date().toISOString(),
      max: disabled?.endDate ? null : contract.endDate,
    };
  }, [parentContract?.startDate, contract.endDate, disabled?.endDate]);

  const endDateLimit = useMemo((): {
    min: DatePickerType.Config.Min | null;
    max: DatePickerType.Config.Max | null;
  } => {
    return {
      min: contract.startDate || new Date().toISOString(),
      max: parentContract?.endDate || null,
    };
  }, [contract.startDate, parentContract?.endDate]);

  return (
    <Zone
      config={{
        zones: [['validity', 'other']],
        rows: ['1fr'],
        columns: ['1fr', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'validity' }}>
        <Widget config={{ title: t('validity'), shadow: false }}>
          <Col>
            <Form.Item
              data={{
                defaultValue: contract.startDate,
              }}
              config={{
                name: 'startDate',
                label: t('startDate'),
                rules: [{ required: true }],
                way: 'vertical',
              }}
            >
              <DatePicker
                config={{
                  defaultTime: 'startDay',
                  width: 'full',
                  format: 'datetime',
                  min: startDateLimit.min,
                  max: startDateLimit.max,
                }}
              />
            </Form.Item>
            <Space config={{ count: 2 }} />
            <Form.Item
              data={{ defaultValue: contract.endDate }}
              config={{
                name: 'endDate',
                label: t('endDate'),
                rules: [{ required: true }],
                way: 'vertical',
              }}
            >
              <DatePicker
                config={{
                  defaultTime: 'endDay',
                  disabled: !!disabled?.endDate,
                  width: 'full',
                  format: 'datetime',
                  min: endDateLimit.min,
                  max: endDateLimit.max,
                }}
              />
            </Form.Item>
            <Space config={{ count: 2 }} />
            <Form.Item
              data={{ defaultValue: contract.state }}
              config={{
                name: 'state',
                label: t('stateOfContract'),
                rules: [{ required: true }],
                way: 'horizontal',
              }}
            >
              <Switch
                config={{
                  width: 'full',
                  color: { active: 'success', inactive: 'warn' },
                  text: { active: t('active'), inactive: t('suspend') },
                }}
              />
            </Form.Item>
          </Col>
        </Widget>
      </Zone.Area>

      <Zone.Area config={{ area: 'other' }}>
        <Widget config={{ title: t('otherParams'), shadow: false }}>
          <Col>
            {contract?.capacity !== undefined && (
              <>
                <Form.Item
                  data={{
                    defaultValue: contract.capacity,
                  }}
                  config={{
                    name: 'capacity',
                    label: t('capacity'),
                    rules: [{ required: true }],
                    way: 'vertical',
                  }}
                >
                  <Input config={{ width: 'full', type: { name: 'number' } }} />
                </Form.Item>
                <Space config={{ count: 2 }} />
              </>
            )}

            <Form.Item
              data={{ defaultValue: contract.reference }}
              config={{
                name: 'reference',
                label: t('reference'),
                way: 'vertical',
              }}
            >
              <Input config={{ width: 'full' }} />
            </Form.Item>
            <Space config={{ count: 2 }} />
            <Form.Item
              data={{ defaultValue: contract.description }}
              config={{
                name: 'description',
                label: t('description'),
                way: 'vertical',
              }}
            >
              <TextArea config={{ width: 'full' }} />
            </Form.Item>
            <Space config={{ count: 2 }} />
            <Form.Item
              data={{
                defaultValue: contract.APBMode,
              }}
              config={{
                name: 'APBMode',
                label: t('APBMode'),
                tooltip: (
                  <Write
                    data={{
                      item: t('TOOLPIP_PRESENT_ANTIPASSBACK_MODE'),
                    }}
                    config={{ mode: 'key-small-bold' }}
                  />
                ),
                way: 'horizontal',
              }}
            >
              <Select
                data={{
                  items: APBModeGlobal.map((value) => ({
                    label: t(value.toLowerCase()),
                    value,
                  })),
                }}
                config={{ width: 'full' }}
              />
            </Form.Item>
            <Space config={{ count: 2 }} />
            {contract.APBMode !== 'NONE' && !!contract.state && (
              <Form.Item
                data={{ defaultValue: contract.APBNext }}
                config={{
                  name: 'APBNext',
                  label: t('APBNext'),
                  way: 'horizontal',
                  tooltip: (
                    <Write
                      data={{
                        item: t('TOOLPIP_PRESENT_ANTIPASSBACK_NEXT'),
                      }}
                      config={{ mode: 'key-small-bold' }}
                    />
                  ),
                }}
              >
                <Switch
                  config={{
                    width: 'full',
                    color: { active: 'success', inactive: 'warn' },
                    text: { active: t('active'), inactive: t('suspend') },
                  }}
                />
              </Form.Item>
            )}
          </Col>
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
