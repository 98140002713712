import { useTranslation } from '@gimlite/watermelon';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useEffect } from 'react';
import { DefaultMotoristWizard } from '../../../../common/defaultValue/wizard';
import { ProductForm } from '../../../../form/product/product.form';
import { CreateNewSingleContractWizardContext } from '../../createNewSingleContract.wizard';

export const ProductStep = ({
  context: { product, parkingId, contract },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewSingleContractWizardContext>) => {
  const { t, lang } = useTranslation();

  useEffect(() => {
    validate(!!product.id);
  }, [product]);

  return parkingId ? (
    <ProductForm
      handleEvent={{
        select: ({ id, period, APBMode, credentials, duration }) => {
          submit({
            product: { period, id, credentials: credentials || [], duration },
            contract: {
              startDate: new Date().toISOString(),
              endDate: null,
              APBNext: contract.APBNext,
              reference: null,
              description: null,
              state: true,
              APBMode: APBMode,
            },
            credentials: {
              actived: [],
              desactived: [],
              context: 'wizardNewMotorist',
            },
            motoristSearchSelect: null,
            motoristOrigin: {
              _id: null,
              origin: null,
            },
            addFakeQRCODE: false,
            motorist: {
              create: DefaultMotoristWizard({ t, lang }),
            },
          });
        },
      }}
      data={{
        parkingId,
        productId: product.id,
        category: 'PERIOD_SUBSCRIPTION',
      }}
    />
  ) : null;
};
