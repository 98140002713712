import { BadgeType, useTranslation } from '@gimlite/watermelon';

class Static {
  static contractBadgeColorMap: Record<
    ContractBadgesType.Data.State,
    BadgeType.Config.Color
  > = {
    ACTIVE: 'disabled',
    SUSPENDED: 'warn',
    EXPIRED: 'warn',
    PRESENT: 'success',
    UPCOMING: 'warn',
  };
}

export declare namespace ContractBadgesType {
  type Props = {
    states: [ContractBadgesType.Data.State];
  };

  namespace Data {
    type State = 'ACTIVE' | 'SUSPENDED' | 'EXPIRED' | 'PRESENT' | 'UPCOMING';
  }
}

export const useContractBadges = ({ states }: ContractBadgesType.Props) => {
  const { t } = useTranslation();

  return states.map((state) => {
    return {
      text: t(state).toUpperCase(),
      color: Static.contractBadgeColorMap[state],
    };
  });
};
