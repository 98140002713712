import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import {
  AutoCompleteType,
  Button,
  Col,
  Popover,
  requestGQL,
  Row,
  toCapitalizeCase,
  useCSV,
} from '@gimlite/watermelon';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useCallback, useEffect, useState } from 'react';
import { DefaultCredentialsQrcodeWizard } from '../../../../common/defaultValue/wizard';
import { globalSearchMergeGQL } from '../../../../common/gql/globalSearchMerge.gql';
import {
  convertCredentialsBackToFront,
  CredentialType,
} from '../../../../common/mapper/credential.mapper';
import { MotoristMerge } from '../../../../common/types/entities/motoristMerge';
import { PopupInfo } from '../../../../components/popup-info/popup-info.component';
import {
  MotoristCreateForm,
  MotoristCreateFormType,
} from '../../../../form/motorist-create/motorist-create.form';
import {
  MotoristCSVParams,
  MotoristsCreateFormType,
} from '../../../../form/motorists-create/motorists-create.form';
import { CreateNewSingleContractWizardContext } from '../../createNewSingleContract.wizard';

export const MotoristStep = ({
  context: {
    contract,
    product: { id: productId, credentials: productCredentials },
    credentials,
    motorist,
    motoristSearchSelect,
  },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewSingleContractWizardContext>) => {
  const { t, lang }: any = useTranslation();

  const [currentSearchItems, setCurrentSearchItems] = useState<
    AutoCompleteType.Data.Item[]
  >([]);

  const [currentSearchList, setCurrentSearchList] = useState<MotoristMerge[]>(
    [],
  );

  const searchMotorist = useCallback(async (content: string) => {
    const { motoristsMerge } = (await requestGQL({
      params: { content },
      gql: globalSearchMergeGQL,
    })) as { motoristsMerge: MotoristMerge[] };

    setCurrentSearchList(() => motoristsMerge);

    setCurrentSearchItems(() =>
      motoristsMerge.map(({ _id, firstName, lastName }) => ({
        label: `${firstName} ${lastName}`,
        value: _id,
      })),
    );
  }, []);

  const selectMotorist = useCallback(
    async (item: MotoristCreateFormType.Data.CurrentSearchMotoristValue) => {
      if (item) {
        const {
          _id,
          address1,
          address2,
          city,
          country,
          credentials: credentialNotFormatted,
          email,
          firstName,
          entityOrigin,
          lang,
          lastName,
          qrcode,
          zipcode,
          status,
        } = currentSearchList.find(
          (element) => element._id === item.value,
        ) as MotoristMerge;

        submit({
          credentials: {
            actived: convertCredentialsBackToFront(
              credentialNotFormatted,
              qrcode,
            ).filter(({ name }) => productCredentials.includes(name)),
            desactived: credentials.desactived,
            context: 'wizardMotoristExist',
          },
        });

        submit({
          motoristOrigin: {
            _id: _id,
            origin: entityOrigin,
          },
        });

        submit({
          motorist: {
            ...motorist,
            create: {
              _id,
              firstName: firstName ? toCapitalizeCase(firstName) : null,
              lastName: lastName ? toCapitalizeCase(lastName) : null,
              address1,
              address2,
              city,
              zipcode: zipcode ? zipcode : null,
              country,
              username: email,
              lang,
              invitationMotorist: false,
              status,
            },
          },
        });
      }
    },
    [motorist, currentSearchList],
  );

  useEffect(() => {
    validate(!!motoristSearchSelect);
  }, [motoristSearchSelect]);

  useEffect(() => {
    if (
      !!!credentials.actived.find(({ name }) => name === 'QRCODE') &&
      productCredentials.find((item) => item === 'QRCODE') &&
      lang
    ) {
      submit({
        addFakeQRCODE: true,
        credentials: {
          ...credentials,
          actived: [
            DefaultCredentialsQrcodeWizard({
              t,
              lang,
            }) as CredentialType.CredentialFromFront,
            ...credentials.actived,
          ],
        },
      });
    }
  }, [credentials.actived, lang]);

  const credentialsEdit = {
    RFID: productCredentials.includes('RFID'),
    PLATE: productCredentials.includes('PLATE'),
    TCS: productCredentials.includes('TCS'),
  };
  const CSVParams = MotoristCSVParams(credentialsEdit);
  const { firstName, lastName, username } = motorist.create;
  const { CombinationValidNode } = useCSV<MotoristsCreateFormType.Extra.CSV>({
    valid: CSVParams.valid,
    fields: CSVParams.fields.map(({ label, ...rest }) => ({
      ...rest,
      label: t(label),
    })),
    data: [
      {
        uid: !!credentials.actived.find(({ name }) => name === 'TCS')
          ? '123456789'
          : null,
        plate: credentials.actived.find(({ name }) => name === 'PLATE')
          ? '123456789'
          : null,
        visibleId: !!credentials.actived.find(({ name }) => name === 'TCS')
          ? '123456789'
          : null,
        firstName,
        lastName,
        email: username,
      },
    ],
  });
  return (
    <Form
      data={{ value: motorist.create }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ motorist: { create: value } });
        },
        validate: (value: boolean) => {
          validate(value);
        },
      }}
    >
      <Zone
        config={{
          gap: {
            y: 1,
            x: 1,
          },
          zones: [['info', 'form']],
          rows: ['1fr'],
          columns: ['min-content', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'info' }}>
          <ContractWizardInfos
            data={{
              productName: productId ? t(`product-${productId}-name`) : null,
              APBMode: contract.APBMode,
              APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
              state: contract.state,
              startDate: contract.startDate,
              endDate: contract.endDate,
              description: contract.description,
              reference: contract.reference,
            }}
          />
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          <MotoristCreateForm
            handleEvent={{
              submitCredentials: (value) => {
                submit({ credentials: value });
              },
              submitMotorist: (value) => {
                submit({ motorist: { ...motorist, create: value } });
              },
              validate: () => {
                const activedCredentials = credentials.actived.reduce(
                  (acc, credential) => {
                    switch (credential.name) {
                      case 'PLATE':
                        return { ...acc, plate: true };
                      case 'RFID':
                        return {
                          ...acc,
                          uid: true,
                          visibleId: credential.visibleId ? true : false,
                        };
                      case 'QRCODE':
                      default:
                        return acc;
                    }
                  },
                  {},
                );

                const currentCombinaison = Object.entries({
                  ...motorist.create,
                  ...activedCredentials,
                }).reduce((acc: any, [key, value]) => {
                  return value ? [...acc, key] : acc;
                }, []);

                const credentialsEdit = {
                  RFID: productCredentials.includes('RFID'),
                  PLATE: productCredentials.includes('PLATE'),
                  TCS: productCredentials.includes('TCS'),
                };

                const valid = MotoristCSVParams(credentialsEdit).valid.map(
                  (combinaison) =>
                    combinaison.map((item) =>
                      item === 'email' ? 'username' : item,
                    ),
                );

                const findValidCombinaison = valid.findIndex(
                  (combinaison) =>
                    combinaison.length === 0 ||
                    combinaison.every((item) =>
                      currentCombinaison.includes(item),
                    ),
                );

                validate(
                  findValidCombinaison !== -1
                    ? true
                    : t('watermelon-INVALID_COMBINAISON'),
                );
              },
            }}
            data={{
              credentials,
              motorist: { ...motorist.create },
            }}
            config={{
              credentialsEdit: {
                RFID: productCredentials.includes('RFID'),
                PLATE: productCredentials.includes('PLATE'),
                TCS: productCredentials.includes('TCS'),
              },
              search: {
                submitSearchMotoristValue: (value) => {
                  submit({ motoristSearchSelect: value });
                },
                searchMotorist: (value) => searchMotorist(value),
                clearSearchMotorist: () => setCurrentSearchItems([]),
                selectMotorist: (value) => {
                  if (value) selectMotorist(value);
                  submit({ motoristSearchSelect: value });
                },
                currentSearchMotoristItems: currentSearchItems,
                currentSearchMotoristValue: motoristSearchSelect,
              },
            }}
          />
        </Zone.Area>
        <Zone.Area config={{ area: 'empty' }}>
          <></>
        </Zone.Area>
        <Zone.Area config={{ area: 'csv' }}>
          <Col config={{ vertical: 'center', horizontal: 'start' }}>
            <Row config={{ horizontal: 'center' }}>
              <Popover
                data={
                  <PopupInfo
                    data={{
                      node: CombinationValidNode,
                    }}
                    config={{
                      title: t('contractFieldCombinations'),
                      description: [
                        {
                          key: 'required',
                          label: t('required'),
                          value: '*',
                          color: 'error',
                        },
                      ],
                    }}
                  />
                }
              >
                <Button
                  config={{
                    size: 'small',
                    mode: 'stroke',
                    text: t('validContractFieldsCombinations'),
                  }}
                ></Button>
              </Popover>
            </Row>
          </Col>
        </Zone.Area>
      </Zone>
    </Form>
  );
};
