import { useNavigate } from '@gimlite/router';
import { requestGQL } from '@gimlite/watermelon';
import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { contractsGql } from '../../common/gql/contracts.gql';
import { Contract } from '../../common/types/entities/contract';
import { ContractMotoristLine } from '../line/contract-motorist.line';

export type MotoristContractListProps = {
  parkingId?: string;
  motoristId?: string | null;
  freeflagId?: string;
  widget?: {
    title?: string;
    icon?: string;
  };
};

export const MotoristContractList = ({
  parkingId,
  motoristId,
  freeflagId,
  widget,
}: MotoristContractListProps) => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const navigate = useNavigate();

  /*const { passThrough: motoristContracts } = useCache({
    fn: async (parkingId, motoristId) => {
      const contracts: Contract[] = await requestGQL({
        operationName: 'contracts',
        gql: contractsGql,
        params: {
          parkingId,
          motoristId,
          freeflagId,
        },
        render: (data) => data.list,
      });
      return contracts;
    },
    maxAgeMs: 1 * Minute,
    cacheInvalidationSubscriptions: [
      WsEvents.CONTRACT_UPDATED,
      WsEvents.CONTRACT_CREATED,
    ],
  });*/

  const fetchContracts = async (
    parkingId: string,
    motoristId: string,
    freeflagId: string,
  ) => {
    const contracts: Contract[] = await requestGQL({
      operationName: 'contracts',
      gql: contractsGql,
      params: {
        parkingId,
        motoristId,
        freeflagId,
        category: [],
        status: [],
      },
      render: (data) => data.list,
    });
    return contracts;
  };

  useEffect(() => {
    const contracts = fetchContracts(
      parkingId!!,
      motoristId!!,
      freeflagId!!,
    ) as Promise<Contract[]>;
    contracts.then((contracts) => setContracts(contracts));
  }, [motoristId, parkingId, freeflagId]);

  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
    >
      <ListFlatInfo<any>
        handleEvent={{
          click: (id) => {
            const selectedContract = contracts?.find(({ _id }) => _id === id);
            navigate(`/${selectedContract?.parkingId}/contracts/${id}`);
          },
        }}
        data={{
          paging: { count: contracts.length, limit: 20, current: 1 },
          list: contracts.map(
            ({
              _id,
              motorist,
              ospContractId,
              isInside,
              isEnabled,
              stopDate,
            }) => ({
              _id,
              items: {
                component: (
                  <ContractMotoristLine
                    data={{
                      id: _id,
                      firstName: motorist?.firstName || null,
                      lastName: motorist?.lastName || null,
                      status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                      isStopped:
                        stopDate !== undefined &&
                        DateTime.fromISO(stopDate)
                          .diffNow()
                          .as('milliseconds') < 0,
                      contractId: ospContractId,
                      carCounter: [Number(isInside), 1],
                      motoristId: motorist?._id,
                    }}
                    config={{ context: 'flat' }}
                  />
                ),
              },
            }),
          ),
        }}
        config={{
          pagination: 'scroll',
          columns: [{ key: 'component', size: '1fr' }],
        }}
      />
    </Widget>
  );
};
