import { gql } from '@apollo/client'

export const parkingsGql = gql`
  query parkings($page: Int, $limit: Int, $order: String) {
    parkings(page: $page, limit: $limit, order: $order) {
      list {
        _id
        name
        code
        translation
      }
      paging {
        current
        count
        limit
      }
    }
  }
`
