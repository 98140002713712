import { useTranslation } from '@gimlite/watermelon';
import {
  Timeline,
  TimelineType,
} from '../../components/timeline/timeline.component';

export declare namespace EntryAuthorizedSessionTimelineType {
  type Props = {
    data: {
      date: TimelineType.Data.Date;
      extra: TimelineType.Data.Extra;
      device: TimelineType.Data.Device;
    };
  };
}

export const EntryAuthorizedSessionTimeline = ({
  data: { date, extra, device },
}: EntryAuthorizedSessionTimelineType.Props) => {
  const { t } = useTranslation();
  return (
    <Timeline
      config={{
        color: 'success',
      }}
      data={{
        icon: 'faCarSolid',
        date,
        device,
        title: t('global-state-entry-authorized'),
        extra,
      }}
    />
  );
};
