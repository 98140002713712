import { gql } from '@apollo/client';

export const globalSearchMergeGQL = gql`
  query globalSearchMerge($content: String!) {
    globalSearchMerge(content: $content) {
      motoristsMerge {
        _id
        email
        firstName
        lastName
        address1
        address2
        zipcode
        city
        country
        createdAt
        lang
        phone
        zipcode
        status
        qrcode {
          type
          token
          createdAt
          disabledAt
        }
        entityOrigin
        credentials {
          type
          provider
          value
          description
        }
      }
    }
  }
`;
