import { Empty, Widget, useReadOf, useTranslation } from '@gimlite/watermelon';
import { useEffect, useMemo } from 'react';
import { contractGql } from '../../common/gql/contract.gql';
import { WsEvents } from '../../common/mapper/ws-event.mapper';
import { Contract } from '../../common/types/entities/contract';
import { MasterPoolContractDetails } from './master-pool-contract.details';
import { MotoristContractDetails } from './motorist-contract.details';
import { TenantPoolContractDetails } from './tenant-pool-contract.details';

export type ContractDetailsProps = {
  parkingId: string;
  contractId?: string;
};

export const ContractDetails = ({
  parkingId,
  contractId,
}: ContractDetailsProps) => {
  const { t, lang } = useTranslation();
  const { details, setId } = useReadOf<Contract>({
    gql: contractGql,
    wsSubscriptions: [WsEvents.CONTRACT_UPDATED, WsEvents.MOTORIST_UPDATED],
    cache: false,
    event2Id: (event) => {
      return (
        event?.contracts?.[0]?._id ||
        (event?.motorist &&
        !event.contracts &&
        !event.contract &&
        event.motorist.contractIds.length > 0 &&
        event.motorist.contractIds.includes(contractId)
          ? contractId
          : undefined) ||
        event?.contract?._id ||
        ''
      );
    },
  });

  const emptyFormatted = useMemo(() => {
    return (
      <Widget config={{ title: t('detailsOfContract'), backtitle: true }}>
        <Empty
          config={{
            mode: { name: 'select', text: `${t('selectContract')} ...` },
          }}
        ></Empty>
      </Widget>
    );
  }, [lang]);

  useEffect(() => {
    if (contractId) {
      setId(contractId);
    }
  }, [contractId]);

  if (!contractId) return emptyFormatted;

  switch (details?.category) {
    case 'MOTORIST_POOL':
    case 'PERIOD_SUBSCRIPTION':
      return (
        <MotoristContractDetails
          widget={{ title: t('detailsOfMotoristContract') }}
          contract={details}
          parkingId={parkingId}
        />
      );

    case 'TENANT_POOL':
      return (
        <TenantPoolContractDetails
          widget={{ title: t('detailsOfSubPoolContract') }}
          contract={details}
        />
      );

    case 'OPERATOR_POOL':
      return (
        <MasterPoolContractDetails
          widget={{ title: t('detailsOfMasterPoolContract') }}
          contract={details}
        />
      );

    default:
      return emptyFormatted;
  }
};
