import { gql } from '@apollo/client';
import { Product } from '../types/entities/product';
import { Credential } from '../types/entities/credential';

export type ProductResponse = {
  _id: string;
  name: string;
  category: Product['category'];
  parameters: {
    durationValue: number | null;
    durationUnit: string | null;
    cents: number | null;
    currency: string | null;
    contractTag: string | null;
    maxNoPayDaysCount: number | null;
  } | null;
  rules: {
    cycleControl: {
      pastDaysCount: number;
      maxOpenedSessions: number;
    };
    credentialsTypes: {
      types: Array<Pick<Credential, 'type' | 'provider'>>;
    } | null;
  } | null;
  externalPayload: {
    ArticleCode: string | null;
  } | null;
  createdAt: string;
};

export const productGQL = gql`
  query product($productId: String!) {
    product(productId: $productId) {
      _id
      name
      category
      parameters {
        durationValue
        durationUnit
        cents
        currency
        contractTag
      }
      rules {
        cycleControl {
          pastDaysCount
          maxOpenedSessions
        }
        credentialsTypes {
          types {
            type
            provider
          }
        }
      }
      externalPayload {
        ArticleCode
      }
      createdAt
    }
  }
`;
