import { gql } from '@apollo/client';
import { APBModeGlobalName } from '../mapper/abp.mapper';

export type CreateSubPoolResponse = {
  _id: string;
  ospContractId: string;
  name1: string;
  name2: string | null;
  address1: string | null;
  address2: string | null;
  zipcode: string | null;
  city: string | null;
  country: string | null;
  isEnabled: boolean;
  createdAt: string;
};

export type CreateSubPool = {
  input: {
    contractId: string;
    newContractSpacesAllocated: number;
    contractName: string;
    startDate: string | null;
    stopDate: string | null;
    isEnabled: boolean | null;
    description: string | null;
    reference: string | null;
    name2: string | null;
    antiPassBackMode: APBModeGlobalName | null;
  };
};

export const createSubPoolGQL = gql`
  mutation createSubPool($input: CreateSubPoolDto!) {
    createSubPool(input: $input) {
      _id
      ospContractId
      name1
      isEnabled
      name2
      address1
      address2
      zipcode
      city
      country
      createdAt
    }
  }
`;
