import { dispatch } from '@gimlite/router';
import {
  Wizard,
  WizardItemType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { GlobalSearchContractEntityResponse } from '../../common/gql/globalSearch.gql';
import { MasterContractStep } from '../../common/steps/masterContract/masterContract.step';
import { ContractCreateFormType } from '../../form/contract-create/contract-create.form';
import {
  CreateNewSubPoolContractServiceContextType,
  createNewSubPoolContractService,
} from './createNewSubPoolContract.machine';
import { ConfirmationStep } from './steps/confirmation/confirmation.step';
import { ContractStep } from './steps/contract/contract.step';
import { InformationsStep } from './steps/informations/informations.step';
import { SummaryStep } from './steps/summary/summary.step';
import { ConfigProps } from '../../config';

export type CreateNewSubPoolContractContext = {
  parkingId: string | null;
  masterPoolContractId: string | null;
  parentContract: ContractCreateFormType.Data.ContractPool | null;
  contract: ContractCreateFormType.Data.ContractPool;
  informations: {
    name1: string | null;
    name2: string | null;
  };
  masterPoolExistedSave: GlobalSearchContractEntityResponse[];
  machine: any;
  mode: ConfigProps['mode'];
};

export const CreateNewSubPoolContractWizard = () => {
  const { t, lang } = useTranslation();

  const { parkingId, mode } = useSelector(
    createNewSubPoolContractService,
    ({ context }: { context: CreateNewSubPoolContractServiceContextType }) =>
      context,
  );

  return (
    <Wizard<CreateNewSubPoolContractContext>
      config={{
        title: t(`createNewSubPoolContract`),
      }}
      data={{
        defaultContext: {
          parkingId,
          masterPoolContractId: null,
          parentContract: null,
          contract: {
            startDate: new Date().toISOString(),
            endDate: null,
            APBMode: 'HARD',
            APBNext: false,
            reference: null,
            description: null,
            state: true,
            capacity: null,
          },
          informations: {
            name1: null,
            name2: null,
          },
          masterPoolExistedSave: [],
          machine: createNewSubPoolContractService,
          mode,
        },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          dispatch('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item
        config={{
          title: t('masterContract'),
          component: (
            params: WizardItemType.Config.Component<CreateNewSubPoolContractContext>,
          ) =>
            MasterContractStep({
              ...params,
              withRuleTenantCanCreateSubContract: true,
              ignoreDefaultSub: true,
            }),
        }}
      />
      <Wizard.Item config={{ title: t('contract'), component: ContractStep }} />
      <Wizard.Item
        config={{ title: t('informations'), component: InformationsStep }}
      />
      <Wizard.Item
        config={{ title: t('confirmation'), component: ConfirmationStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
};
