import {
  Row,
  Write,
  Zone,
  toCapitalizeCase,
  useTranslation,
} from '@gimlite/watermelon';
import { Blurb } from '@gimlite/watermelon/components/blurb/blurb.component';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { Event as SessionEventType } from '../../common/types/entities/event';
import { SessionAction } from '../action/session.action';
import { SessionInfos } from '../infos/session/session.infos';

class Static {
  static prioritySelectEntry: Array<
    Extract<SessionEventType['state'], 'CREATED' | 'ENTERED' | 'ENTRY_DENIED'>
  > = ['ENTERED', 'ENTRY_DENIED', 'CREATED'];

  static prioritySelectExit: Array<
    Extract<
      SessionEventType['state'],
      'EXIT_AUTHORIZE_PENDING' | 'EXIT_AUTHORIZED' | 'EXIT_DENIED' | 'EXITED'
    >
  > = ['EXITED', 'EXIT_DENIED', 'EXIT_AUTHORIZED', 'EXIT_AUTHORIZE_PENDING'];
}

export declare namespace SessionLineType {
  type Props = {
    data: {
      firstName: string | null;
      lastName: string | null;
      entryDate: string;
      exitDate?: string;
      sessionId: string;
      productName: string | null;
      motoristId: string;
      contractId: string;
      parkingId: string;
      isInconsistent: boolean;
      events: SessionEventType[];
    };
  };
}

export const SessionLine = ({ data }: SessionLineType.Props) => {
  const { t } = useTranslation();

  const {
    firstName,
    lastName,
    productName,
    entryDate,
    exitDate,
    sessionId,
    motoristId,
    contractId,
    parkingId,
    isInconsistent,
    events,
  } = data;

  const eventsFormatted = useMemo(
    () =>
      events.reduce(
        (acc: Record<string, SessionEventType | null>, { state, ...rest }) =>
          state ? { ...acc, [state]: { state, ...rest } } : acc,
        {},
      ),
    [data],
  );

  const selectedEntryState = Static.prioritySelectEntry.find(
    (state) => eventsFormatted[state],
  );

  const selectedEntry = selectedEntryState
    ? eventsFormatted[selectedEntryState]
    : eventsFormatted[
        Static.prioritySelectEntry[Static.prioritySelectEntry.length - 1]
      ];

  const selectedExitState = Static.prioritySelectExit.find(
    (state) => eventsFormatted[state],
  );

  const selectedExit = selectedExitState
    ? eventsFormatted[selectedExitState]
    : eventsFormatted[
        Static.prioritySelectExit[Static.prioritySelectExit.length - 1]
      ];

  const formattedDate = useCallback(
    (date?: string) => {
      return DateTime.fromMillis(date ? DateTime.fromISO(date).millisecond : 0)
        .millisecond === 0
        ? '-'
        : DateTime.fromISO(date || '').toFormat('dd/MM/yyyy HH:mm:ss');
    },
    [selectedEntry, selectedExit],
  );

  return (
    <Zone
      config={{
        gap: {
          x: 1,
          y: 0,
        },
        zones: [['identity', 'entryDate', 'exitDate', 'showSession', 'action']],
        rows: ['1fr'],
        columns: ['1fr', '1fr', '1fr', 'min-content', 'min-content'],
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Zone.Area config={{ area: 'identity' }}>
        <Blurb
          data={{
            title:
              !firstName && lastName
                ? toCapitalizeCase(lastName)
                : firstName && !lastName
                ? toCapitalizeCase(firstName)
                : firstName && lastName
                ? `${toCapitalizeCase(lastName)} ${toCapitalizeCase(firstName)}`
                : t('unknown'),
            description: productName ?? undefined,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'entryDate' }}>
        <Row config={{ horizontal: 'start', vertical: 'center' }}>
          <Write
            data={{
              item: formattedDate(entryDate),
            }}
            config={{
              mode: 'key-small-bold',
              color:
                isInconsistent ||
                selectedEntry?.state.includes('DENIED') ||
                events.find(({ state }) => state.includes('DENIED'))
                  ? 'error'
                  : undefined,
            }}
          ></Write>
        </Row>
      </Zone.Area>

      <Zone.Area config={{ area: 'exitDate' }}>
        <Row config={{ horizontal: 'start', vertical: 'center' }}>
          {exitDate && (
            <Write
              data={{
                item: formattedDate(exitDate),
              }}
              config={{
                mode: 'key-small-bold',
                color:
                  isInconsistent ||
                  selectedExit?.state.includes('DENIED') ||
                  events.find(({ state }) => state.includes('DENIED'))
                    ? 'error'
                    : undefined,
              }}
            ></Write>
          )}
        </Row>
      </Zone.Area>

      <Zone.Area config={{ area: 'showSession' }}>
        <Row config={{ horizontal: 'center', vertical: 'center' }}>
          <SessionInfos data={{ sessionId: sessionId, events: events }} />
        </Row>
      </Zone.Area>

      <Zone.Area config={{ area: 'action' }}>
        <Row config={{ horizontal: 'center', vertical: 'center' }}>
          <SessionAction
            config={{ size: 'list' }}
            data={{ sessionId, motoristId, contractId, parkingId }}
          />
        </Row>
      </Zone.Area>
    </Zone>
  );
};
