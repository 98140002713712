import { BadgeType, Zone, useTranslation } from '@gimlite/watermelon';
import { Blurb } from '@gimlite/watermelon/components/blurb/blurb.component';
import { DateTime } from 'luxon';
import { TechnicalEvent } from '../../common/types/entities/technical-events';
import { StringFn, parseTokens } from '../../common/utils/tokens-2-payload';
import { IncidentAction } from '../action/incident.action';

class Static {
  static tokenMap = {
    DATE: (payload: any, t: StringFn) =>
      DateTime.fromISO(payload.date).toFormat('dd.LL.yy HH:mm'),
    UUU: (payload: any, t: StringFn) =>
      t(`global-providers-${payload.credential?.provider?.toLowerCase()}`),
    RFID: (payload: any, t: StringFn) =>
      payload.credential?.value.toUpperCase(),
    EID: (payload: any, t: StringFn) => payload.credential?.value.toUpperCase(),
    PL: (payload: any, t: StringFn) => payload.credential?.value.toUpperCase(),
    PN: (payload: any, t: StringFn) => payload.spacesAllocated,
    NNE: (payload: any, t: StringFn) => payload.openedSessions?.length,
    NDAY: (payload: any, t: StringFn) =>
      payload.rules?.cycleControl?.pastDaysCount,
    NC: (payload: any, t: StringFn) => payload.ospContractId,
  };

  static statusBadgeColorMap: Record<
    IncidentLineType.Data.Status,
    BadgeType.Config.Color
  > = {
    information: 'disabled',
    warning: 'warn',
    alarm: 'error',
  };
}

export declare namespace IncidentLineType {
  type Props = {
    data: {
      code: string;
      payload: Object;
      device?: string;
      date: string;
      category: IncidentLineType.Data.Category;
      criticity: IncidentLineType.Data.Status;
      isArchived: boolean;
      technicalEventId: string;
    };
  };

  namespace Data {
    type Category = TechnicalEvent['category'];
    type Status = TechnicalEvent['criticity'];
  }
}

export const IncidentLine = ({
  data: {
    code,
    payload,
    device,
    category,
    date,
    criticity,
    isArchived,
    technicalEventId,
  },
}: IncidentLineType.Props) => {
  const { t } = useTranslation();

  const relativeDate = DateTime.fromISO(date).toFormat('dd/MM/yyyy HH:mm:ss');

  const badges = [
    {
      color: Static.statusBadgeColorMap[criticity],
      text: t(criticity).toUpperCase(),
    },
  ];

  if (isArchived) badges.push({ color: 'disabled', text: t('archived') });

  return (
    <Zone
      config={{
        gap: {
          x: 1,
          y: 0,
        },
        zones: [['identity', 'type', 'action']],
        rows: ['1fr'],
        columns: ['1fr', 'min-content', 'min-content'],
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Zone.Area config={{ area: 'identity' }}>
        <Blurb
          data={{
            title:
              t(`incident-title-${code}`) +
              `${device ? ` : ${t('access-point')} ${device}` : ''}`,
            description: parseTokens(
              t(`incident-${code}`),
              {
                date,
                ...payload,
              },
              Static.tokenMap,
              t,
            ),
            badge: badges,
          }}
        ></Blurb>
      </Zone.Area>

      <Zone.Area config={{ area: 'type' }}>
        <Blurb
          data={{
            title: t(category).toUpperCase(),
            description: `${relativeDate}`,
          }}
          config={{ vertical: 'end' }}
        ></Blurb>
      </Zone.Area>

      <Zone.Area config={{ area: 'action' }}>
        <IncidentAction
          config={{ size: 'list' }}
          data={{ technicalEventId, payload, isArchived }}
        />
      </Zone.Area>
    </Zone>
  );
};
