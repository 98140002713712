export const WsEvents = {
  CONTRACT_UPDATED: 'contracts:updated',
  CONTRACT_CREATED: 'contracts:created',
  MOTORIST_UPDATED: 'motorists:updated',
  MOTORIST_CREATED: 'motorists:created',
  LOCAL_MOTORIST_UPDATED: 'localmotorist:updated',
  LOCAL_MOTORIST_CREATED: 'localmotorist:created',
  TECHNICAL_EVENT_CREATED: 'technical-events:created',
  TECHNICAL_EVENT_UPDATED: 'technical-events:updated',
  SESSION_CREATED: 'sessions:created',
  SESSION_UPDATED: 'sessions:updated',
  BREAKDOWN_UPDATED: 'breakdowns:updated',
  BREAKDOWN_CREATED: 'breakdowns:created',
  POOLS_CREATED: 'pools:created',
};
