import { DateTime } from 'luxon';

export const searchProjection = (context, { list, paging }) => {
  const listFormatted = list.map((element) => ({
    ...element,
    _id: element._id,
    title: element.description
      ? element.description
      : ['EXIT', 'ENTRY'].includes(element.type)
      ? 'Barrier failure'
      : '(No description)',
    description:
      element.type +
      ': ' +
      DateTime.fromISO(element.startDate).toFormat('yyyy-MM-dd') +
      ' - ' +
      DateTime.fromISO(element.endDate).toFormat('yyyy-MM-dd'),
    contractsNeutralizedFormatted: {
      list: element.contractsNeutralized?.list.map((contract) => ({
        ...contract,
        _id: contract._id,
        title: contract.ospContractId,
        description: contract.category,
      })),
      paging: element.contractsNeutralized?.paging,
    },
  }));

  return {
    list: listFormatted,
    paging,
  };
};
