import { useNavigate } from '@gimlite/router';
import { requestGQL, useTranslation } from '@gimlite/watermelon';
import {
  ActionInline,
  ActionInlineType,
} from '@gimlite/osp/components/action-inline/action-inline.component';
import { closeSessionGql } from '../../common/gql/session-close.gql';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../hook/useRootStore.hook';

export declare namespace SessionActionType {
  type Props = {
    config: {
      size: ActionInlineType.Config.Size;
    };
    data: {
      sessionId: string;
      motoristId: string;
      contractId: string;
      parkingId: string;
    };
  };
}

export const SessionAction = observer(
  ({
    config: { size },
    data: { sessionId, motoristId, contractId, parkingId },
  }: SessionActionType.Props) => {
    const { GlobalStore } = useRootStore();

    const { t } = useTranslation();
    const actions: ActionInlineType.Data[] = [
      {
        key: 'showContractOfMotorist',
        text: t('showMotoristContract'),
        color: 'label',
        event: () => navigate(`/${parkingId}/contracts/${contractId}`),
        show: !!contractId,
      },
    ];

    if (GlobalStore.ENV === 'LOCAL' || GlobalStore.ENV === 'develop')
      actions.push({
        key: 'stopSession',
        text: t('stopSession'),
        color: 'error',
        isCritical: true,
        event: () =>
          requestGQL({
            params: { sessionId },
            gql: closeSessionGql,
          }),
      });

    const navigate = useNavigate();

    return <ActionInline config={{ size }} data={actions} />;
  },
);
