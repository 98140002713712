import { ApolloError } from '@apollo/client';
import { ContractMotoristLine } from '@gimlite/osp/composition/line/contract-motorist.line';
import { ReportTechnicalWizardInfos } from '@gimlite/osp/composition/wizard-infos/report-technical.wizard-infos';
import { requestGQL, useCascade } from '@gimlite/watermelon';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import {
  CreateBreakdown,
  CreateBreakdownResponse,
  createBreakdownGQL,
} from '../../../../common/gql/createBreakdown.gql';
import {
  SummaryForm,
  SummaryFormType,
} from '../../../../form/summary/summary.form';
import { ReportTechnicalDeviceFailureContext } from '../../reportTechnicalDeviceFailure.wizard';

export const SummaryStep = ({
  context: { issue, parkingId, motoristsConcerned },
  validate,
  submit,
}: WizardItemType.Config.Component<ReportTechnicalDeviceFailureContext>) => {
  const { t, lang } = useTranslation();

  const [created, setCreated] = useState<SummaryFormType.Data.Line[]>();

  const { isFinish } = useCascade<CreateBreakdownResponse>([
    async () => {
      try {
        const response = (await requestGQL({
          params: {
            input: {
              parkingId,
              category: issue.category,
              endDate: issue.endDate,
              description: issue.description,
              type: issue.accessPointType,
            },
          } as CreateBreakdown,
          gql: createBreakdownGQL,
        })) as CreateBreakdownResponse;

        setCreated(() =>
          motoristsConcerned.map(
            ({ _id, motorist, ospContractId, isEnabled }) => ({
              _id,
              items: {
                component: (
                  <ContractMotoristLine
                    data={{
                      firstName: motorist?.firstName || null,
                      lastName: motorist?.lastName || null,
                      contractId: ospContractId,
                      status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                    }}
                    config={{ action: false }}
                  />
                ),
                state: { type: 'success' },
              },
            }),
          ),
        );

        return { title: 'ReportTechnicalDeviceFailure', response };
      } catch (error: unknown) {
        setCreated(() =>
          motoristsConcerned.map(
            ({ _id, motorist, ospContractId, isEnabled }) => ({
              _id,
              items: {
                component: (
                  <ContractMotoristLine
                    data={{
                      firstName: motorist?.firstName || null,
                      lastName: motorist?.lastName || null,
                      contractId: ospContractId,
                      status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                    }}
                    config={{ action: false }}
                  />
                ),
                state: {
                  type: 'error',
                  message:
                    error instanceof ApolloError
                      ? `${error.message}`
                      : `${error}`,
                },
              },
            }),
          ),
        );

        return {
          title: 'ReportTechnicalDeviceFailure',
          response: new Error(
            error instanceof ApolloError ? `${error.message}` : `${error}`,
          ),
        };
      }
    },
  ]);

  useEffect(() => {
    validate(isFinish);
  }, [isFinish]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ReportTechnicalWizardInfos
          data={{
            startDate: issue.startDate,
            endDate: issue.endDate,
            accessPointType: issue.accessPointType,
            category: issue.category,
            description: issue.description,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <SummaryForm
          data={{
            list: created,
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
