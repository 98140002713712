import { gql } from '@apollo/client'

export const motoristGql = gql`
  query motorist($motoristId: String!) {
    motorist(motoristId: $motoristId) {
      _id
      username
      firstName
      lastName
      address1
      address2
      zipcode
      city
      state
      country
      phone
      inside
      lang
      credentials {
        type
        provider
        value
        description
      }
      qrcode {
        type
        token
        createdAt
        disabledAt
      }
    }
  }
`
