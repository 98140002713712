import { EventObject, assign, createMachine, interpret } from 'xstate';
import { AddSeveralMotoristsToAnExistingPoolContractWizardContext } from './addSeveralMotoristsToAnExistingPoolContract.wizard';

export type AddSeveralMotoristsToAnExistingPoolContractServiceContextType = {
  parkingId: AddSeveralMotoristsToAnExistingPoolContractWizardContext['parkingId'];
  skipSubContract: boolean;
  mode: AddSeveralMotoristsToAnExistingPoolContractWizardContext['mode'];
  error: string | null;
};

const addSeveralMotoristsToAnExistingPoolContractServiceContext: AddSeveralMotoristsToAnExistingPoolContractServiceContextType =
  {
    parkingId: null,
    skipSubContract: false,
    mode: 'operator',
    error: null,
  };

const machine = createMachine({
  id: 'addSeveralMotoristsToAnExistingPoolContract',
  initial: 'off',
  context: addSeveralMotoristsToAnExistingPoolContractServiceContext,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
          actions: assign({
            parkingId: (
              _,
              { parkingId }: EventObject & { parkingId: string },
            ) => parkingId,
            mode: (
              _,
              {
                mode,
              }: EventObject & {
                mode: AddSeveralMotoristsToAnExistingPoolContractServiceContextType['mode'];
              },
            ) =>
              mode ||
              addSeveralMotoristsToAnExistingPoolContractServiceContext.mode,
          }),
        },
      } as any,
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
        SKIP_SUB_CONTRACT: {
          actions: assign({
            skipSubContract: (_, { payload }: { payload: boolean }) => {
              return payload;
            },
          }),
        } as any,
      },
    },
    failure: {
      on: {
        RETRY: {
          actions: assign({ error: null }),
          target: 'idle',
        },
      },
    },
  },
});

export const addSeveralMotoristsToAnExistingPoolContractService =
  interpret(machine).start();
