import { ContractMotoristLine } from '@gimlite/osp/composition/line/contract-motorist.line';
import { ReportTechnicalWizardInfos } from '@gimlite/osp/composition/wizard-infos/report-technical.wizard-infos';
import { ListFlatMultipleCheck, useSearchOf } from '@gimlite/watermelon';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { contractsGql } from '../../../../common/gql/contracts.gql';
import { MotoristContract } from '../../../../common/types/entities/motorist-contract';
import { ReportTechnicalDeviceFailureContext } from '../../reportTechnicalDeviceFailure.wizard';

const pageLimit = 10000;

export const MotoristsStep = ({
  context: { issue, parkingId, motoristsConcerned },
  validate,
  submit,
  loading,
}: WizardItemType.Config.Component<ReportTechnicalDeviceFailureContext>) => {
  const { t, lang } = useTranslation();

  const { list, paging, setSearch } = useSearchOf<
    MotoristContract,
    {
      contractMotoristPage: number;
      contractMotoristLimit: number;
    }
  >({
    searchParams: {
      isActive: true,
      categories: ['PERIOD_SUBSCRIPTION', 'MOTORIST_POOL'],
    },
    gql: contractsGql,
    wsSubscriptions: [],
  });

  const listFormatted = useMemo(() => {
    return (
      list &&
      list.map(({ _id, motorist, ospContractId, stopDate, isEnabled }) => ({
        _id,
        items: {
          component: (
            <ContractMotoristLine
              data={{
                firstName: motorist?.firstName || null,
                lastName: motorist?.lastName || null,
                contractId: ospContractId,
                status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                isStopped:
                  stopDate !== undefined &&
                  DateTime.fromISO(stopDate).diffNow().as('milliseconds') < 0,
              }}
              config={{ action: false }}
            />
          ),
        },
      }))
    );
  }, [list]);

  const checkList = useMemo(() => {
    return list ? list.map(({ _id }) => _id) : undefined;
  }, [list]);

  useEffect(() => {
    submit({
      motoristsConcerned: list,
    });
  }, [list]);

  useEffect(() => {
    setSearch({
      paging: {
        contractMotoristPage: 1,
        contractMotoristLimit: pageLimit,
      },
      params: {
        parkingId,
      },
    });
  }, []);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ReportTechnicalWizardInfos
          data={{
            startDate: issue.startDate,
            endDate: issue.endDate,
            accessPointType: issue.accessPointType,
            category: issue.category,
            description: issue.description,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <Widget
          config={{
            title: t('allContractsConcerned'),
            shadow: false,
          }}
        >
          <Zone
            config={{
              gap: {
                y: 1,
                x: 0,
              },
              zones: [['description'], ['details']],
              rows: ['min-content', '1fr'],
              columns: ['1fr'],
            }}
          >
            <Zone.Area config={{ area: 'description' }}>
              <Description
                data={[
                  {
                    key: 'total',
                    label: t('total'),
                    value: checkList ? checkList.length : 0,
                  },
                ]}
                config={{ way: 'horizontal' }}
              ></Description>
            </Zone.Area>

            <Zone.Area config={{ area: 'details' }}>
              <ListFlatMultipleCheck<any>
                handleEvent={{
                  paging: (data) => {
                    setSearch({
                      paging: {
                        contractMotoristPage: data.page,
                        contractMotoristLimit: data.limit,
                      },
                      params: {
                        parkingId,
                      },
                    });
                  },
                }}
                data={{
                  list: listFormatted,
                  checkList: checkList || [],
                  paging,
                }}
                config={{
                  disabled: true,
                  pagination: 'none',
                  columns: [{ key: 'component', size: '1fr' }],
                  border: true,
                }}
              />
            </Zone.Area>
          </Zone>
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
