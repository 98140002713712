import { gql } from '@apollo/client';

export const localMotoristsGql = gql`
  query localMotorists(
    $motoristPage: Int!
    $motoristLimit: Int!
    $order: String
    $localMotoristId: String
    $left: String = "active"
    $isLinked: String
  ) {
    localMotorists(
      page: $motoristPage
      limit: $motoristLimit
      order: $order
      localMotoristId: $localMotoristId
      left: $left
      isLinked: $isLinked
    ) {
      list {
        _id
        operatorId
        externalId
        linkedmotorist {
          shortUid
          contracts {
            list {
              stopDate
            }
          }
          credentials {
            type
            value
            description
          }
        }
        plates {
          type
          value
          description
        }
        uids {
          type
          value
          description
        }
        cards {
          type
          value
          description
        }
        email
        firstName
        lastName
        inside
        address1
        address2
        zipcode
        activeAndTotalCount {
          active
          total
        }
        city
        country
        phone
        lang
        status
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
