import { gql } from '@apollo/client'

export const parkingGql = gql`
  query parking($parkingId: String!) {
    parking(parkingId: $parkingId) {
      accessPoints {
        _id
        shortCode
      }
    }
  }
`
