import { dispatch } from '@gimlite/router';
import {
  Widget,
  Zone,
  requestGQL,
  toCapitalizeCase,
  useTranslation,
} from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { DateTime, Duration } from 'luxon';
import { useEffect, useState } from 'react';
import { productsGql } from '../../common/gql/products.gql';
import { APBModeGlobalName } from '../../common/mapper/abp.mapper';
import {
  CredentialType,
  convertCredentialsBackToFrontName,
} from '../../common/mapper/credential.mapper';
import { APBModeDetectWithCycleControl } from '../../common/mapper/product.mapper';
import {
  ProductCategory,
  Product as ProductEntity,
  ProductParams,
} from '../../common/types/entities/product';
import {
  Product,
  ProductGroup,
} from '../../components/product/product.component';
import './product.form.scss';

export declare namespace ProductFormType {
  type Props = {
    handleEvent: {
      select: ({
        id,
        period,
        APBMode,
      }: {
        id: string;
        period: number | null;
        APBMode: APBModeGlobalName;
        credentials: CredentialType.GlobalName[] | null;
        duration: Pick<ProductParams, 'durationUnit' | 'durationValue'> | null;
      }) => void;
    };
    data: {
      category: ProductCategory;
      productId: string | null;
      parkingId: string;
    };
  };

  namespace Data {
    type ProductId = string | null;
  }
}

export const ProductForm = ({
  data: { productId, category, parkingId },
  handleEvent: { select },
}: ProductFormType.Props) => {
  const { t, lang } = useTranslation();
  const [list, setList] = useState<ProductEntity[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = (await requestGQL({
          params: {
            parkingId,
            category,
          },
          gql: productsGql,
        })) as {
          list: ProductEntity[];
        };

        setList(response.list);
      } catch (error) {
        dispatch('NOTIF', {
          mode: 'error',
          content: 'ERROR',
        });
        return [];
      }
    })();
  }, []);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['contain']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'contain' }}>
        <Widget
          config={{
            shadow: false,
          }}
        >
          <ProductGroup
            data={{ selected: productId }}
            handleEvent={{
              click: (id) => {
                const { parameters, rules } = list.find(
                  (product) => product._id === id,
                )!;

                const APBMode = APBModeDetectWithCycleControl(
                  rules?.cycleControl || null,
                );

                const credentialsCompatibility = rules?.credentialsTypes?.types
                  ? rules.credentialsTypes.types.map((item) =>
                      convertCredentialsBackToFrontName(item),
                    )
                  : [];

                if (
                  parameters &&
                  parameters.durationValue &&
                  parameters.durationUnit
                ) {
                  switch (parameters.durationUnit) {
                    case 'HOUR':
                      select({
                        id,
                        duration: {
                          durationValue: parameters.durationValue,
                          durationUnit: parameters.durationUnit,
                        },
                        period: Duration.fromMillis(0)
                          .plus({
                            hour: parameters.durationValue,
                          })
                          .minus({
                            day: 1,
                          })
                          .toMillis(),
                        APBMode,
                        credentials: credentialsCompatibility,
                      });
                      break;

                    case 'MONTH':
                      select({
                        id,
                        duration: {
                          durationValue: parameters.durationValue,
                          durationUnit: parameters.durationUnit,
                        },
                        period: Duration.fromMillis(0)
                          .plus({
                            month: parameters.durationValue,
                          })
                          .minus({
                            day: 1,
                          })
                          .toMillis(),
                        APBMode,
                        credentials: credentialsCompatibility,
                      });
                      break;

                    case 'YEAR':
                      select({
                        id,
                        duration: {
                          durationValue: parameters.durationValue,
                          durationUnit: parameters.durationUnit,
                        },
                        period: Duration.fromMillis(0)
                          .plus({
                            years: parameters.durationValue,
                          })
                          .minus({
                            day: 1,
                          })
                          .toMillis(),
                        APBMode,
                        credentials: credentialsCompatibility,
                      });
                      break;

                    default:
                      select({
                        id,
                        duration: {
                          durationValue: parameters.durationValue,
                          durationUnit: parameters.durationUnit,
                        },
                        period: null,
                        APBMode,
                        credentials: credentialsCompatibility,
                      });
                      break;
                  }
                } else {
                  select({
                    id,
                    duration: null,
                    period: null,
                    APBMode,
                    credentials: credentialsCompatibility,
                  });
                }
              },
            }}
          >
            <Wizard.CardGroup
              config={{
                title: t('selectProduct'),
                subtitle: t('SELECT_PRODUCT_EXPLAIN'),
              }}
            >
              {list &&
                list.map(
                  (
                    {
                      _id,
                      parameters,
                      rules,
                      externalPayload,
                      createdAt,
                      category,
                    },
                    index,
                  ) => {
                    const APBMode = APBModeDetectWithCycleControl(
                      rules?.cycleControl || null,
                    );

                    return (
                      <div key={index}>
                        <Product
                          data={{
                            _id,
                            category,
                            title: t(`product-${_id}-name`),
                            description: t(`product-${_id}-description`),
                            price:
                              parameters &&
                              parameters?.cents &&
                              typeof parameters?.cents === 'number'
                                ? parameters.cents / 100
                                : null,
                            currency: parameters ? parameters.currency : null,
                            period: {
                              time: parameters?.durationUnit
                                ? t(parameters.durationUnit.toLowerCase())
                                : null,
                              count: parameters
                                ? parameters?.durationValue
                                : null,
                            },
                            witEngament: false,
                            advantage: [
                              {
                                label: t(
                                  `antiPassback${toCapitalizeCase(APBMode)}`,
                                ),
                                isPresent: !(APBMode === 'NONE'),
                              },
                            ],
                            credential: rules?.credentialsTypes?.types
                              ? rules.credentialsTypes.types.map((item) =>
                                  convertCredentialsBackToFrontName(item),
                                )
                              : null,
                            info: {
                              articleCode: externalPayload?.ArticleCode || null,
                              createdAt:
                                DateTime.fromISO(createdAt).toFormat(
                                  'dd/MM/yy HH:mm:ss',
                                ),
                            },
                          }}
                        />
                      </div>
                    );
                  },
                )}
            </Wizard.CardGroup>
          </ProductGroup>
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
