import {
  BadgeType,
  FormType,
  Segmented,
  SegmentedType,
  Widget,
  Zone,
  requestGQL,
  useMyUrl,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { contractSessionsGql } from '../../common/gql/contract-sessions.gql';
import { updatePoolContractGql } from '../../common/gql/update-pool-contract.gql';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { Contract } from '../../common/types/entities/contract';
import {
  ContractBadgesType,
  useContractBadges,
} from '../../common/utils/contract-badges.hook';
import { ProfilDetails } from '../../components/profil-details/profil-details.component';
import { useRootStore as useRootStoreOSP } from '../../hook/useRootStore.hook';
import { PoolContractAction } from '../action/pool-contract.action';
import { PoolAdminList } from '../list/pool-admin.list';
import { SessionList } from '../list/session.list';

type Status = 'ACTIVE' | 'SUSPENDED' | 'EXPIRED';

class Static {
  static statusBadgeColorMap: Record<Status, BadgeType.Config.Color> = {
    ACTIVE: 'disabled',
    SUSPENDED: 'warn',
    EXPIRED: 'disabled',
  };
}

export type TenantPoolContractDetailsProps = {
  contract: Contract;
  onSelect?: (id: string) => void;
  widget?: {
    title?: string;
  };
};

export const TenantPoolContractDetails = observer(
  ({ onSelect, contract, widget }: TenantPoolContractDetailsProps) => {
    const { GlobalStore: GlobalStoreOSP } = useRootStoreOSP();

    const { t, lang } = useTranslation();
    const [segmented, setSegmented] =
      useState<SegmentedType.Data.Selected>('admin');
    const [form, setForm] = useState<FormType.Data.Value>(
      extractFormData(contract),
    );
    const { setParamsUrl } = useMyUrl({});

    const handleSubmit = (form: FormType.Data.Value) => {
      const updatedContract = {
        name1: form.name1 || '',
        name2: form.name2 || '',
        startDate: form.startDate,
        stopDate: form.endDate,
        spacesAllocated: Number(form.noOfSpace[1]),
        description: form.description || '',
        spacesOccupied: Number(form.noOfSpace[0]),
        reference: form.reference,
      } as Partial<Contract>;

      updateContract(updatedContract);
    };

    const updateContract = async (updatedContract: Partial<Contract>) => {
      setParamsUrl({ clicked: undefined });
      await requestGQL({
        gql: updatePoolContractGql,
        params: {
          contractId: contract._id,
          input: updatedContract,
        },
      });
    };

    const isStopped =
      DateTime.fromISO(contract.stopDate).diffNow().as('milliseconds') < 0;
    const isUpcoming = DateTime.fromISO(contract.startDate) > DateTime.now();
    const states: [ContractBadgesType.Data.State] = [
      contract.isEnabled ? 'ACTIVE' : 'SUSPENDED',
    ];
    if (isUpcoming) states.push('UPCOMING');
    if (isStopped) states.push('EXPIRED');
    if (isStopped || isUpcoming) states.shift();
    const badges = useContractBadges({ states });

    useEffect(() => {
      const {
        ospContractId,
        startDate,
        stopDate,
        name1,
        name2,
        spacesAllocated,
        description,
        reference,
        spacesOccupied,
      } = contract;
      setForm({
        name1,
        name2,
        code: ospContractId,
        reference: reference,
        noOfSpace: [
          `${GlobalStoreOSP.mode === 'tenant' ? '/' : ''}${spacesOccupied}`,
          `${spacesAllocated}`,
        ],
        startDate,
        endDate: stopDate,
        description,
      });
    }, [JSON.stringify(contract)]);

    return (
      <Widget.Group
        config={{
          title: widget?.title,
          backtitle: !!widget?.title,
        }}
      >
        <Zone
          config={{
            zones: [['profil'], ['segmented'], ['details']],
            rows: ['min-content', 'min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'profil' }}>
            <Widget>
              <ProfilDetails
                handleEvent={{ submit: handleSubmit }}
                data={{
                  icon: iconGlobalEntity['masterPoolContract'],
                  form: form,
                  badges: badges,
                }}
                config={{
                  form: {
                    title1: {
                      name: 'name2',
                      placeholder: t('name2'),
                    },
                    title2: {
                      name: 'name1',
                      placeholder: t('name1'),
                    },
                    description1: {
                      name: 'code',
                      placeholder: t('code'),
                      edit: false,
                    },
                    description2: {
                      name: 'reference',
                      placeholder: t('reference'),
                    },
                    group1: [
                      {
                        name: 'noOfSpace',
                        label: t('noOfSpace'),
                        element: { type: 'counter' },
                        render: (value) =>
                          `${value[0].replace('/', '')} / ${value[1].replace(
                            '/',
                            '',
                          )}`,
                      },
                      {
                        name: 'startDate',
                        label: t('startDate'),
                        element: {
                          type: 'datetime',
                          min: contract.dateBoundaryFromParent?.from || null,
                          max:
                            contract.stopDate ||
                            contract.dateBoundaryFromParent?.to,
                        },
                        render: (value: string) =>
                          DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                      },
                      {
                        name: 'endDate',
                        label: t('endDate'),
                        element: {
                          type: 'datetime',
                          min:
                            contract.startDate ||
                            contract.dateBoundaryFromParent?.from,
                          max: contract.dateBoundaryFromParent?.to || null,
                        },
                        render: (value: string) =>
                          DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                      },
                      {
                        name: 'description',
                        label: t('description'),
                        element: { type: 'textarea' },
                      },
                    ],
                  },
                  actions: (
                    <PoolContractAction
                      config={{ size: 'xlarge' }}
                      initialState={{
                        id: contract._id,
                        isStopped: isStopped,
                        status: contract.isEnabled,
                      }}
                    />
                  ),
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'segmented' }}>
            <Zone.Area config={{ area: 'segmented' }}>
              <Widget>
                <Segmented
                  handleEvent={{ option: setSegmented }}
                  data={{ selected: segmented }}
                  config={{
                    size: 'large',
                    options: [
                      { label: t('admins'), value: 'admin' },
                      { label: t('sessions'), value: 'session' },
                    ],
                  }}
                />
              </Widget>
            </Zone.Area>
          </Zone.Area>

          <Zone.Area config={{ area: 'details' }}>
            {segmented === 'admin' && contract && (
              <PoolAdminList contract={contract} />
            )}
            {segmented === 'session' && contract && (
              <SessionList
                overrideGQL={contractSessionsGql}
                parkingId={contract.parkingId}
                contractId={contract._id}
                category={contract.category}
              />
            )}
          </Zone.Area>
        </Zone>
      </Widget.Group>
    );
  },
);

function extractFormData(contract: Contract): FormType.Data.Value {
  const {
    ospContractId,
    startDate,
    stopDate,
    name1,
    name2,
    spacesAllocated,
    description,
    reference,
    spacesOccupied,
  } = contract;

  return {
    name1,
    name2,
    code: ospContractId,
    reference: reference,
    noOfSpace: [`${spacesOccupied}`, `${spacesAllocated}`],
    startDate,
    endDate: stopDate,
    description,
  };
}
