import {
  Description,
  ListFlatInfo,
  ListFlatInfoType,
  Widget,
  Zone,
  useTranslation,
} from '@gimlite/watermelon';
import './confirmation.form.scss';

export declare namespace ConfirmationFormType {
  type Props = {
    data: {
      list: ConfirmationFormType.Data.Line[];
    };
  };

  namespace Data {
    type Line = ListFlatInfoType.Data.Line<any>;
  }
}

export const ConfirmationForm = ({
  data: { list },
}: ConfirmationFormType.Props) => {
  const { t, lang } = useTranslation();

  return (
    <Widget
      config={{
        title: t('listOfNewFeaturesThatWillBeCreated'),
        shadow: false,
      }}
    >
      <Zone
        config={{
          gap: {
            y: 1,
            x: 0,
          },
          zones: [['description'], ['details']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'description' }}>
          <Description
            data={[{ key: 'total', label: t('total'), value: list.length }]}
            config={{ way: 'horizontal' }}
          ></Description>
        </Zone.Area>

        <Zone.Area config={{ area: 'details' }}>
          <ListFlatInfo<any>
            data={{
              list,
              paging: { count: 1, current: 1, limit: 1 },
            }}
            config={{
              pagination: 'none',
              columns: [{ key: 'component', size: '1fr' }],
              border: true,
            }}
          />
        </Zone.Area>
      </Zone>
    </Widget>
  );
};
