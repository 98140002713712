import { Icon } from '@gimlite/watermelon';
import './run-wizard.component.scss';

export declare namespace RunWizardType {
  type Props = {
    className?: string;
    handleEvent?: {
      click?: () => void;
    };
  };
}

export const RunWizard = ({
  className,
  handleEvent: { click } = {},
}: RunWizardType.Props) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        click?.();
      }}
      className={`run-wizard ${className}`}
    >
      <Icon
        config={{ type: 'faPlusSolid', size: 'xxsmall', color: 'primary-over' }}
      />
    </div>
  );
};
