import { getOperationName } from '@apollo/client/utilities';
import {
  Empty,
  FormType,
  Segmented,
  SegmentedType,
  Zone,
  requestGQL,
  useReadOf,
  useTranslation,
} from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { breakdownUpdateGql } from '../../common/gql/breakdown-update.gql';
import { breakdownGql } from '../../common/gql/breakdown.gql';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { Breakdown } from '../../common/types/entities/breakdowns';
import { DurationTimer } from '../../common/utils/duration-timer/duration-timer';
import { ProfilDetails } from '../../components/profil-details/profil-details.component';
import { BreakdownAction } from '../action/breakdown.action';
import { MotoristContractList } from '../list/motorist-contract.list';

type BreakdownsDetailsProps = {
  breakdownId: string | null;
  widget?: {
    title?: string;
  };
};

export const BreakdownsDetails = ({
  widget,
  breakdownId,
}: BreakdownsDetailsProps) => {
  const [segmented, setSegmented] =
    useState<SegmentedType.Data.Selected>('contracts');
  const { t } = useTranslation();

  const { details, setId } = useReadOf<Breakdown>({
    gql: breakdownGql,
    cache: false,
    wsSubscriptions: ['breakdowns:updated'],
    queryBuilder: (id) => ({ breakdownId: id }),
  });

  const [form, setForm] = useState<FormType.Data.Value>();
  useEffect(() => setId(breakdownId || undefined), [breakdownId]);

  useEffect(() => {
    if (!!details) {
      setForm({
        problem: details.type || '',
        access: details.description || '-',

        startDate: details.startDate,
        endDate: details.endDate,

        duration: DateTime.fromISO(details.endDate).diff(
          DateTime.fromISO(details.startDate),
          'seconds',
        ).seconds,
      });
    }
  }, [details]);

  return (
    <Widget.Group
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
      }}
    >
      {details && breakdownId && form ? (
        <Zone
          config={{
            zones: [['profil'], ['segmented'], ['details']],
            rows: ['min-content', 'min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'profil' }}>
            <Widget>
              <ProfilDetails
                handleEvent={{
                  submit: (form) => {
                    setForm(form);
                    const { endDate } = form;
                    requestGQL({
                      operationName: getOperationName(breakdownUpdateGql)!!,
                      params: {
                        breakdownId: details._id,
                        input: { endDate },
                      },
                      gql: breakdownUpdateGql,
                    });
                  },
                }}
                data={{
                  icon: iconGlobalEntity['breakdown'],
                  form: form,
                  badges:
                    DateTime.fromISO(details.endDate) < DateTime.now()
                      ? [
                          {
                            text: t('FINISHED'),
                            color: 'success',
                          },
                        ]
                      : [
                          {
                            text: t('PROGRESS'),
                            color: 'error',
                          },
                        ],
                }}
                config={{
                  edit: DateTime.fromISO(details.endDate) > DateTime.now(),
                  form: {
                    title1: {
                      name: 'problem',
                      placeholder: t('problem'),
                      edit: false,
                    },
                    description1: {
                      name: 'access',
                      placeholder: t('access'),
                      edit: false,
                    },
                    group1: [
                      {
                        name: 'startDate',
                        label: t('startDate'),
                        render: (value) =>
                          DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                        element: {
                          type: 'datetime',
                        },
                        edit: false,
                      },
                      {
                        name: 'duration',
                        label: t('duration'),
                        element: { type: 'input' },
                        render: (data) => DurationTimer(data),
                        edit: false,
                      },
                    ],
                    group2: [
                      {
                        name: 'endDate',
                        label: t('endDate'),
                        render: (value) =>
                          DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                        element: {
                          type: 'datetime',
                          min: DateTime.fromISO(details.startDate).toISO(),
                          max: DateTime.fromISO(details.startDate)
                            .plus({ hours: 72 })
                            .toISO(),
                        },
                        edit:
                          DateTime.fromISO(details.endDate) > DateTime.now(),
                      },
                    ],
                  },
                  actions: (
                    <BreakdownAction
                      config={{ size: 'xlarge' }}
                      data={{ breakdownId }}
                    />
                  ),
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'segmented' }}>
            <Widget>
              <Segmented
                handleEvent={{
                  option: (value) => {
                    setSegmented(value);
                  },
                }}
                data={{ selected: segmented }}
                config={{
                  size: 'large',
                  options: [
                    { label: t('contractsNeutralized'), value: 'contracts' },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'details' }}>
            {segmented === 'contracts' && (
              <MotoristContractList freeflagId={breakdownId} />
            )}
          </Zone.Area>
        </Zone>
      ) : (
        <Empty
          config={{
            mode: { name: 'select', text: `${t('selectBarrierFailure')} ...` },
          }}
        ></Empty>
      )}
    </Widget.Group>
  );
};
