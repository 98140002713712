import { MotoristLine } from '@gimlite/osp/composition/line/motorist.line';
import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import {
  ListFlatMultipleCheckType,
  requestGQL,
  useSearchOf,
} from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { globalSearchGQL } from '../../../../common/gql/globalSearch.gql';
import { localMotoristsGql } from '../../../../common/gql/local-motorists.gql';
import { LocalMotorist } from '../../../../common/types/entities/localMotorist';
import { anonimizedEmail } from '../../../../common/utils/anonymise-email';
import { SwitcherText } from '../../../../components/switcher-text/switcher-text.component';
import { MotoristsCreateForm } from '../../../../form/motorists-create/motorists-create.form';
import {
  MultipleCheckForm,
  MultipleCheckFormType,
} from '../../../../form/multiple-check/multiple-check.form';
import { AddSeveralMotoristsToAnExistingPoolContractWizardContext } from '../../addSeveralMotoristsToAnExistingPoolContract.wizard';

const params = { order: 'lastName' };
const pageLimit = 50;

export const MotoristsStep = ({
  context: { contract, motorists, motoristExistedSave, motoristMode },
  validate,
  submit,
}: WizardItemType.Config.Component<AddSeveralMotoristsToAnExistingPoolContractWizardContext>) => {
  const { t, lang } = useTranslation();

  const [searchLocalMotoristsResult, setSearchLocalMotoristsResult] = useState<
    LocalMotorist[] | null
  >(null);

  const { list, paging, setSearch } = useSearchOf<
    LocalMotorist,
    { motoristPage: number; motoristLimit: number }
  >({
    gql: localMotoristsGql,
    wsSubscriptions: ['localmotorist:created', 'localmotorist:updated'],
  });

  const motoristsFormattedLine = useCallback(
    (
      motorists: LocalMotorist[],
    ): ListFlatMultipleCheckType.Data.Line<any>[] => {
      return motorists.map(({ _id, firstName, lastName, email, status }) => {
        return {
          _id,
          items: {
            component: (
              <MotoristLine
                data={{
                  firstName,
                  lastName,
                  email: anonimizedEmail(email),
                  status: status === 'VERIFIED' ? 'VERIFIED' : 'UNVERIFIED',
                }}
                config={{ action: false, icon: true }}
              />
            ),
          },
        };
      });
    },
    [],
  );

  const handleSearch = useCallback(async (value: string) => {
    if (value && value.length >= 3) {
      const { localMotorists } = (await requestGQL({
        params: { content: value },
        gql: globalSearchGQL,
      })) as { localMotorists: LocalMotorist[] };

      setSearchLocalMotoristsResult(() => localMotorists);
      submit({
        motoristExistedSave: [...motoristExistedSave, ...localMotorists].reduce(
          (acc: LocalMotorist[], { _id, ...rest }) => {
            if (!acc.find((element) => element._id === _id)) {
              return [...acc, { _id, ...rest }];
            }
            return acc;
          },
          [],
        ),
      });

      setSearch({
        paging: { motoristPage: 1, motoristLimit: pageLimit },
        params,
      });
    } else {
      setSearchLocalMotoristsResult(() => null);
    }
  }, []);

  const { fresh, paging: pagingFormatted } = useMemo((): {
    fresh: LocalMotorist[] | undefined;
    paging?: MultipleCheckFormType.Props['data']['paging'];
  } => {
    if (!list) {
      return { fresh: undefined };
    } else {
      const listTarget = !searchLocalMotoristsResult
        ? list
        : searchLocalMotoristsResult;

      return {
        fresh: listTarget,
        paging: !searchLocalMotoristsResult
          ? paging
          : {
              count: searchLocalMotoristsResult.length,
              current: 1,
              limit: searchLocalMotoristsResult.length,
            },
      };
    }
  }, [list, paging, searchLocalMotoristsResult]);

  useEffect(() => {
    if (motoristMode === 'exist') validate(motorists.checked.length > 0);
  }, [motorists.checked, motoristMode]);

  useEffect(() => {
    if (motoristMode === 'exist') {
      const newList = motoristExistedSave;

      if (list) {
        list.forEach(({ _id, ...rest }) => {
          if (
            motoristExistedSave.findIndex((element) => element._id === _id) ===
            -1
          ) {
            newList.push({ _id, ...rest });
          }
        });
      }

      submit({
        motoristExistedSave: newList,
      });
    }
  }, [list, motoristExistedSave, motoristMode]);

  useEffect(() => {
    setSearch({
      paging: { motoristPage: 1, motoristLimit: pageLimit },
      params,
    });
  }, []);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'form']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'form' }}>
        <Zone
          config={{
            gap: {
              y: 0,
              x: 0,
            },
            zones: [
              ['link', '.'],
              ['work', 'work'],
            ],
            rows: ['min-content', '1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area config={{ area: 'link' }}>
            <Widget config={{ shadow: false, full: true }}>
              <SwitcherText<
                AddSeveralMotoristsToAnExistingPoolContractWizardContext['motoristMode']
              >
                handleEvent={{
                  mode: (value) => {
                    setSearch({
                      paging: {
                        motoristLimit: pageLimit,
                        motoristPage: 1,
                      },
                      params,
                    });

                    submit({
                      motoristMode: value,
                      motorists: {
                        checked: [],
                        created: [],
                      },
                      motoristExistedSave:
                        value === 'create' ? [] : motoristExistedSave,
                    });
                  },
                }}
                data={{ value: motoristMode }}
                config={{
                  mode: [
                    {
                      label: t('chooseExistingMotorists?'),
                      value: 'create',
                    },
                    {
                      label: t('createMotorists?'),
                      value: 'exist',
                    },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'work' }}>
            <Widget
              config={{
                title:
                  motoristMode === 'exist'
                    ? t('listOfExistingMotorist')
                    : t('createSeveralMotoristContracts'),
                shadow: false,
              }}
            >
              {motoristMode === 'exist' ? (
                <MultipleCheckForm
                  config={{
                    searchPlaceholder: t('searchAMotorist'),
                  }}
                  handleEvent={{
                    checkList: (list) => {
                      submit({
                        motorists: {
                          ...motorists,
                          checked: list.map(
                            (id) =>
                              [fresh ? fresh : [], motoristExistedSave]
                                .flat()
                                .find(
                                  (element) => element._id === id,
                                ) as LocalMotorist,
                          ),
                        },
                      });
                    },
                    paging: (data) => {
                      setSearch({
                        paging: {
                          motoristLimit: data.limit,
                          motoristPage: data.page,
                        },
                        params,
                      });
                    },
                    search: handleSearch,
                  }}
                  data={{
                    paging: pagingFormatted,
                    checked: motorists.checked.map((element) => element._id),
                    fresh: fresh ? motoristsFormattedLine(fresh) : undefined,
                    saved: motoristsFormattedLine(motoristExistedSave),
                  }}
                />
              ) : (
                <MotoristsCreateForm
                  config={{
                    validIfEmpty: true,
                  }}
                  handleEvent={{
                    validate: (isValid) => {
                      validate(isValid && motorists.created.length > 0);
                    },
                    submit: (data) => {
                      submit({ motorists: { ...motorists, created: data } });
                    },
                  }}
                  data={{
                    created: motorists.created,
                  }}
                />
              )}
            </Widget>
          </Zone.Area>
        </Zone>
      </Zone.Area>
    </Zone>
  );
};
