import { DateTime } from 'luxon';
import { MotoristCreateFormType } from '../../form/motorist-create/motorist-create.form';
import { CredentialType } from '../mapper/credential.mapper';
import { Languages } from '@gimlite/watermelon';
import { v4 as uuidv4 } from 'uuid';

export const DefaultCredentialsQrcodeWizard = ({
  t,
  lang,
}: {
  t: Function;
  lang: Languages | null;
}): CredentialType.CredentialFromFront => {
  return {
    name: 'QRCODE',
    generatedAt: DateTime.now().toFormat('dd/MM/yyyy HH:mm:ss'),
    value: t('generatedAtTheEnd'),
  };
};

export const DefaultMotoristWizard = ({
  t,
  lang,
}: {
  t: Function;
  lang: Languages | null;
}): MotoristCreateFormType.Data.Motorist => {
  return {
    _id: uuidv4(),
    firstName: null,
    lastName: null,
    address1: null,
    address2: null,
    city: null,
    zipcode: null,
    country: 'CHE',
    username: null,
    lang: lang,
    status: null,
    invitationMotorist: false,
  };
};
