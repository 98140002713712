import { useRootStore as useRootStoreOSP } from '@gimlite/osp';
import {
  BadgeType,
  CountryEntry,
  FormType,
  Segmented,
  SegmentedType,
  Zone,
  countries,
  requestGQL,
  useMyUrl,
  useTranslation,
} from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { contractSessionsGql } from '../../common/gql/contract-sessions.gql';
import { getProductsOptionsGql } from '../../common/gql/get-products-options.gql';
import { updateContractGql } from '../../common/gql/update-contract.gql';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { Contract } from '../../common/types/entities/contract';
import {
  ContractBadgesType,
  useContractBadges,
} from '../../common/utils/contract-badges.hook';
import { ProfilDetails } from '../../components/profil-details/profil-details.component';
import { PoolContractAction } from '../action/pool-contract.action';
import { PoolAdminList } from '../list/pool-admin.list';
import { SessionList } from '../list/session.list';

type Status = 'ACTIVE' | 'SUSPENDED' | 'EXPIRED';

class Static {
  static statusBadgeColorMap: Record<Status, BadgeType.Config.Color> = {
    ACTIVE: 'disabled',
    SUSPENDED: 'warn',
    EXPIRED: 'disabled',
  };
}

export type MasterPoolContractDetailsProps = {
  contract: Contract;
  widget?: {
    title?: string;
  };
};

function extractFormData(contract: Contract): FormType.Data.Value {
  const {
    startDate,
    stopDate,
    name1,
    name2,
    ospContractId,
    address,
    spacesAllocated,
    description,
    reference,
    spacesOccupied,
    contractCount,
    productId,
  } = contract;

  const { address1, address2, city, country, zipcode } = address || {
    address1: '',
    address2: '',
    city: '',
    country: '',
    zipcode: '',
  };

  return {
    name1,
    name2,
    code: ospContractId,
    reference: reference,
    streetName: address1,
    streetNumber: address2,
    zipCode: zipcode,
    city,
    country: country,
    product: productId,
    noOfSpace:
      contractCount === 1
        ? [`${spacesOccupied}`, `${spacesAllocated}`]
        : [`/${spacesOccupied}`, `${spacesAllocated}`],
    startDate,
    endDate: stopDate,
    description,
  };
}

export const MasterPoolContractDetails = observer(
  ({ widget, contract }: MasterPoolContractDetailsProps) => {
    const { t, lang } = useTranslation();
    const { GlobalStore: GlobalStoreOSP } = useRootStoreOSP();

    const [segmented, setSegmented] =
      useState<SegmentedType.Data.Selected>('admin');

    const [form, setForm] = useState<FormType.Data.Value>(
      extractFormData(contract),
    );
    const [data, setData] = useState<Contract>(contract);

    const [productOptions, setProductOptions] = useState<any[]>([]);

    const countryOptions = useMemo(() => {
      if (!countries || !lang || !countries[lang]) return [];

      return countries[lang].map(({ value, label }: CountryEntry) => ({
        label,
        value: value.ISO3,
      }));
    }, [countries, lang]);
    const { setParamsUrl } = useMyUrl({});

    useEffect(() => {
      setSegmented('admin');
      setForm(extractFormData(contract));
    }, [contract]);

    const handleSubmit = ({
      name1,
      name2,
      startDate,
      endDate,
      streetNumber,
      streetName,
      zipCode,
      city,
      country,
      description,
      reference,
      noOfSpace,
      product,
    }: FormType.Data.Value) => {
      let updatedContract = {
        name1: name1 || '',
        name2: name2 || '',
        startDate,
        stopDate: endDate,
        spacesAllocated: Number(noOfSpace[1]),
        address: {
          address1: streetName,
          address2: streetNumber,
          city,
          country,
          zipcode: zipCode,
        },
        description: description || '',
        reference: reference,
        productId: product,
      } as Partial<Contract>;
      if (
        contract.contractCount === 1 &&
        contract.spacesOccupied !== Number(noOfSpace[0])
      ) {
        updatedContract.spacesOccupied = Number(noOfSpace[0]);
      }
      updateContract(updatedContract);
    };

    const updateContract = async (updatedContract: Partial<Contract>) => {
      if (data.isEnabled === updatedContract.isEnabled) return;

      setParamsUrl({ clicked: undefined });

      requestGQL({
        gql: updateContractGql,
        params: {
          contractId: contract._id,
          input: updatedContract,
        },
      }).then((mutatedContract: Contract) => {
        setData(mutatedContract);
      });
    };

    const fetchProducts = useCallback(async () => {
      const products = await requestGQL({
        operationName: 'products',
        gql: getProductsOptionsGql,
        params: {
          parkingId: contract.parkingId,
          category: contract.category,
          productLimit: 999,
        },
        render: (data) => data.list,
      });

      return products;
    }, [contract.category, contract.parkingId]);

    useEffect(() => {
      fetchProducts().then((products) => setProductOptions(products));
    }, [contract.category, contract.parkingId]);

    const status = contract.isEnabled ? 'ACTIVE' : 'SUSPENDED';
    const isStopped =
      DateTime.fromISO(contract.stopDate).diffNow().as('milliseconds') < 0;
    const isUpcoming = DateTime.fromISO(contract.startDate) > DateTime.now();
    const states: [ContractBadgesType.Data.State] = [status];
    if (isUpcoming) states.push('UPCOMING');
    if (isStopped) states.push('EXPIRED');
    if (isStopped || isUpcoming) states.shift();
    if (contract.isInside) states.push('PRESENT');
    const badges = useContractBadges({ states });

    return (
      <Widget.Group
        config={{
          title: widget?.title,
          backtitle: !!widget?.title,
        }}
      >
        <Zone
          config={{
            zones: [['profil'], ['segmented'], ['details']],
            rows: ['min-content', 'min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'profil' }}>
            <Widget>
              <ProfilDetails
                handleEvent={{ submit: handleSubmit }}
                data={{
                  icon: iconGlobalEntity['masterPoolContract'],
                  form,
                  badges: badges,
                }}
                config={{
                  edit: GlobalStoreOSP.mode !== 'tenant',
                  form: {
                    title1: {
                      name: 'name2',
                      placeholder: t('name2'),
                    },
                    title2: {
                      name: 'name1',
                      placeholder: t('name1'),
                    },
                    description1: {
                      name: 'code',
                      placeholder: t('code'),
                      edit: false,
                    },
                    description2: {
                      name: 'reference',
                      placeholder: t('reference'),
                    },
                    group1: [
                      {
                        name: 'product',
                        label: t('product'),
                        element: {
                          type: 'select',
                          items: productOptions.map(({ _id, name }) => ({
                            label: t(`product-${_id}-name`),
                            value: _id,
                          })),
                        },
                      },
                      {
                        name: 'noOfSpace',
                        label: t('noOfSpace'),
                        element: { type: 'counter' },
                        render: (value) =>
                          `${value[0].replace('/', '')} / ${value[1].replace(
                            '/',
                            '',
                          )}`,
                      },
                      {
                        name: 'startDate',
                        label: t('startDate'),
                        element: { type: 'datetime', max: contract.stopDate },
                        render: (value: string) =>
                          DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                      },
                      {
                        name: 'endDate',
                        label: t('endDate'),
                        element: {
                          type: 'datetime',
                          min: contract.startDate,
                        },
                        render: (value: string) =>
                          DateTime.fromISO(value).toFormat('dd/MM/yy HH:mm:ss'),
                      },
                      {
                        name: 'description',
                        label: t('description'),
                        element: { type: 'textarea' },
                      },
                    ],
                    group2: [
                      {
                        name: 'streetName',
                        label: t('address1'),
                        element: { type: 'input' },
                      },
                      {
                        name: 'streetNumber',
                        label: t('address2'),
                        element: { type: 'input' },
                      },
                      {
                        name: 'zipCode',
                        label: t('zipCode'),
                        element: { type: 'input' },
                      },
                      {
                        name: 'city',
                        label: t('city'),
                        element: { type: 'input' },
                      },
                      {
                        name: 'country',
                        label: t('country'),
                        element: {
                          type: 'select',
                          items: countryOptions,
                        },
                      },
                    ],
                  },
                  actions:
                    GlobalStoreOSP.mode !== 'tenant' ? (
                      <PoolContractAction
                        config={{ size: 'xlarge' }}
                        initialState={{
                          id: contract._id,
                          parkingId: contract.parkingId,
                          isStopped: isStopped,
                          status: contract.isEnabled,
                        }}
                      />
                    ) : undefined,
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'segmented' }}>
            <Widget>
              <Segmented
                handleEvent={{ option: setSegmented }}
                data={{ selected: segmented }}
                config={{
                  size: 'large',
                  options: [
                    { label: t('admins'), value: 'admin' },
                    { label: t('sessions'), value: 'session' },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'details' }}>
            {contract && (
              <>
                {segmented === 'admin' && <PoolAdminList contract={contract} />}
                {segmented === 'session' && (
                  <SessionList
                    overrideGQL={contractSessionsGql}
                    parkingId={contract.parkingId}
                    contractId={contract._id}
                    category={contract.category}
                  />
                )}
              </>
            )}
          </Zone.Area>
        </Zone>
      </Widget.Group>
    );
  },
);
