import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { RootStoreContext, useRootStore } from '../hook/useRootStore.hook';
import { GlobalStoreContextType } from '../store/global.store';
import { RootStoreMobX } from '../store/root.store';

export const RootStore: RootStoreMobX = new RootStoreMobX();

export type ConfigProps = {
  children: React.ReactNode;
  mode: GlobalStoreContextType['mode'];
  ENV: GlobalStoreContextType['ENV'];
  BFF_MOTORIST_HOST: GlobalStoreContextType['BFF_MOTORIST_HOST'];
  BFF_MOTORIST_TOKEN: GlobalStoreContextType['BFF_MOTORIST_TOKEN'];
};

const ConfigObserver = observer(
  ({
    children,
    mode,
    ENV,
    BFF_MOTORIST_HOST,
    BFF_MOTORIST_TOKEN,
  }: ConfigProps) => {
    const { GlobalStore } = useRootStore();

    useEffect(() => {
      if (!GlobalStore.load) {
        GlobalStore.initBaseProps({
          ENV,
          BFF_MOTORIST_HOST,
          BFF_MOTORIST_TOKEN,
          mode,
        });
      }
    }, []);

    return children;
  },
);

export const Config = (data: ConfigProps) => (
  <RootStoreContext.Provider value={RootStore ?? new RootStoreMobX()}>
    <ConfigObserver {...data} />
  </RootStoreContext.Provider>
);
