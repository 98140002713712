export const breakdownsCategory = {
  C10: { translate: 'barrierFailure' },
  C20: { translate: 'cameraFailure' },
  C30: { translate: 'freePeriod' },
  C40: { translate: 'paymentBoxFailure' }
} as const

export const breakdownsType = {
  ENTRY: { translate: 'entry' },
  EXIT: { translate: 'exit' }
} as const
