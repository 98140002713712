import {
  Widget,
  Write,
  toCapitalizeCase,
  useMyUrl,
  useSearchOf,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { productsGql } from '../../../common/gql/products.gql';
import { convertCredentialsBackToFrontName } from '../../../common/mapper/credential.mapper';
import { APBModeDetectWithCycleControl } from '../../../common/mapper/product.mapper';
import {
  ProductCategory,
  Product as ProductEntity,
} from '../../../common/types/entities/product';
import { Product } from '../../../components/product/product.component';
import './product.list.scss';

const categorys: Array<{
  title: string;
  category: ProductCategory;
}> = [
  { title: 'forAVisitor', category: 'VISITOR' },
  {
    title: 'forASingleContract',
    category: 'PERIOD_SUBSCRIPTION',
  },
  { title: 'forAPoolContract', category: 'OPERATOR_POOL' },
];

export type ProductsWidgetProps = {
  parkingId: string;
};

export const ProductList = observer(({ parkingId }: ProductsWidgetProps) => {
  const { t, lang } = useTranslation();
  const { getParamsUrl, setParamsUrl } = useMyUrl({});

  const { list, paging, setSearch } = useSearchOf<
    ProductEntity,
    { productPage: number; productLimit: number }
  >({
    gql: productsGql,
    wsSubscriptions: [],
  });

  const contractsGroup = useMemo(
    () =>
      categorys.reduce(
        (
          acc: Array<{ title: string; items: ProductEntity[] }>,
          { title, category },
        ) => [
          ...acc,
          {
            title,
            items: list
              ? list.filter((product) => product.category === category)
              : [],
          },
        ],
        [],
      ),
    [list, lang],
  );
  const paramsFormatted = useMemo(() => {
    const { parkingId, productPage, productLimit } = getParamsUrl;

    return { parkingId, productPage, productLimit };
  }, [getParamsUrl]);

  useEffect(() => {
    setParamsUrl({
      ...getParamsUrl,
      productPage: 1,
      productLimit: 100,
      parkingId,
    });
  }, [parkingId]);

  useEffect(() => {
    setSearch({
      paging: {
        productPage: paramsFormatted.productPage || 1,
        productLimit: paramsFormatted.productLimit || 100,
      },
      params: { ...paramsFormatted, parkingId },
    });
  }, [paramsFormatted, parkingId]);

  return (
    <Widget
      className="product-list"
      config={{
        title: 'Products',
        backtitle: true,
        extra: true,
      }}
    >
      <div className="product-list__contain">
        {contractsGroup.map(({ items, title }, index) => (
          <div
            key={`product-list__contain__line-${index}`}
            className="product-list__contain__line"
          >
            <Write
              className="product-list__contain__line__title"
              config={{
                mode: 'title-medium',
              }}
              data={{
                item: t(title),
              }}
            />
            <div className="product-list__contain__line__product">
              {items.map(
                (
                  {
                    _id,
                    parameters,
                    rules,
                    externalPayload,
                    createdAt,
                    category,
                  },
                  index,
                ) => {
                  const APBMode = APBModeDetectWithCycleControl(
                    rules?.cycleControl || null,
                  );

                  return (
                    <div
                      key={`product-list__contain__line__product__item-${index}`}
                      className="product-list__contain__line__product__item"
                    >
                      <Product
                        data={{
                          _id,
                          title: t(`product-${_id}-name`),
                          description: t(`product-${_id}-description`),
                          price:
                            parameters?.cents &&
                            typeof parameters?.cents === 'number'
                              ? parameters.cents / 100
                              : null,
                          currency: parameters?.currency || null,
                          period: {
                            time: parameters?.durationUnit
                              ? t(parameters.durationUnit)
                              : null,
                            count: parameters?.durationValue || null,
                          },
                          witEngament: false,
                          advantage: [
                            {
                              label: t(
                                `antiPassback${toCapitalizeCase(APBMode)}`,
                              ),
                              isPresent: !(APBMode === 'NONE'),
                            },
                          ],
                          credential: rules?.credentialsTypes?.types
                            ? rules.credentialsTypes.types.map((item) =>
                                convertCredentialsBackToFrontName(item),
                              )
                            : null,
                          info: {
                            articleCode: externalPayload?.ArticleCode || null,
                            createdAt:
                              DateTime.fromISO(createdAt).toFormat(
                                'dd/MM/yy HH:mm:ss',
                              ),
                          },
                          category,
                        }}
                      />
                    </div>
                  );
                },
              )}
            </div>
          </div>
        ))}
      </div>
    </Widget>
  );
});
