import { dispatch } from '@gimlite/router';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import {
  breakdownsCategory,
  breakdownsType,
} from '../../common/mapper/breakdown.mapper';
import { MotoristContract } from '../../common/types/entities/motorist-contract';
import {
  ReportTechnicalDeviceFailureServiceContextType,
  reportTechnicalDeviceFailureService,
} from './reportTechnicalDeviceFailure.machine';
import { IssueStep } from './steps/issue/issue.step';
import { MotoristsStep } from './steps/motorists/motorists.step';
import { SummaryStep } from './steps/summary/summary.step';

export type ReportTechnicalDeviceFailureContext = {
  parkingId: string | null;
  issue: {
    startDate: string | null;
    endDate: string | null;
    duration: number | null;
    accessPointType: keyof typeof breakdownsType;
    category: keyof typeof breakdownsCategory;
    description: string | null;
  };
  motoristsConcerned: MotoristContract[];
};

export const ReportTechnicalDeviceFailureWizard = () => {
  const { t, lang } = useTranslation();

  const { parkingId } = useSelector(
    reportTechnicalDeviceFailureService,
    ({
      context,
    }: {
      context: ReportTechnicalDeviceFailureServiceContextType;
    }) => context,
  );

  return (
    <Wizard<ReportTechnicalDeviceFailureContext>
      config={{
        title: t(`reportTechnicalDeviceFailure`),
      }}
      data={{
        defaultContext: {
          parkingId,
          issue: {
            startDate: new Date().toISOString(),
            endDate: null,
            duration: null,
            accessPointType: 'ENTRY',
            category: 'C10',
            description: null,
          },
          motoristsConcerned: [],
        },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          dispatch('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item config={{ title: t('issue'), component: IssueStep }} />
      <Wizard.Item
        config={{ title: t('motorists'), component: MotoristsStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
};
