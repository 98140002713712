import { gql } from '@apollo/client';

export const contractGql = gql`
  query contract($id: String!) {
    contract(id: $id) {
      _id
      category
      ospContractId
      startDate
      stopDate
      isEnabled
      contractCount
      spacesAllocated
      name1
      name2
      parkingId
      description
      isInside
      reference
      spacesOccupied
      productId
      product {
        rules {
          credentialsTypes {
            types {
              type
            }
          }
        }
      }
      dateBoundaryFromParent {
        from
        to
      }

      motoristId
      motorist {
        _id
        firstName
        lastName
        address1
        address2
        zipcode
        credentials {
          type
          value
          provider
        }
        city
        state
        country
        phone
        gender
        contractIds
      }

      address {
        address1
        address2
        city
        country
        zipcode
      }

      credentials {
        type
        provider
        value
        description
      }

      poolUsers {
        _id
        firstName
        lastName
        username
      }

      freeflag {
        _id
      }

      rules
    }
  }
`;
