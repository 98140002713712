import { useRootStore, useTranslation } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import {
  CredentialCard,
  CredentialCardType,
  CredentialMini,
} from '../../components/credential/credential.component';
import { CredentialType } from '../../common/mapper/credential.mapper';

export class QRCODECredentialStatic {
  static info: CredentialType.Static<QRCODECredentialCardType.Data> = {
    name: 'QRCODE',
    icon: 'faQrcodeSolid',
    backgroundColor: { light: '#333333', dark: '#e8e8e8' },
    color: { light: '#e8e8e8', dark: '#333333' },
    category: 'QRCODE',
    primaryValue: 'value',
  };
}

export declare namespace QRCODECredentialMiniType {
  type Props = {
    data?: {
      value?: string;
    };
  };
}

export const QRCODECredentialMini = observer(
  ({ data: { value } = {} }: QRCODECredentialMiniType.Props) => {
    const { ThemeStore } = useRootStore();

    return (
      <CredentialMini
        data={{ text: value }}
        config={{
          icon: QRCODECredentialStatic.info.icon,
          backgroundColor:
            QRCODECredentialStatic.info.backgroundColor[ThemeStore.theme],
          color: QRCODECredentialStatic.info.color[ThemeStore.theme],
        }}
      />
    );
  },
);

export declare namespace QRCODECredentialCardType {
  type Props = {
    handleEvent?: {
      event?: CredentialCardType.HandleEvent.Event['function'];
    };
    data: QRCODECredentialCardType.Data;
    config?: {
      isActive?: boolean;
    };
  };

  type Data = {
    generatedAt: string;
    value: string;
  };
}

export const QRCODECredentialCard = observer(
  ({
    handleEvent: { event } = {},
    data: { generatedAt, value },
    config: { isActive = true } = {},
  }: QRCODECredentialCardType.Props) => {
    const { t, lang } = useTranslation();
    const { ThemeStore } = useRootStore();

    return (
      <CredentialCard.Context.Consumer>
        {({ place, edit }) => (
          <CredentialCard
            handleEvent={{ event, edit }}
            data={{
              name: QRCODECredentialStatic.info.name,
              info: [
                { key: 'generated', label: t('generated'), value: generatedAt },
                { key: 'value', label: t('value'), value: value },
              ],
              category: QRCODECredentialStatic.info.category,
              primaryValue: QRCODECredentialStatic.info.primaryValue,
            }}
            config={{
              icon: QRCODECredentialStatic.info.icon,
              backgroundColor:
                QRCODECredentialStatic.info.backgroundColor[ThemeStore.theme],
              color: QRCODECredentialStatic.info.color[ThemeStore.theme],
              disabled: !isActive,
              action:
                place === 'wizardNewMotorist'
                  ? []
                  : place === 'wizardMotoristExist'
                  ? [{ label: t('download'), value: 'download' }]
                  : [
                      { label: t('download'), value: 'download' },
                      { label: t('re-generate'), value: 're-generate' },
                    ],
            }}
          />
        )}
      </CredentialCard.Context.Consumer>
    );
  },
);
