import { gql } from '@apollo/client'

export const incidentGql = gql`
  query technicalEvent($id: String!) {
    technicalEvent(id: $id) {
      _id
      key
      category
      criticity
      from
      to {
        channel
      }
      payload
      createdAt
    }
  }
`
