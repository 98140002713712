import { dispatch } from '@gimlite/router';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { GlobalSearchContractEntityResponse } from '../../common/gql/globalSearch.gql';
import { CredentialType } from '../../common/mapper/credential.mapper';
import { MasterContractStep } from '../../common/steps/masterContract/masterContract.step';
import { SubContractStep } from '../../common/steps/subContract/subContract.step';
import { LocalMotorist } from '../../common/types/entities/localMotorist';
import { ContractCreateFormType } from '../../form/contract-create/contract-create.form';
import { MotoristsCreateFormType } from '../../form/motorists-create/motorists-create.form';
import {
  AddSeveralMotoristsToAnExistingPoolContractServiceContextType,
  addSeveralMotoristsToAnExistingPoolContractService,
} from './addSeveralMotoristsToAnExistingPoolContract.machine';
import { ConfirmationStep } from './steps/confirmation/confirmation.step';
import { ContractStep } from './steps/contract/contract.step';
import { MotoristsStep } from './steps/motorists/motorists.step';
import { SummaryStep } from './steps/summary/summary.step';
import { ConfigProps } from '../../config';

export type AddSeveralMotoristsToAnExistingPoolContractWizardContext = {
  parkingId: string | null;
  masterPoolContractId: string | null;
  product: {
    id: string | null;
    credentials: CredentialType.GlobalName[];
  };
  subPoolContractId: string | null;
  parentContract: ContractCreateFormType.Data.ContractPool | null;
  contract: ContractCreateFormType.Data.ContractMotorist;
  motorists: {
    created: MotoristsCreateFormType.Data.Created[];
    checked: LocalMotorist[];
  };
  motoristMode: 'create' | 'exist';
  motoristExistedSave: LocalMotorist[];
  subPoolExistedSave: GlobalSearchContractEntityResponse[];
  masterPoolExistedSave: GlobalSearchContractEntityResponse[];
  machine: any;
  mode: ConfigProps['mode'];
};

export const AddSeveralMotoristsToAnExistingPoolContractWizard = () => {
  const { t, lang } = useTranslation();

  const { parkingId, skipSubContract, mode } = useSelector(
    addSeveralMotoristsToAnExistingPoolContractService,
    ({
      context,
    }: {
      context: AddSeveralMotoristsToAnExistingPoolContractServiceContextType;
    }) => context,
  );

  return (
    <Wizard<AddSeveralMotoristsToAnExistingPoolContractWizardContext>
      config={{
        title: t(`addSeveralMotoristsToAnExistingPoolContract`),
      }}
      data={{
        defaultContext: {
          parkingId,
          product: {
            id: null,
            credentials: [],
          },
          masterPoolContractId: null,
          subPoolContractId: null,
          parentContract: null,
          contract: {
            startDate: new Date().toISOString(),
            endDate: null,
            APBMode: 'HARD',
            APBNext: false,
            reference: null,
            description: null,
            state: true,
          },
          motorists: {
            checked: [],
            created: [],
          },
          motoristMode: 'exist',
          motoristExistedSave: [],
          subPoolExistedSave: [],
          masterPoolExistedSave: [],
          machine: addSeveralMotoristsToAnExistingPoolContractService,
          mode,
        },
      }}
      handleEvent={{
        done: async (data: any) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          dispatch('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item
        config={{ title: t('masterContract'), component: MasterContractStep }}
      />
      {!skipSubContract && (
        <Wizard.Item
          config={{ title: t('subContract'), component: SubContractStep }}
        />
      )}
      <Wizard.Item config={{ title: t('contract'), component: ContractStep }} />
      <Wizard.Item
        config={{ title: t('motorists'), component: MotoristsStep }}
      />
      <Wizard.Item
        config={{ title: t('confirmation'), component: ConfirmationStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
};
