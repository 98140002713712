export type StringFn = (input: string) => string;

export type TokenMapper = {
  [key: string]: (payload: any, t: StringFn) => string;
};

export function parseTokens(
  input: string,
  payload: object,
  tokenMapper: TokenMapper,
  t: StringFn,
): string {
  return Object.entries(tokenMapper)
    .filter(([token]) => input.includes(token))
    .reduce(
      (acc, [token, fn]) => acc.replaceAll('[' + token + ']', fn(payload, t)),
      input,
    );
}
