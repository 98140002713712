import { gql } from '@apollo/client'

export type SubContractsResponse = {
  list: Array<SubContractsEntityResponse>
  paging: {
    current: number
    count: null
    limit: number
  }
}

export type SubContractsEntityResponse = {
  _id: string
  ospContractId: string
  isEnabled: boolean
  productId: string
  name1: string
  name2: string | null
  contractCount: string
  spacesAllocated: number
  spacesOccupied: number
  startDate: string
  stopDate: string
}

export type SubContractsParams = {
  page: number
  limit: number
  order: string
  linkedContractId: string
}

export const subContractsGql = gql`
  query subContracts(
    $page: Int!
    $limit: Int!
    $order: String
    $linkedContractId: String
  ) {
    subContracts(
      page: $page
      limit: $limit
      order: $order
      linkedContractId: $linkedContractId
    ) {
      list {
        _id
        ospContractId
        isEnabled
        productId
        name1
        name2
        contractCount
        spacesAllocated
        spacesOccupied
        stopDate
        startDate
      }
      paging {
        current
        count
        limit
      }
    }
  }
`
