import { EventObject, assign, createMachine, interpret } from 'xstate';
import { CreateNewSingleContractWizardContext } from './createNewSingleContract.wizard';

export type CreateNewSingleContractServiceContextType = {
  parkingId: CreateNewSingleContractWizardContext['parkingId'];
  error: string | null;
};

const createNewSingleContractServiceContext: CreateNewSingleContractServiceContextType =
  {
    parkingId: null,
    error: null,
  };

const machine = createMachine({
  id: 'createNewSingleContract',
  initial: 'off',
  context: createNewSingleContractServiceContext,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
          actions: assign({
            parkingId: (
              _,
              { parkingId }: EventObject & { parkingId: string },
            ) => parkingId,
          }),
        } as any,
      },
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
      },
    },
    failure: {
      on: {
        RETRY: {
          actions: assign({ error: null }),
          target: 'idle',
        },
      },
    },
  },
});

export const createNewSingleContractService = interpret(machine).start();
