import { gql } from '@apollo/client';
import { PoolUser } from '../types/entities/pool-user';

export type VerifyUserResponse = {
  user: PoolUser;
  fieldsToVerify: string[];
};

export type VerifyUser = {
  token: string;
};

export const verifyUserGQL = gql`
  query verifyUser($token: String!) {
    verifyUser(token: $token) {
      user {
        _id
        username
        firstName
        lastName
        phone
        accountId
        lang
        operatorContracts
      }
      fieldsToVerify
    }
  }
`;
