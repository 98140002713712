import { gql } from '@apollo/client';

export const localMotoristGql = gql`
  query localMotorist(
    $localMotoristId: String
    $email: String
    $plates: [PlateInput!]
    $uids: [UidInput!]
    $firstName: String
    $lastName: String
    $phone: String
  ) {
    localMotorist(
      localMotoristId: $localMotoristId
      email: $email
      plates: $plates
      uids: $uids
      firstName: $firstName
      lastName: $lastName
      phone: $phone
    ) {
      _id
      plates {
        type
        value
        description
      }
      cards {
        type
        value
        provider
        description
      }
      uids {
        type
        value
        provider
        description
      }
      email
      firstName
      lastName
      address1
      address2
      zipcode
      city
      country
      phone
      inside
      lang
      left
      linkedMotoristId
      status
      linkedmotorist {
        _id
        consumer
        shortUid
        contracts {
          list {
            stopDate
          }
        }
        credentials {
          type
          value
          provider
          description
        }
        qrcode {
          type
          token
          createdAt
          disabledAt
        }
      }
    }
  }
`;
