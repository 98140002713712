import {
  Empty,
  FormType,
  Segmented,
  SegmentedType,
  Widget,
  Zone,
  useReadOf,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { sessionGql } from '../../common/gql/session.gql';
import { ComponentInfosSelector } from '../../common/mapper/eventsInfos.mapper';
import { iconGlobalEntity } from '../../common/mapper/icon.mapper';
import { Event } from '../../common/types/entities/event';
import { Session } from '../../common/types/entities/session';
import { Translation } from '../../common/types/entities/translation';
import { DurationTimer } from '../../common/utils/duration-timer/duration-timer';
import { ProfilDetails } from '../../components/profil-details/profil-details.component';
import { TimelineGroup } from '../../components/timeline/timeline.component';
import { SessionAction } from '../action/session.action';

export type SessionDetailsProps = {
  sessionId: string | null;
  parkingId: string;
  widget?: {
    title?: string;
  };
};

const sessionRender = ({
  events,
  entryDate,
  exitDate,
  amount,
  currency,
  ...session
}: Session) => ({
  ...session,
  startDate: entryDate,
  amount:
    amount && currency
      ? new Intl.NumberFormat(currency, {
          style: 'currency',
          currency: currency,
        }).format(amount / 100)
      : currency
      ? new Intl.NumberFormat(currency, {
          style: 'currency',
          currency: currency,
        }).format(0)
      : '-',
  entryDate:
    entryDate !== '1970-01-01T00:00:00.000Z'
      ? DateTime.fromISO(entryDate).toLocaleString(
          DateTime.DATETIME_SHORT_WITH_SECONDS,
        )
      : 'N/A',
  exitDate:
    exitDate !== '1970-01-01T00:00:00.000Z'
      ? DateTime.fromISO(exitDate).toLocaleString(
          DateTime.DATETIME_SHORT_WITH_SECONDS,
        )
      : 'N/A',
  events: events.map((event: Event) => {
    const accesspoint = session?.parking?.accessPoints.find(
      (access: any) => access._id === event.accessPointId,
    );

    return {
      ...event,
      currency: currency,
      accessPointCode: accesspoint?.shortCode || 'undefined',
      createdAt: DateTime.fromISO(event.createdAt).toLocaleString(
        DateTime.DATETIME_SHORT_WITH_SECONDS,
      ),
    };
  }),
});

export const SessionDetails = ({
  widget,
  sessionId,
  parkingId,
}: SessionDetailsProps) => {
  const { t, lang } = useTranslation();
  const [form, setForm] = useState<FormType.Data.Value>();
  const [segmented, setSegmented] =
    useState<SegmentedType.Data.Selected>('history');

  const { details, setId } = useReadOf<Session>({
    gql: sessionGql,
    wsSubscriptions: ['sessions:updated'],
    queryBuilder: (id) => ({ sessionId: id }),
    render: sessionRender,
  });

  useEffect(() => setId(sessionId || undefined), [sessionId]);

  const parkingName = useMemo(() => {
    const translations = details?.parking?.translation?.find(
      (tr: Translation) => tr.lang === lang,
    );
    return translations?.name;
  }, [details]);

  const eventsList = useMemo(() => details?.events, [details]);

  useEffect(() => {
    if (!!details) {
      const {
        motorist,
        amount,
        occupancyDuration,
        entryDate,
        startDate,
        exitDate,
        product,
        authorizeTransactionId,
      } = details;

      setForm({
        firstName: motorist.firstName || '-',
        lastName: motorist.lastName || '',
        product: product?.name || '-',
        entryDate,
        exitDate,
        parking: parkingName,
        duration: occupancyDuration ? occupancyDuration : startDate,
        amount: amount && authorizeTransactionId ? amount : '-',
      });
    }
  }, [details, parkingName]);

  return (
    <Widget.Group
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
      }}
    >
      {sessionId && details && form ? (
        <Zone
          config={{
            zones: [['profil'], ['segmented'], ['details']],
            rows: ['min-content', 'min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'profil' }}>
            <Widget>
              <ProfilDetails
                handleEvent={{
                  submit: (form) => setForm(form),
                }}
                data={{
                  icon: iconGlobalEntity['session'],
                  form,
                  badges: [
                    {
                      text: t(
                        `global-state-${details.state
                          .replaceAll('_', '-')
                          .toLocaleLowerCase()}`,
                      ),
                      color: details.state.includes('DENIED')
                        ? 'error'
                        : 'success',
                    },
                  ],
                }}
                config={{
                  form: {
                    title1: {
                      name: 'lastName',
                      placeholder: t('lastName'),
                      edit: false,
                    },
                    title2: {
                      name: 'firstName',
                      placeholder: t('firstName'),
                      edit: false,
                    },
                    description1: {
                      name: 'product',
                      placeholder: t('product'),
                      edit: false,
                    },
                    group1: [
                      {
                        name: 'entryDate',
                        label: t('entryDate'),
                        element: {
                          type: 'datetime',
                        },
                        edit: false,
                      },
                      {
                        name: 'parking',
                        label: t('parking'),
                        element: {
                          type: 'input',
                        },
                        edit: false,
                      },
                      {
                        name: 'amount',
                        label: t('amount'),
                        element: {
                          type: 'input',
                        },
                        edit: false,
                      },
                    ],
                    group2: [
                      {
                        name: 'exitDate',
                        label: t('exitDate'),
                        element: { type: 'datetime' },
                        edit: false,
                      },
                      {
                        name: 'duration',
                        label: t('duration'),
                        element: { type: 'input' },
                        render: (data) => DurationTimer(data),
                        edit: false,
                      },
                    ],
                  },
                  actions: (
                    <SessionAction
                      config={{ size: 'xlarge' }}
                      data={{
                        contractId: details?.contractId,
                        motoristId: details?.motorist?._id,
                        parkingId: parkingId,
                        sessionId: details?._id,
                      }}
                    />
                  ),
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'segmented' }}>
            <Widget>
              <Segmented
                handleEvent={{
                  option: (value) => setSegmented(value),
                }}
                data={{ selected: segmented }}
                config={{
                  size: 'large',
                  options: [{ label: t('history'), value: 'history' }],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'details' }}>
            {segmented === 'history' && (
              <Widget>
                <TimelineGroup config={{ height: 'large', width: 'full' }}>
                  {eventsList?.map((event) => ComponentInfosSelector(event))}
                </TimelineGroup>
              </Widget>
            )}
          </Zone.Area>
        </Zone>
      ) : (
        <Empty
          config={{
            mode: { name: 'select', text: `${t('selectSession')} ...` },
          }}
        ></Empty>
      )}
    </Widget.Group>
  );
};
