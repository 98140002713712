import { useTranslation } from '@gimlite/watermelon'
import { DateTime, Duration as LDuration } from 'luxon'
import { useEffect, useMemo, useState } from 'react'

export declare namespace DurationTimerType {
  type Props = {
    data?: {
      value?: DurationTimerType.Data.Value
      defaultValue?: DurationTimerType.Data.DefaultValue
    }
  }
  namespace Data {
    type DefaultValue = string | null
    type Value = string | null
  }
}

export const DurationTimer = (data: string | number) => {
  const [b, setRefresh] = useState(true)
  const { t, lang } = useTranslation()

  useEffect(() => {
    if (typeof data === 'string' && DateTime.fromISO(data).millisecond !== 0) {
      const timer = setTimeout(() => setRefresh(!b), 1000)
      return () => clearTimeout(timer)
    }
  }, [b, data])

  // Check if we still inside and format
  const formatedCounter = useMemo(() => {
    // We have a duration, just show it
    if (typeof data === 'number') {
      return LDuration.fromObject({ seconds: data })
        .shiftTo('days', 'hours', 'minutes', 'seconds')
        .toFormat(t('DURATION_FORMAT_WITH_DAY_LUXON'))
    }
    // startDate is epoch then no startDate
    if (DateTime.fromISO(data).millisecond === 0) {
      return '-'
    }
    return DateTime.now()
      .diff(DateTime.fromISO(data), ['days', 'hours', 'minutes', 'seconds'])
      .toFormat(t('DURATION_FORMAT_WITH_DAY_LUXON'))
  }, [data, b, lang])

  return formatedCounter
}
