import { Space, Write, useTranslation } from '@gimlite/watermelon';
import './switcher-text.component.scss';

export declare namespace SwitcherTextType {
  type Props<T> = {
    handleEvent: {
      mode: (value: T) => void;
    };
    className?: string;
    data: {
      value: T;
    };
    config: {
      mode: [SwitcherTextType.Config.Mode<T>, SwitcherTextType.Config.Mode<T>];
    };
  };

  namespace Config {
    type Mode<T> = {
      label: string;
      value: T;
    };
  }
}

export const SwitcherText = <T,>({
  className = '',
  handleEvent: { mode },
  data: { value },
  config,
}: SwitcherTextType.Props<T>) => {
  const { t } = useTranslation();

  return (
    <div className={`switcher-text ${className}`}>
      <Write
        data={{
          item:
            value === config.mode[0].value
              ? config.mode[0].label
              : config.mode[1].label,
        }}
        config={{ mode: 'label' }}
      ></Write>
      <Space config={{ count: 1, way: 'horizontal' }} />
      <Write
        handleEvent={{
          click: () => {
            mode(
              value === config.mode[0].value
                ? config.mode[1].value
                : config.mode[0].value,
            );
          },
        }}
        data={{ item: t('clickHere') }}
        config={{ mode: 'link', color: 'primary' }}
      ></Write>
    </div>
  );
};
