import { gql } from '@apollo/client';

export const breakdownUpdateGql = gql`
  mutation updateBreakdown($breakdownId: String!, $input: UpdateBreakdownDto!) {
    updateBreakdown(breakdownId: $breakdownId, input: $input) {
      _id
      startDate
      endDate
      category
      description
      type
      createdAt
      updatedAt
    }
  }
`;
