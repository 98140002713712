import { gql } from '@apollo/client';

export const sessionGql = gql`
  query session($sessionId: String!) {
    session(sessionId: $sessionId) {
      _id
      entryDate
      exitDate
      startDate
      endDate
      occupancyDuration
      state
      amount
      currency
      authorizeTransactionId
      contractId

      product {
        _id
        name
        code
      }

      motorist {
        _id
        firstName
        lastName
      }

      parking {
        translation
        accessPoints {
          shortCode
          _id
        }
      }

      events {
        state
        credentials {
          type
          provider
          value
          description
        }
        accessPointId
        createdAt
        reason
        amount
      }
    }
  }
`;
