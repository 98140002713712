import { ActionInlineType } from '@gimlite/osp/components/action-inline/action-inline.component';
import {
  Badge,
  BadgeType,
  Button,
  Color,
  DatePicker,
  DatePickerType,
  Form,
  FormType,
  Icon,
  IconType,
  Input,
  Select,
  SelectType,
  Space,
  Write,
  useTranslation,
} from '@gimlite/watermelon';
import { TextArea } from '@gimlite/watermelon/components/textarea/textarea.component';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Ratio } from '../ratio/ratio.component';
import './profil-details.component.scss';

declare namespace ProfilDetailsCustomEditableType {
  type Props = {
    className?: string;
    handleEvent: {
      change: (
        value: string | null,
        field: ProfilDetailsType.Extra.FormPossibility,
      ) => void;
      blur: (
        value: string | null,
        field: ProfilDetailsType.Extra.FormPossibility,
      ) => void;
    };
    data: {
      currentEdit: boolean;
      currentFormCustom: ProfilDetailsType.Extra.FormStructureCustom;
      fieldName: ProfilDetailsType.Extra.FormPossibility;
      fieldValue?: ProfilDetailsType.Config.Form.CustomItem;
    };
    config?: {
      textOverflow?: boolean;
    };
  };
}

const ProfilDetailsCustomEditable = ({
  className = '',
  handleEvent: { change, blur },
  data: { currentEdit, currentFormCustom, fieldName, fieldValue },
  config: { textOverflow = true } = {},
}: ProfilDetailsCustomEditableType.Props) => {
  const type = useMemo(() => {
    return ['title1', 'title2'].includes(fieldName) ? 'title' : 'description';
  }, [fieldName]);

  return (
    <div
      className={`
      profil-details-custom-editable
      profil-details-custom-editable--type--${type}
      ${!textOverflow ? 'profil-details-custom-editable--textOverflowMask' : ''}
      ${className}
    `}
    >
      {fieldValue &&
        (!currentEdit || (currentEdit && !(fieldValue?.edit !== false))) && (
          <span className="profil-details-custom-editable__text">
            {currentFormCustom[fieldName].value}
          </span>
        )}
      {fieldValue && currentEdit && fieldValue?.edit !== false && (
        <div className="profil-details-custom-editable__input">
          <span
            onBlur={(e) => {
              blur(e.currentTarget.textContent, `${fieldName}`);
            }}
            onInput={(e) => {
              change(e.currentTarget.textContent, `${fieldName}`);
            }}
            role="textbox"
            contentEditable
            suppressContentEditableWarning
            className="profil-details-custom-editable__input__edit"
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          >
            {currentFormCustom[fieldName].value}
          </span>

          <span
            style={{
              visibility: currentFormCustom[fieldName].placeholder
                ? 'visible'
                : 'hidden',
            }}
            className="profil-details-custom-editable__input__placeholder"
          >
            {fieldValue?.placeholder}
          </span>
        </div>
      )}
    </div>
  );
};

export declare namespace ProfilDetailsType {
  type Props = {
    className?: string;
    handleEvent: {
      submit: (form: ProfilDetailsType.Data.Form) => void;
    };
    data: {
      icon: IconType.Config.Type;
      form: ProfilDetailsType.Data.Form;
      badges?: ProfilDetailsType.Data.Badge[];
    };
    config: {
      form: {
        title1: ProfilDetailsType.Config.Form.CustomItem;
        title2?: ProfilDetailsType.Config.Form.CustomItem;
        description1: ProfilDetailsType.Config.Form.CustomItem;
        description2?: ProfilDetailsType.Config.Form.CustomItem;
        group1?: ProfilDetailsType.Config.Form.FormItem[];
        group2?: ProfilDetailsType.Config.Form.FormItem[];
      };
      actions?: React.ReactElement<ActionInlineType.Props>;
      edit?: boolean;
    };
  };

  namespace Data {
    type Badge = { color: BadgeType.Config.Color; text: BadgeType.Config.Text };
    type Form = Record<string, FormType.Data.Value>;
  }

  namespace Config {
    namespace Form {
      type CustomItem = {
        name: string;
        placeholder: string;
        edit?: boolean;
      };

      type FormItem = {
        name: string;
        label: string;
        tooltip?: React.ReactNode;
        render?: (value: FormType.Data.Value) => FormType.Data.Value;
        color?: Extract<Color, 'success' | 'error' | 'warn'>;
        element:
          | { type: 'input' }
          | {
              type: 'date';
              min?: DatePickerType.Config.Min;
              max?: DatePickerType.Config.Max;
            }
          | {
              type: 'datetime';
              min?: DatePickerType.Config.Min;
              max?: DatePickerType.Config.Max;
            }
          | { type: 'textarea' }
          | { type: 'counter' }
          | { type: 'select'; items: SelectType.Data.Item[] };
        placeholder?: string;
        edit?: boolean;
        disabled?: boolean;
      };
    }
  }

  namespace Extra {
    type FormPossibility =
      | 'title1'
      | 'title2'
      | 'description1'
      | 'description2';

    type FormStructureCustom = Record<
      ProfilDetailsType.Extra.FormPossibility,
      { value: FormType.Data.Value; placeholder: boolean }
    >;
  }
}

export const ProfilDetails = ({
  className = '',
  data: { icon, form, badges },
  config: {
    form: {
      title1,
      title2,
      description1,
      description2,
      group1 = [],
      group2 = [],
    },
    actions,
    edit = true,
  },
  handleEvent: { submit },
}: ProfilDetailsType.Props) => {
  const { t } = useTranslation();
  const [currentEdit, setCurrentEdit] = useState<boolean>(false);

  const [currentFormCustom, setCurrentFormCustom] =
    useState<ProfilDetailsType.Extra.FormStructureCustom>({
      title1: { value: form[title1.name], placeholder: false },
      title2: {
        value: title2 ? form[title2?.name] : '',
        placeholder: false,
      },
      description1: {
        value: form[description1?.name],
        placeholder: false,
      },
      description2: {
        value: description2 ? form[description2?.name] : '',
        placeholder: false,
      },
    });

  const [currentForm, setCurrentForm] = useState<FormType.Data.Value>({});

  const activeEdit = useMemo(
    () =>
      ![
        title1?.edit,
        title2?.edit ?? false,
        description1?.edit,
        description2?.edit ?? false,
        ![...group1, ...group2].every(({ edit = true }) => edit === false),
      ].every((rule) => rule === false),
    [title1, title2, description1, description2, group1, group2],
  );

  const [oldCurrentFormCustom, setOldCurrentFormCustom] =
    useState<ProfilDetailsType.Extra.FormStructureCustom>(currentFormCustom);

  const handleContentEditValue = useCallback(
    (value: string | null, key: ProfilDetailsType.Extra.FormPossibility) => {
      setCurrentFormCustom((form) => ({
        ...form,
        [key]: { value, placeholder: form[key].placeholder },
      }));
    },
    [form],
  );

  const handleContentEditPlaceholder = useCallback(
    (value: string | null, key: ProfilDetailsType.Extra.FormPossibility) => {
      setCurrentFormCustom((form) => ({
        ...form,
        [key]: { value: form[key].value, placeholder: !value },
      }));
    },
    [form],
  );

  const preserveFormNoEdit = useMemo(
    () =>
      [...group1, ...group2].reduce((acc: FormType.Data.Value, element) => {
        if (element?.edit === false)
          return { ...acc, [element.name]: form[element.name] };
        return acc;
      }, {}),
    [title1, title2, description1, description2, group1, group2],
  );

  const sendForm = useCallback(() => {
    const formCustomFormatted = {
      [title1.name]: currentFormCustom.title1.value,
      [description1.name]: currentFormCustom.description1.value,
    };

    if (title2?.name)
      formCustomFormatted[title2.name] = currentFormCustom.title2.value;
    if (description2?.name)
      formCustomFormatted[description2.name] =
        currentFormCustom.description2.value;

    submit({
      ...form,
      ...formCustomFormatted,
      ...currentForm,
      ...preserveFormNoEdit,
    });
  }, [currentFormCustom, currentForm, preserveFormNoEdit]);

  useEffect(() => {
    setCurrentFormCustom({
      title1: {
        value: form[title1.name],
        placeholder: title1?.name ? !form[title1.name] : false,
      },
      title2: {
        value: title2 ? form[title2?.name] : '',
        placeholder: title2?.name ? !form[title2.name] : false,
      },
      description1: {
        value: form[description1?.name],
        placeholder: description1?.name ? !form[description1.name] : false,
      },
      description2: {
        value: description2 ? form[description2?.name] : '',
        placeholder: description2?.name ? !form[description2.name] : false,
      },
    });
  }, [form]);

  return (
    <div className={`profil-details ${className}`}>
      <div className="profil-details__top">
        <div className="profil-details__top__identity">
          <Icon
            className="profil-details__top__identity__icon"
            config={{ type: icon, size: 'mega' }}
          />
          <div className="profil-details__top__identity__text">
            <div className="profil-details__top__identity__text__write">
              {!currentEdit &&
              !form[title1.name] &&
              title2 &&
              !form[title2.name] ? (
                <span className="profil-details__top__identity__text__write__unknown">
                  {t('unknown')}
                </span>
              ) : (
                <>
                  <ProfilDetailsCustomEditable
                    data={{
                      currentEdit,
                      currentFormCustom,
                      fieldName: 'title1',
                      fieldValue: title1,
                    }}
                    handleEvent={{
                      blur: handleContentEditValue,
                      change: handleContentEditPlaceholder,
                    }}
                    config={{
                      textOverflow: !(
                        form[title1.name] &&
                        title2 &&
                        form[title2?.name]
                      ),
                    }}
                  />
                  {form[title1.name] && (
                    <Space
                      config={{
                        way: 'horizontal',
                      }}
                    ></Space>
                  )}
                  <ProfilDetailsCustomEditable
                    data={{
                      currentEdit,
                      currentFormCustom,
                      fieldName: 'title2',
                      fieldValue: title2,
                    }}
                    handleEvent={{
                      blur: handleContentEditValue,
                      change: handleContentEditPlaceholder,
                    }}
                    config={{
                      textOverflow: !(
                        form[title1.name] &&
                        title2 &&
                        form[title2?.name]
                      ),
                    }}
                  />
                </>
              )}
            </div>
            <div className="profil-details__top__identity__text__write">
              <ProfilDetailsCustomEditable
                data={{
                  currentEdit,
                  currentFormCustom,
                  fieldName: 'description1',
                  fieldValue: description1,
                }}
                handleEvent={{
                  blur: handleContentEditValue,
                  change: handleContentEditPlaceholder,
                }}
              />
              {((description2 && form[description2.name]) || currentEdit) &&
                !!description1?.name &&
                !!description2?.name && (
                  <div className="profil-details__top__identity__text__write__separa">
                    <span className="profil-details__top__identity__text__write__separa__text">
                      •
                    </span>
                  </div>
                )}
              <ProfilDetailsCustomEditable
                data={{
                  currentEdit,
                  currentFormCustom,
                  fieldName: 'description2',
                  fieldValue: description2,
                }}
                handleEvent={{
                  blur: handleContentEditValue,
                  change: handleContentEditPlaceholder,
                }}
              />
            </div>
            {badges && (
              <div className="profil-details__top__identity__text__badge">
                {badges.map(({ color, text }, index) => (
                  <div
                    key={`badge-${index}`}
                    className="profil-details__top__identity__text__badge__item"
                  >
                    <Badge
                      config={{
                        color,
                        text: text.toUpperCase(),
                        size: 'medium',
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="profil-details__top__action">
          {edit && activeEdit && (
            <div className="profil-details__top__action__button">
              {!currentEdit && (
                <Button
                  className="profil-details__top__action__button__edit"
                  handleEvent={{
                    click: () => {
                      setCurrentEdit(true);
                      setOldCurrentFormCustom(currentFormCustom);
                    },
                  }}
                  config={{ text: t('edit'), size: 'medium' }}
                ></Button>
              )}
              {currentEdit && (
                <>
                  <Button
                    className="profil-details__top__action__button__save"
                    handleEvent={{
                      click: () => {
                        setCurrentEdit(false);
                        sendForm();
                      },
                    }}
                    config={{ text: t('save'), size: 'medium' }}
                  ></Button>
                  <Button
                    className="profil-details__top__action__button__cancel"
                    handleEvent={{
                      click: () => {
                        setCurrentFormCustom(oldCurrentFormCustom);
                        setCurrentForm(form);
                        setCurrentEdit(false);
                      },
                    }}
                    config={{
                      text: t('cancel'),
                      size: 'medium',
                      mode: 'stroke',
                    }}
                  ></Button>
                </>
              )}
            </div>
          )}
          {actions && actions}
        </div>
      </div>
      <Form
        handleEvent={{
          change: (value) => {
            setCurrentForm(value);
          },
        }}
        config={{ element: { width: 'full', height: 'small' } }}
        className="profil-details__bottom"
      >
        {[group1, group2].map((group, index) => (
          <div key={`group-${index}`} className="profil-details__bottom__group">
            {group.map(
              (
                {
                  label,
                  name,
                  color,
                  edit = true,
                  element,
                  tooltip,
                  render,
                  disabled = false,
                },
                index,
              ) => {
                const writeFormatted =
                  element.type === 'select'
                    ? element.items.find(({ value }) => value === form[name])
                        ?.label
                    : form[name];

                const value = render ? render(writeFormatted) : writeFormatted;

                return (
                  <div
                    className="profil-details__bottom__group__item"
                    key={`group-item-${index}`}
                  >
                    {(!edit ||
                      !currentEdit ||
                      (currentEdit && !activeEdit)) && (
                      <Form.Item
                        className="profil-details__bottom__group__item__read"
                        config={{ name, label, tooltip, labelLimitation: true }}
                      >
                        <Write
                          className="profil-details__bottom__group__item__read__text"
                          data={{
                            item: value || '-',
                          }}
                          config={{
                            mode: 'input',
                            color: value ? color : 'disabled',
                          }}
                        />
                      </Form.Item>
                    )}
                    {edit && currentEdit && activeEdit && (
                      <Form.Item
                        className="profil-details__bottom__group__item__edit"
                        data={{ defaultValue: form[name] }}
                        config={{ name, label, tooltip, labelLimitation: true }}
                      >
                        {element.type === 'input' ? (
                          <Input config={{ disabled }} />
                        ) : element.type === 'date' ? (
                          <DatePicker
                            config={{
                              mode: 'single',
                              format: 'date',
                              disabled,
                              min: element?.min || null,
                              max: element?.max || null,
                            }}
                          />
                        ) : element.type === 'datetime' ? (
                          <DatePicker
                            config={{
                              mode: 'single',
                              format: 'datetime',
                              disabled,
                              min: element?.min || null,
                              max: element?.max || null,
                            }}
                          />
                        ) : element.type === 'select' ? (
                          <Select
                            data={{ items: element.items }}
                            config={{ disabled }}
                          />
                        ) : element.type === 'textarea' ? (
                          <TextArea config={{ disabled }} /> //! Form Item label au centre, ca va pas
                        ) : element.type === 'counter' ? (
                          <Ratio value={value} />
                        ) : (
                          <span>Error</span>
                        )}
                      </Form.Item>
                    )}
                  </div>
                );
              },
            )}
          </div>
        ))}
      </Form>
    </div>
  );
};
