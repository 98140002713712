import {
  ActionInline,
  ActionInlineType,
} from '@gimlite/osp/components/action-inline/action-inline.component';
import { requestGQL, useTranslation } from '@gimlite/watermelon';
import { exitLocalMotoristGql } from '../../common/gql/exit-local-motorist.gql';

export declare namespace MotoristActionType {
  type Props = {
    config: {
      size: ActionInlineType.Config.Size;
    };
    activeContracts: boolean;
    localMotoristId?: string | null;
  };
}

export const MotoristAction = ({
  config: { size },
  activeContracts,
  localMotoristId,
}: MotoristActionType.Props) => {
  const { t } = useTranslation();

  return (
    <ActionInline
      config={{ size }}
      data={[
        {
          key: 'exitRegistry',
          text: t('exitRegistry'),
          color: 'error',
          event: (value) =>
            requestGQL({
              params: { localMotoristId },
              gql: exitLocalMotoristGql,
            }),
          isCritical: true,
          show: !activeContracts,
        },
      ]}
    />
  );
};
