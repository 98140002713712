import { Icon } from '@gimlite/watermelon';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import { useMemo } from 'react';
import { TimelineGroup } from '../../../components/timeline/timeline.component';
import './session.infos.scss';
import { Event } from '../../../common/types/entities/event';
import { ComponentInfosSelector } from '../../../common/mapper/eventsInfos.mapper';

export declare namespace SessionInfosType {
  type Props = {
    className?: string;
    data: SessionInfosType.Data;
  };

  type Data = {
    sessionId: string;
    events: Event[];
  };
}

export const SessionInfos = ({
  data: { sessionId, events },
  className = '',
}: SessionInfosType.Props) => {
  const eventListFormated = useMemo(
    () =>
      events.filter(
        ({ state }) => state !== 'CREATED' || state.includes('PENDING'),
      ),
    [events],
  );
  return (
    <div className={`session-infos ${className}`}>
      {sessionId && (
        <Popover
          data={
            <TimelineGroup config={{ height: 'medium', width: 'full' }}>
              {eventListFormated.map((event) => ComponentInfosSelector(event))}
            </TimelineGroup>
          }
          config={{ placement: 'bottomRight', trigger: 'hover' }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="session-infos__pointer"
          >
            <Icon
              config={{
                type: 'faEyeSolid',
                color: 'label',
                size: 'small',
              }}
            ></Icon>
          </div>
        </Popover>
      )}
    </div>
  );
};
