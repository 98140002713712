export const APBModeDetectWithCycleControl = (
  cycleControl:
    | { pastDaysCount: number; maxOpenedSessions: number }
    | {}
    | null,
): 'HARD' | 'SOFT' | 'NONE' => {
  if (!cycleControl || !('pastDaysCount' in cycleControl)) {
    return 'NONE';
  } else if (cycleControl.pastDaysCount === 1) {
    return 'SOFT';
  } else {
    return 'HARD';
  }
};
