import { useEffect, useState } from 'react';
import { Icon } from '../../components/icon/icon.component';
import { Size } from '../../types/component.type';
import './search.component.scss';
import { useTranslation } from '../../hook/useTranslation.hook';

export declare namespace SearchType {
  type Props = {
    className?: string;
    handleEvent?: {
      search?: SearchType.HandleEvent.Search['function'];
      change?: (value: string) => void;
      clear?: () => void;
    };
    config?: {
      debounceTime?: number;
      width?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      height?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      placeholder?: string;
      showResult?: boolean;
    };
  };

  namespace HandleEvent {
    type Search = {
      value1: string;
      return: void;
      function: (
        value: SearchType.HandleEvent.Search['value1'],
      ) => SearchType.HandleEvent.Search['return'];
    };
  }
}

export const Search = ({
  className = '',
  handleEvent: { search, change, clear } = {},
  config: {
    debounceTime = 300,
    placeholder,
    width = 'medium',
    height = 'medium',
  } = {},
}: SearchType.Props) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const { t, lang } = useTranslation();

  useEffect(() => {
    if (query.trim() === '') {
      clear?.();
      setQuery('');
    } else {
      change?.(query);
      const handler = setTimeout(() => {
        setDebouncedQuery(query);
      }, debounceTime);
      return () => clearTimeout(handler);
    }
  }, [query]);

  useEffect(() => {
    if (debouncedQuery) {
      search?.(debouncedQuery);
    } else {
      search?.('');
    }
  }, [debouncedQuery]);

  return (
    <div
      className={`
        search
        formItemTargetWidth--${width}
        search--width--${width}
        search--height--${height}
        ${className}
      `}
    >
      <Icon
        config={{
          type: 'faMagnifyingGlassSolid',
          size: 'small',
          color: 'disabled',
        }}
        className="search__icon"
      />
      <input
        type="text"
        value={query}
        className="search__input"
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        placeholder={placeholder || t('watermelon-globalSearch')}
      />
    </div>
  );
};
