import { ApolloError } from '@apollo/client';
import { ContractMotoristLine } from '@gimlite/osp/composition/line/contract-motorist.line';
import { MotoristLine } from '@gimlite/osp/composition/line/motorist.line';
import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import {
  UseCascadeResult,
  deleteAllWithSameProperties,
  requestGQL,
  useCascade,
} from '@gimlite/watermelon';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateLocalMotorists,
  CreateLocalMotoristsResponse,
  createLocalMotoristGQL,
} from '../../../../common/gql/createLocalMotorist.gql';
import {
  CreateMotorist,
  CreateMotoristResponse,
  createMotoristGQL,
} from '../../../../common/gql/createMotorist.gql';
import {
  CredentialType,
  convertCredentialsFrontToBack,
} from '../../../../common/mapper/credential.mapper';
import {
  SummaryForm,
  SummaryFormType,
} from '../../../../form/summary/summary.form';
import { AddOneMotoristToAnExistingPoolContractWizardContext } from '../../addOneMotoristToAnExistingPoolContract.wizard';

type ResultPosibility = CreateMotoristResponse | CreateLocalMotoristsResponse;

export const SummaryStep = ({
  context: {
    contract,
    motoristMode,
    motorist,
    credentials,
    addFakeQRCODE,
    subPoolExistedSave,
    subPoolContractId,
    product: { id: productId },
  },
  validate,
  submit,
}: WizardItemType.Config.Component<AddOneMotoristToAnExistingPoolContractWizardContext>) => {
  const { t, lang }: any = useTranslation();

  const [created, setCreated] = useState<
    SummaryFormType.Data.Line[] | undefined
  >(undefined);

  const { isFinish } = useCascade<ResultPosibility>([
    ...(motoristMode === 'exist'
      ? [
          async () => {
            try {
              const response = (await requestGQL({
                params: {
                  input: {
                    address1: motorist.exist!.address1,
                    address2: motorist.exist!.address2,
                    city: motorist.exist!.city,
                    country: motorist.exist!.country,
                    firstName: motorist.exist!.firstName,
                    lang: motorist.exist!.lang,
                    lastName: motorist.exist!.lastName,
                    username: motorist.exist!.email,
                    zipcode: motorist.exist!.zipcode
                      ? `${motorist.exist!.zipcode}`
                      : null,
                    contract: {
                      linkedContractId: subPoolContractId,
                      sendInvitation: motorist.create.invitationMotorist,
                      antiPassBackMode: contract.APBMode,
                      description: contract.description,
                      isEnabled: contract.state,
                      startDate: contract.startDate!, //! Les validateurs force la présence
                      stopDate: contract.endDate!, //! Les validateurs force la présence
                      reference: contract.reference,
                      freeFlagIsEnabled:
                        contract.APBMode !== 'NONE' ? !contract.APBNext : null,
                      productId: productId,
                    },
                    localMotoristId: motorist.exist!._id,
                    credentials: convertCredentialsFrontToBack([
                      ...motorist.exist!.uids.map(
                        ({ value, description }) =>
                          ({
                            name: 'RFID',
                            visibleId: value,
                            uid: description,
                          } as CredentialType.CredentialFromFront),
                      ),
                      ...motorist.exist!.plates.map(
                        ({ value, description }) =>
                          ({
                            name: 'PLATE',
                            plate: value,
                            description: description,
                          } as CredentialType.CredentialFromFront),
                      ),
                    ]),
                  },
                } as CreateMotorist,
                gql: createMotoristGQL,
              })) as CreateMotoristResponse;

              setCreated(() => [
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <ContractMotoristLine
                        data={{
                          firstName: response.motorist.firstName,
                          lastName: response.motorist.lastName,
                          contractId: response.contract.ospContractId,
                          status: response.contract.isEnabled
                            ? 'ACTIVE'
                            : 'SUSPENDED',
                        }}
                        config={{ action: false }}
                      />
                    ),
                    state: {
                      type: 'success',
                    },
                  },
                },
              ]);

              return { title: 'CreateMotorist', response };
            } catch (error: unknown) {
              setCreated(() => [
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <ContractMotoristLine
                        data={{
                          firstName: motorist.create.firstName,
                          lastName: motorist.create.lastName,
                          contractId: null,
                          status: contract.state ? 'ACTIVE' : 'SUSPENDED',
                        }}
                        config={{ action: false }}
                      />
                    ),
                    state: {
                      type: 'error',
                      message:
                        error instanceof ApolloError
                          ? `${error.message}`
                          : `${error}`,
                    },
                  },
                },
              ]);

              return {
                title: 'CreateMotorist',
                response: new Error(
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
                ),
              };
            }
          },
        ]
      : [
          async () => {
            try {
              const response = (await requestGQL({
                params: {
                  input: {
                    address1: motorist.create.address1,
                    address2: motorist.create.address2,
                    city: motorist.create.city,
                    country: motorist.create.country,
                    email: motorist.create.username,
                    firstName: motorist.create.firstName,
                    lang: motorist.create.lang,
                    lastName: motorist.create.lastName,
                    sendInvitation: motorist.create.invitationMotorist,
                    zipcode: motorist.create.zipcode,
                    linkedContractId: subPoolContractId,
                    plates: convertCredentialsFrontToBack(
                      credentials.desactived
                        .filter((element) => element.name === 'PLATE')
                        .reduce(
                          (acc, credential) =>
                            deleteAllWithSameProperties(acc, credential),
                          credentials.actived.filter(
                            (element) => element.name === 'PLATE',
                          ),
                        ),
                    ).map(({ provider, value, ...rest }) => ({
                      ...rest,
                      value: value.toUpperCase(),
                    })),
                    uids: convertCredentialsFrontToBack(
                      credentials.desactived
                        .filter((element) => element.name === 'RFID')
                        .reduce(
                          (acc, credential) =>
                            deleteAllWithSameProperties(acc, credential),
                          credentials.actived.filter(
                            (element) => element.name === 'RFID',
                          ),
                        ),
                    ),
                    cards: convertCredentialsFrontToBack(
                      credentials.desactived
                        .filter((element) =>
                          ['TCS', 'SKIDATA'].includes(element.name),
                        )
                        .reduce(
                          (acc, credential) =>
                            deleteAllWithSameProperties(acc, credential),
                          credentials.actived.filter((element) =>
                            ['TCS', 'SKIDATA'].includes(element.name),
                          ),
                        ),
                    ),
                  },
                } as CreateLocalMotorists,
                gql: createLocalMotoristGQL,
              })) as CreateLocalMotoristsResponse;

              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <MotoristLine
                        data={{
                          firstName: response.motorist.firstName,
                          lastName: response.motorist.lastName,
                          status:
                            response.motorist.status === 'VERIFIED'
                              ? 'VERIFIED'
                              : 'UNVERIFIED',
                          email: response.motorist.email,
                        }}
                        config={{ icon: true, action: false }}
                      />
                    ),
                    state: {
                      type: 'success',
                    },
                  },
                },
              ]);

              return { title: 'CreateLocalMotorists', response };
            } catch (error: unknown) {
              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <MotoristLine
                        data={{
                          firstName: motorist.create.firstName,
                          lastName: motorist.create.lastName,
                          status: 'UNVERIFIED',
                          email: motorist.create.username,
                        }}
                        config={{ icon: true, action: false }}
                      />
                    ),
                    state: {
                      type: 'error',
                      message:
                        error instanceof ApolloError
                          ? `${error.message}`
                          : `${error}`,
                    },
                  },
                },
              ]);

              return {
                title: 'CreateLocalMotorists',
                response: new Error(
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
                ),
              };
            }
          },
          async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
            try {
              const response = (await requestGQL({
                params: {
                  input: {
                    address1: motorist.create.address1,
                    address2: motorist.create.address2,
                    city: motorist.create.city,
                    country: motorist.create.country,
                    firstName: motorist.create.firstName,
                    lang: motorist.create.lang,
                    lastName: motorist.create.lastName,
                    username: motorist.create.username,
                    zipcode: motorist.create.zipcode
                      ? `${motorist.create.zipcode}`
                      : null,
                    contract: {
                      linkedContractId: subPoolContractId,
                      sendInvitation: motorist.create.invitationMotorist,
                      antiPassBackMode: contract.APBMode,
                      description: contract.description,
                      isEnabled: contract.state,
                      startDate: contract.startDate!, //! Les validateurs force la présence
                      stopDate: contract.endDate!, //! Les validateurs force la présence
                      reference: contract.reference,
                      freeFlagIsEnabled:
                        contract.APBMode !== 'NONE' ? !contract.APBNext : null,
                      productId: productId,
                    },
                    localMotoristId:
                      prevResult?.[0]?.response &&
                      !(prevResult?.[0]?.response instanceof Error) &&
                      prevResult[0]?.response?.motorist?._id
                        ? prevResult[0]?.response?.motorist?._id
                        : null,
                    credentials: convertCredentialsFrontToBack(
                      credentials.desactived.reduce(
                        (acc, credential) =>
                          deleteAllWithSameProperties(acc, credential),
                        addFakeQRCODE
                          ? credentials.actived.filter(
                              ({ name }) => name !== 'QRCODE',
                            )
                          : credentials.actived,
                      ),
                    ),
                  },
                } as CreateMotorist,
                gql: createMotoristGQL,
              })) as CreateMotoristResponse;

              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <ContractMotoristLine
                        data={{
                          firstName: response.motorist.firstName,
                          lastName: response.motorist.lastName,
                          contractId: response.contract.ospContractId,
                          status: response.contract.isEnabled
                            ? 'ACTIVE'
                            : 'SUSPENDED',
                        }}
                        config={{ action: false }}
                      />
                    ),
                    state: {
                      type: 'success',
                    },
                  },
                },
              ]);

              return { title: 'CreateMotorist', response };
            } catch (error: unknown) {
              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <ContractMotoristLine
                        data={{
                          firstName: motorist.create.firstName,
                          lastName: motorist.create.lastName,
                          contractId: null,
                          status: contract.state ? 'ACTIVE' : 'SUSPENDED',
                        }}
                        config={{ action: false }}
                      />
                    ),
                    state: {
                      type: 'error',
                      message:
                        error instanceof ApolloError
                          ? `${error.message}`
                          : `${error}`,
                    },
                  },
                },
              ]);

              return {
                title: 'CreateMotorist',
                response: new Error(
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
                ),
              };
            }
          },
        ]),
  ]);

  useEffect(() => {
    validate(isFinish);
  }, [isFinish]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <SummaryForm
          data={{
            list: created,
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
