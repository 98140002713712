import {
  Col,
  Empty,
  FilterReduced,
  Identifiable,
  Minute,
  Space,
  useRootStore,
} from '@gimlite/watermelon';
import { SkeletonLine } from '@gimlite/watermelon/components/skeleton/skeleton.component';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { TreeNode, TreeNodeProps } from './tree-node.component';
import './tree.component.scss';

export type TWithParents<T extends Identifiable = Identifiable> = T & {
  parent?: TWithParents<T>;
};

export type TreeProps<T> = {
  nodeRenderer?: (data: T) => {
    component: JSX.Element;
    hasChildren: boolean;
    lastDepth: boolean;
  };
  fetchRootNodes: (...args: any[]) => Promise<T[]>;
  fetchChildren: (id: string, filterQ: object) => Promise<T[]>;
  fetchNodeWParents: (...args: any[]) => Promise<TWithParents>;
  isLeaf: (data: T) => boolean;
  onSelect?: (id?: string) => void;
  initialFocus?: string;
  wsSubscriptions?: string[];
  cacheTtlMs?: number;
  parkingId: string;
  filters?: ReactNode[];
  buildFilterSearch?: (filters: Record<string, string | string[]>) => object;
  onFilter?: () => void;
  clicked?: boolean;
};

export const Tree = <T extends Identifiable>({
  nodeRenderer,
  fetchRootNodes,
  fetchChildren,
  fetchNodeWParents,
  isLeaf,
  parkingId,
  initialFocus,
  wsSubscriptions = [],
  cacheTtlMs = 10 * Minute,
  filters = [],
  buildFilterSearch,
  onFilter,
  clicked,
}: TreeProps<T>) => {
  const { GlobalStore } = useRootStore();
  const [rootNodes, setRootNodes] = useState<TreeNodeProps<T>[]>([]);

  const [searchFilters, setSearchFilters] = useState({});
  const [filterQ, setFilterQ] = useState({});
  const [current, setCurrent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const [nodeRefs, setNodeRefs] = useState<{ [key: string]: HTMLDivElement }>(
    {},
  );
  const registerNodeRef = (id: string, ref: HTMLDivElement) => {
    setNodeRefs((prev) => ({ ...prev, [id]: ref }));
  };

  const loader = useMemo(() => {
    const skeletonFormatted: React.ReactNode[] = [];

    //!Temporaire
    for (let i = 0; i < 12; i++) {
      skeletonFormatted.push(
        <Col config={{ width: 'full' }}>
          <SkeletonLine config={{ size: 'large' }} />
          <Space config={{ count: 1, way: 'vertical' }}></Space>
        </Col>,
      );
    }

    return <div className="tree__loader">{skeletonFormatted}</div>;
  }, []);

  const fetchRoots = (event?: any) => {
    fetchRootNodes(filterQ)
      .then((nodes) => {
        setLoading(() => false);

        if (!nodes.length) {
          setNodeRefs({});
          setRootNodes([]);
          return;
        }

        if (initialFocus) {
          fetchNodeWParents(initialFocus).then((selectedContractWParents) => {
            const ancestorsIds = findAncestorsIds(selectedContractWParents);

            setRootNodes(
              nodes.map((rootNode) => ({
                _id: rootNode._id,
                data: rootNode,
                fetchChildren,
                filterQ,
                depth: 0,
                isLeaf,
                isExpanded: (data) => ancestorsIds.includes(data._id),
                nodeRenderer,
                selectedId: rootNode._id,
                wsSubscriptions,
                cacheTtlMs,
                registerNodeRef,
                ancestors: ancestorsIds,
                clicked,
              })),
            );
          });
        } else {
          setRootNodes(
            nodes.map((rootNode) => ({
              _id: rootNode._id,
              data: rootNode,
              fetchChildren,
              filterQ,
              depth: 0,
              isLeaf,
              isExpanded: (_) => false,
              nodeRenderer,
              selectedId: undefined,
              wsSubscriptions,
              cacheTtlMs,
              registerNodeRef,
              ancestors: event?.contract?._id ? [event.contract._id] : [],
              clicked,
            })),
          );
        }
      })
      .catch(() => setLoading(() => false));
  };

  const treeFilters = useMemo(() => {
    return (
      filters.length > 0 && (
        <FilterReduced
          data={{ value: searchFilters }}
          handleEvent={{
            submit: (value) => {
              if (value) {
                onFilter?.();
                setFilterQ(buildFilterSearch?.(value) || {});
              }
            },
            change: (value) => {
              if (value) {
                setSearchFilters(value);
              }
            },
            clear: () => {
              setSearchFilters({});
              setFilterQ({});
            },
          }}
        >
          {filters}
        </FilterReduced>
      )
    );
  }, [filters, filterQ]);

  useEffect(() => {
    fetchRoots();
  }, [filterQ, parkingId]);

  useEffect(() => {
    setSearchFilters({});
    setFilterQ({});
    setLoading(() => true);

    return () => setLoading(() => true);
  }, [parkingId]);

  useEffect(() => {
    if (initialFocus && initialFocus !== current && !clicked) {
      setCurrent(initialFocus);
      fetchRoots();
    }
  }, [parkingId, initialFocus, clicked]);

  useEffect(() => {
    if (initialFocus && nodeRefs[initialFocus] && !clicked) {
      nodeRefs[initialFocus].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }, [initialFocus, nodeRefs, parkingId]);

  useEffect(() => {
    if (GlobalStore.socket) {
      wsSubscriptions?.forEach((subscription) => {
        GlobalStore.socket?.on(subscription, onUpdate);
      });

      return () => {
        wsSubscriptions?.forEach((subscription) => {
          GlobalStore.socket?.off(subscription, onUpdate);
        });
      };
    }
  }, [JSON.stringify(wsSubscriptions), GlobalStore.socket, parkingId]);

  function onUpdate(event: any) {
    fetchRoots(event);
  }

  const nodesList = () => {
    if (rootNodes.length === 0)
      return <Empty config={{ mode: { name: 'noData' } }}></Empty>;

    return rootNodes.map((rootNode, index) => (
      <div key={rootNode._id + index} className="tree__item">
        <TreeNode<T>
          key={rootNode._id}
          {...rootNode}
          selectedId={initialFocus}
        />
      </div>
    ));
  };

  return (
    <>
      {filters && filters.length > 0 && (
        <div className="tree-filter">{treeFilters}</div>
      )}
      <div className="tree">{loading ? loader : nodesList()}</div>
    </>
  );
};

export function findAncestorsIds(ancestry: TWithParents) {
  let t = ancestry;
  let ancestorsIds: string[] = [t._id];
  while (t.parent) {
    if (t.parent) ancestorsIds = [...ancestorsIds, t.parent._id];
    t = t.parent;
  }

  return ancestorsIds;
}
