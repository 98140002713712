import packageJSON from '../package.json';

const config = {
  VERSION_FRONT: packageJSON?.version,
  ENV: process.env.REACT_APP_ENV || 'LOCAL',
  BFF_HOST:
    process.env.REACT_APP_HOST_BFF_OSP_OPERATORS || 'http://localhost:3103',
  BFF_WS_HOST: process.env.REACT_APP_HOST_BFF_EVENTS || 'ws://localhost:3333',
  BFF_TOKEN:
    process.env.REACT_APP_BFF_TOKEN ||
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBLZXkiOiIxIiwiYXBwTmFtZSI6ImJmZi1vcGVyYXRvciIsImFwcFZlcnNpb24iOiJvcGVyYXRvci12MSIsImlhdCI6MTY0NDgzMTE4MH0.CpD_7IdXEJA4wZbTiFC8FI_PSW4o3poGMAHFx29Rwyc',
  REACT_APP_HOST_BFF_OSP_MOTORISTS:
    process.env.REACT_APP_HOST_BFF_OSP_MOTORISTS || 'http://localhost:3101',
  BFF_OSP_MOTORIST_TOKEN:
    process.env.REACT_APP_BFF_MOTORIST_TOKEN ||
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBLZXkiOiIxIiwiYXBwTmFtZSI6ImZyb250LW1vdG9yaXN0cyIsImFwcFZlcnNpb24iOiJ2MSIsImlhdCI6MTY1NDg2Mzg4NX0.kb7b4DPauPBQL4x2sllAQTa7iCJvkyDyLwj0YikFAwk',
  FRONT_OSP_MOTORISTS_HOST:
    process.env.REACT_APP_HOST_FRONT_OSP_MOTORISTS ||
    'https://front-motorists.cleverapps.io/',
};
export default config;
