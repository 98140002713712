import { Row, Space, Write, Zone, useTranslation } from '@gimlite/watermelon';
import {
  Description,
  DescriptionType,
} from '@gimlite/watermelon/components/description/description.component';
import './popup-info.component.scss';

export declare namespace PopupInfoType {
  type Props = {
    data: {
      node: React.ReactNode;
    };
    config: {
      title: string;
      description?: DescriptionType.Data | DescriptionType.Data[];
    };
  };

  namespace Config {
    type Mode = {
      label: string;
      value: string;
    };
  }
}

export const PopupInfo = ({
  config: { title, description },
  data: { node },
}: PopupInfoType.Props) => {
  const { t } = useTranslation();

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 0,
        },
        zones: [
          ['title', 'description'],
          ['node', 'node'],
        ],
        rows: ['min-content', '1fr'],
        columns: ['1fr', 'min-content'],
      }}
    >
      <Zone.Area config={{ area: 'title' }}>
        <Write data={{ item: title }} config={{ mode: 'namise' }} />
      </Zone.Area>

      <Zone.Area config={{ area: 'description' }}>
        <Row config={{ wrap: false }}>
          {description && (
            <Description
              className="popup-info-description"
              data={Array.isArray(description) ? description : [description]}
              config={{
                size: 'small',
                way: 'horizontal',
              }}
            />
          )}
        </Row>
      </Zone.Area>

      <Zone.Area config={{ area: 'node' }}>
        <div className="popup-info-node">{node}</div>
      </Zone.Area>
    </Zone>
  );
};
