import { GlobalStoreMobX } from './global.store';

export class RootStoreMobX {
  GlobalStore: GlobalStoreMobX;

  constructor() {
    this.GlobalStore = new GlobalStoreMobX(this);
    this.GlobalStore.init();
  }
}
