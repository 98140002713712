import { gql } from '@apollo/client';

export const addCredGql = gql`
  mutation addCredOnContract(
    $motoristId: String!
    $contractId: String!
    $type: String!
    $description: String
    $plate: String
    $uid: String
    $visibleId: String
    $externalId: String
    $name: String
  ) {
    addCredOnContract(
      motoristId: $motoristId
      contractId: $contractId
      type: $type
      description: $description
      plate: $plate
      uid: $uid
      visibleId: $visibleId
      externalId: $externalId
      name: $name
    ) {
      _id
    }
  }
`;
