import { gql } from '@apollo/client';
import { PoolUser } from '../types/entities/pool-user';

export type VerifyAndUpdateUserResponse = {
  user: PoolUser;
};

export type VerifyAndUpdateUser = {
  input: {
    token: string;
    user: {
      username?: string;
      firstName?: string;
      lastName?: string;
      password?: string;
      phone?: string;
      lang?: string;
    };
  };
};

export const verifyAndUpdateUserGQL = gql`
  mutation verifyAndUpdateUser($input: SetVerifyPoolDto!) {
    verifyAndUpdateUser(input: $input) {
      user {
        _id
        username
        firstName
        lastName
        phone
        accountId
        lang
        operatorContracts
      }
    }
  }
`;
