import { dispatch } from '@gimlite/router';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { DefaultMotoristWizard } from '../../common/defaultValue/wizard';
import { GlobalSearchContractEntityResponse } from '../../common/gql/globalSearch.gql';
import { CredentialType } from '../../common/mapper/credential.mapper';
import { MasterContractStep } from '../../common/steps/masterContract/masterContract.step';
import { SubContractStep } from '../../common/steps/subContract/subContract.step';
import { LocalMotorist } from '../../common/types/entities/localMotorist';
import { ContractCreateFormType } from '../../form/contract-create/contract-create.form';
import { MotoristCreateFormType } from '../../form/motorist-create/motorist-create.form';
import {
  AddOneSeveralMotoristToAnExistingPoolContractServiceContextType,
  addOneMotoristToAnExistingPoolContractService,
} from './addOneMotoristToAnExistingPoolContract.machine';
import { ConfirmationStep } from './steps/confirmation/confirmation.step';
import { ContractStep } from './steps/contract/contract.step';
import { MotoristStep } from './steps/motorist/motorist.step';
import { SummaryStep } from './steps/summary/summary.step';
import { ConfigProps } from '../../config';

export type AddOneMotoristToAnExistingPoolContractWizardContext = {
  parkingId: string | null;
  product: {
    id: string | null;
    credentials: CredentialType.GlobalName[];
  };
  masterPoolContractId: string | null;
  subPoolContractId: string | null;
  parentContract: ContractCreateFormType.Data.ContractPool | null;
  contract: ContractCreateFormType.Data.ContractMotorist;
  credentials: MotoristCreateFormType.Data.Credentials;
  motorist: {
    create: MotoristCreateFormType.Data.Motorist;
    exist: LocalMotorist | null;
  };
  addFakeQRCODE: boolean;
  selectExistMotorist: string | null;
  motoristMode: 'create' | 'exist';
  motoristExistedSave: LocalMotorist[];
  subPoolExistedSave: GlobalSearchContractEntityResponse[];
  masterPoolExistedSave: GlobalSearchContractEntityResponse[];
  machine: any;
  mode: ConfigProps['mode'];
};

export const AddOneMotoristToAnExistingPoolContractWizard = () => {
  const { t, lang } = useTranslation();

  const { parkingId, skipSubContract, mode } = useSelector(
    addOneMotoristToAnExistingPoolContractService,
    ({
      context,
    }: {
      context: AddOneSeveralMotoristToAnExistingPoolContractServiceContextType;
    }) => context,
  );

  return (
    <Wizard<AddOneMotoristToAnExistingPoolContractWizardContext>
      config={{
        title: t(`addOneMotoristToAnExistingPoolContract`),
      }}
      data={{
        defaultContext: {
          parkingId,
          product: {
            id: null,
            credentials: [],
          },
          masterPoolContractId: null,
          subPoolContractId: null,
          parentContract: null,
          contract: {
            startDate: new Date().toISOString(),
            endDate: null,
            APBMode: 'HARD',
            APBNext: false,
            reference: null,
            description: null,
            state: true,
          },
          addFakeQRCODE: true,
          credentials: {
            actived: [],
            desactived: [],
            context: 'wizardNewMotorist',
          },
          motorist: {
            create: DefaultMotoristWizard({ t, lang }),
            exist: null,
          },
          selectExistMotorist: null,
          motoristMode: 'exist',
          motoristExistedSave: [],
          subPoolExistedSave: [],
          masterPoolExistedSave: [],
          machine: addOneMotoristToAnExistingPoolContractService,
          mode,
        },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          dispatch('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item
        config={{ title: t('masterContract'), component: MasterContractStep }}
      />
      {!skipSubContract && (
        <Wizard.Item
          config={{ title: t('subContract'), component: SubContractStep }}
        />
      )}
      <Wizard.Item config={{ title: t('contract'), component: ContractStep }} />
      <Wizard.Item config={{ title: t('motorist'), component: MotoristStep }} />
      <Wizard.Item
        config={{ title: t('confirmation'), component: ConfirmationStep }}
      />
      <Wizard.Item config={{ title: t('summary'), component: SummaryStep }} />
    </Wizard>
  );
};
