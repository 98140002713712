import { ContractSubPoolLine } from '../../../composition/line/contract-sub-pool.line';
import { AddOneMotoristToAnExistingPoolContractWizardContext } from '../../../wizards/addOneMotoristToAnExistingPoolContract/addOneMotoristToAnExistingPoolContract.wizard';
import { AddSeveralMotoristsToAnExistingPoolContractWizardContext } from '../../../wizards/addSeveralMotoristsToAnExistingPoolContract/addSeveralMotoristsToAnExistingPoolContract.wizard';
import { requestGQL, useSearchOf } from '@gimlite/watermelon';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  SingleCheckForm,
  SingleCheckFormType,
} from '../../../form/single-check/single-check.form';
import {
  GlobalSearchContractEntityResponse,
  GlobalSearchResponse,
  globalSearchGQL,
} from '../../gql/globalSearch.gql';
import {
  PoolContractAndSubResponse,
  poolContractAndSubGQL,
} from '../../gql/poolContractAndSub.gql';
import { ProductResponse, productGQL } from '../../gql/product.gql';
import { subContractsGql } from '../../gql/subContracts.gql';
import { convertCredentialsBackToFrontName } from '../../mapper/credential.mapper';
import { APBModeDetectWithCycleControl } from '../../mapper/product.mapper';

export const SubContractStep = ({
  context: {
    subPoolContractId,
    subPoolExistedSave,
    masterPoolContractId,
    contract,
  },
  validate,
  submit,
}: WizardItemType.Config.Component<
  | AddSeveralMotoristsToAnExistingPoolContractWizardContext
  | AddOneMotoristToAnExistingPoolContractWizardContext
>) => {
  const params = { order: 'name1', linkedContractId: masterPoolContractId };
  const pageLimit = 50;

  const { t, lang } = useTranslation();

  const [searchSubPoolContractResult, setSearchSubPoolContractResult] =
    useState<GlobalSearchContractEntityResponse[] | null>(null);
  const [selectSubPoolId, setSelectSubPoolId] = useState<string>();

  const { list, paging, setSearch } = useSearchOf<
    GlobalSearchContractEntityResponse,
    { page: number; limit: number }
  >({
    gql: subContractsGql,
    wsSubscriptions: [],
  });

  const subContractFormattedLine = useCallback(
    (contracts: GlobalSearchContractEntityResponse[]) => {
      return contracts.map(
        ({
          _id,
          name1,
          name2,
          isEnabled,
          ospContractId,
          spacesAllocated,
          contractCount,
          spacesOccupied,
          reference,
        }) => ({
          _id,
          items: {
            component: (
              <ContractSubPoolLine
                config={{
                  action: false,
                }}
                data={{
                  code: ospContractId,
                  name1: name1!,
                  name2,
                  status: isEnabled ? 'ACTIVE' : 'SUSPENDED',
                  carCounter: [spacesOccupied || 0, spacesAllocated!],
                  classification: reference,
                  contractCounter: contractCount,
                }}
              />
            ),
          },
        }),
      );
    },
    [],
  );

  const handleSearch = useCallback(
    async (value: string) => {
      if (value && value.length >= 3) {
        const { contracts: contractsNotFormatted } = (await requestGQL({
          params: { content: value, linkedContractId: masterPoolContractId },
          gql: globalSearchGQL,
        })) as GlobalSearchResponse;

        const subPoolContract = contractsNotFormatted.filter(
          (element) => element.category === 'TENANT_POOL',
        );

        setSearchSubPoolContractResult(() => subPoolContract);

        submit({
          subPoolExistedSave: [
            ...subPoolExistedSave,
            ...subPoolContract,
          ].reduce(
            (acc: GlobalSearchContractEntityResponse[], { _id, ...rest }) => {
              if (!acc.find((element) => element._id === _id)) {
                return [...acc, { _id, ...rest }];
              }
              return acc;
            },
            [],
          ),
        });

        setSearch({
          paging: { page: 1, limit: pageLimit },
          params,
        });
      } else {
        setSearchSubPoolContractResult(() => null);
      }
    },
    [searchSubPoolContractResult, masterPoolContractId],
  );

  const { fresh, paging: pagingFormatted } = useMemo((): {
    fresh: GlobalSearchContractEntityResponse[] | undefined;
    paging?: SingleCheckFormType.Props['data']['paging'];
  } => {
    if (!list) {
      return { fresh: undefined };
    } else {
      const listTarget = !searchSubPoolContractResult
        ? list
        : searchSubPoolContractResult;

      return {
        fresh: listTarget,
        paging: !searchSubPoolContractResult
          ? paging
          : {
              count: searchSubPoolContractResult.length,
              current: 1,
              limit: searchSubPoolContractResult.length,
            },
      };
    }
  }, [list, paging, searchSubPoolContractResult]);

  useEffect(() => {
    if (subPoolContractId && subPoolContractId !== selectSubPoolId) {
      setSelectSubPoolId(() => subPoolContractId);
      (async () => {
        const currentContract = (await requestGQL({
          params: {
            contractId: subPoolContractId,
          },
          gql: poolContractAndSubGQL,
        })) as PoolContractAndSubResponse;

        const { rules } = (await requestGQL({
          params: {
            productId: currentContract.productId,
          },
          gql: productGQL,
        })) as ProductResponse;

        submit({
          product: {
            id: currentContract.productId,
            credentials: rules?.credentialsTypes?.types
              ? rules.credentialsTypes.types.map((item) =>
                  convertCredentialsBackToFrontName(item),
                )
              : [],
          },
          parentContract: {
            ...currentContract,
            startDate: currentContract.startDate,
            endDate: currentContract.stopDate,
            APBMode: APBModeDetectWithCycleControl(
              currentContract?.rules?.cycleControl || null,
            ),
            APBNext: null,
            state: currentContract.isEnabled,
          },
          contract: {
            ...contract,
            startDate: currentContract.startDate,
            endDate: currentContract.stopDate,
            APBMode: APBModeDetectWithCycleControl(
              currentContract?.rules?.cycleControl || null,
            ),
          },
        });
      })();
    }
  }, [fresh, contract, subPoolContractId, subPoolExistedSave]);

  useEffect(() => {
    const newList = subPoolExistedSave;
    if (list) {
      list.forEach(({ _id, ...rest }) => {
        if (
          subPoolExistedSave.findIndex((element) => element._id === _id) === -1
        ) {
          newList.push({ _id, ...rest });
        }
      });
    }

    submit({
      subPoolExistedSave: newList,
    });
  }, [list, subPoolExistedSave]);

  useEffect(() => {
    setSearch({
      paging: { page: 1, limit: pageLimit },
      params,
    });
  }, []);

  useEffect(() => {
    validate(!!subPoolContractId);
  }, [subPoolContractId]);

  return (
    <Zone
      config={{
        gap: {
          y: 0,
          x: 0,
        },
        zones: [['form']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'form' }}>
        <Widget
          config={{
            title: t('selectASubPoolContract'),
            shadow: false,
          }}
        >
          <SingleCheckForm
            handleEvent={{
              paging: (data) => {
                setSearch({
                  paging: { page: data.page, limit: data.limit },
                  params,
                });
              },
              checkItem: (id) => {
                submit({ subPoolContractId: id });
              },
              search: handleSearch,
            }}
            data={{
              check: subPoolContractId,
              fresh: fresh ? subContractFormattedLine(fresh) : fresh,
              saved: subContractFormattedLine(subPoolExistedSave),
              paging: pagingFormatted,
            }}
            config={{
              searchPlaceholder: t('searchASubPoolContract'),
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
