import { ApolloError } from '@apollo/client';
import { ContractMotoristLine } from '@gimlite/osp/composition/line/contract-motorist.line';
import { MotoristLine } from '@gimlite/osp/composition/line/motorist.line';
import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import {
  deleteAllWithSameProperties,
  requestGQL,
  useCascade,
} from '@gimlite/watermelon';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateLocalMotorists,
  CreateLocalMotoristsResponse,
  createLocalMotoristGQL,
} from '../../../../common/gql/createLocalMotorist.gql';
import {
  CreateMotorist,
  CreateMotoristResponse,
  createMotoristGQL,
} from '../../../../common/gql/createMotorist.gql';
import { convertCredentialsFrontToBack } from '../../../../common/mapper/credential.mapper';
import {
  SummaryForm,
  SummaryFormType,
} from '../../../../form/summary/summary.form';
import { CreateNewSingleContractWizardContext } from '../../createNewSingleContract.wizard';
type ResultPosibility = CreateMotoristResponse | CreateLocalMotoristsResponse;

export const SummaryStep = ({
  context: {
    product,
    contract,
    motorist,
    motoristOrigin,
    credentials,
    addFakeQRCODE,
    motoristSearchSelect,
  },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewSingleContractWizardContext>) => {
  const { t, lang } = useTranslation();

  const [created, setCreated] = useState<SummaryFormType.Data.Line[]>();

  const { isFinish } = useCascade<ResultPosibility>([
    ...(!motoristSearchSelect
      ? [
          async () => {
            try {
              const response = (await requestGQL({
                params: {
                  input: {
                    address1: motorist.create.address1,
                    address2: motorist.create.address2,
                    city: motorist.create.city,
                    country: motorist.create.country,
                    email: motorist.create.username,
                    firstName: motorist.create.firstName,
                    lang: motorist.create.lang,
                    lastName: motorist.create.lastName,
                    sendInvitation: motorist.create.invitationMotorist,
                    zipcode: motorist.create.zipcode
                      ? `${motorist.create.zipcode}`
                      : null,
                    plates: convertCredentialsFrontToBack(
                      credentials.desactived
                        .filter((element) => element.name === 'PLATE')
                        .reduce(
                          (acc, credential) =>
                            deleteAllWithSameProperties(acc, credential),
                          credentials.actived.filter(
                            (element) => element.name === 'PLATE',
                          ),
                        ),
                    ).map(({ provider, value, ...rest }) => ({
                      ...rest,
                      value: value.toUpperCase(),
                    })),
                    uids: convertCredentialsFrontToBack(
                      credentials.desactived
                        .filter((element) => element.name === 'RFID')
                        .reduce(
                          (acc, credential) =>
                            deleteAllWithSameProperties(acc, credential),
                          credentials.actived.filter(
                            (element) => element.name === 'RFID',
                          ),
                        ),
                    ),
                    cards: convertCredentialsFrontToBack(
                      credentials.desactived
                        .filter((element) =>
                          ['TCS', 'SKIDATA'].includes(element.name),
                        )
                        .reduce(
                          (acc, credential) =>
                            deleteAllWithSameProperties(acc, credential),
                          credentials.actived.filter((element) =>
                            ['TCS', 'SKIDATA'].includes(element.name),
                          ),
                        ),
                    ),
                  },
                } as CreateLocalMotorists,
                gql: createLocalMotoristGQL,
              })) as CreateLocalMotoristsResponse;

              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <MotoristLine
                        data={{
                          firstName: response.motorist.firstName,
                          lastName: response.motorist.lastName,
                          email: response.motorist.email,
                          status:
                            response.motorist.status === 'VERIFIED'
                              ? 'VERIFIED'
                              : 'UNVERIFIED',
                        }}
                        config={{ action: false, icon: true }}
                      />
                    ),
                    state: {
                      type: 'success',
                    },
                  },
                },
              ]);

              return { title: 'CreateLocalMotorists', response: response };
            } catch (error: unknown) {
              setCreated((list) => [
                ...(list ? list : []),
                {
                  _id: uuidv4(),
                  items: {
                    component: (
                      <MotoristLine
                        data={{
                          firstName: motorist.create.firstName,
                          lastName: motorist.create.lastName,
                          email: motorist.create.username,
                          status: 'UNVERIFIED',
                        }}
                        config={{ action: false, icon: true }}
                      />
                    ),
                    state: {
                      type: 'error',
                      message:
                        error instanceof ApolloError
                          ? `${error.message}`
                          : `${error}`,
                    },
                  },
                },
              ]);

              return {
                title: 'CreateLocalMotorists',
                response: new Error(
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
                ),
              };
            }
          },
        ]
      : []),
    async (prevResult) => {
      try {
        const response = (await requestGQL({
          params: {
            input: {
              address1: motorist.create.address1,
              address2: motorist.create.address2,
              city: motorist.create.city,
              country: motorist.create.country,
              firstName: motorist.create.firstName,
              lang: motorist.create.lang,
              lastName: motorist.create.lastName,
              username: motorist.create.username,
              zipcode: motorist.create.zipcode
                ? `${motorist.create.zipcode}`
                : null,
              contract: {
                linkedContractId: null,
                sendInvitation: motorist.create.invitationMotorist,
                antiPassBackMode: contract.APBMode!,
                description: contract.description,
                isEnabled: contract.state,
                startDate: contract.startDate!, //! Les validateurs force la présence
                stopDate: contract.endDate!, //! Les validateurs force la présence
                reference: contract.reference,
                freeFlagIsEnabled:
                  contract.APBMode !== 'NONE' ? !contract.APBNext : null,
                productId: product.id!, //! Les validateurs force la présence
              },
              localMotoristId:
                motoristOrigin.origin === 'LOCAL_MOTORIST'
                  ? motoristOrigin._id
                  : prevResult?.[0] &&
                    !(prevResult?.[0]?.response instanceof Error) &&
                    prevResult[0]?.response?.motorist?._id
                  ? prevResult[0]?.response?.motorist?._id
                  : null,
              credentials: convertCredentialsFrontToBack(
                credentials.desactived.reduce(
                  (acc, credential) =>
                    deleteAllWithSameProperties(acc, credential),
                  addFakeQRCODE
                    ? credentials.actived.filter(
                        ({ name }) => name !== 'QRCODE',
                      )
                    : credentials.actived,
                ),
              ),
            },
          } as CreateMotorist,
          gql: createMotoristGQL,
        })) as CreateMotoristResponse;

        setCreated((list) => [
          ...(list ? list : []),
          {
            _id: uuidv4(),
            items: {
              component: (
                <ContractMotoristLine
                  data={{
                    firstName: response.motorist.firstName,
                    lastName: response.motorist.lastName,
                    contractId: response.contract.ospContractId,
                    status: response.contract.isEnabled
                      ? 'ACTIVE'
                      : 'SUSPENDED',
                  }}
                  config={{ action: false }}
                />
              ),
              state: {
                type: 'success',
              },
            },
          },
        ]);

        return { title: 'CreateMotorist', response };
      } catch (error: unknown) {
        setCreated((list) => [
          ...(list ? list : []),
          {
            _id: uuidv4(),
            items: {
              component: (
                <ContractMotoristLine
                  data={{
                    firstName: motorist.create.firstName,
                    lastName: motorist.create.lastName,
                    contractId: null,
                    status: contract.state ? 'ACTIVE' : 'SUSPENDED',
                  }}
                  config={{ action: false }}
                />
              ),
              state: {
                type: 'error',
                message:
                  error instanceof ApolloError
                    ? `${error.message}`
                    : `${error}`,
              },
            },
          },
        ]);

        return {
          title: 'CreateMotorist',
          response: new Error(
            error instanceof ApolloError ? `${error.message}` : `${error}`,
          ),
        };
      }
    },
  ]);

  useEffect(() => {
    validate(isFinish);
  }, [isFinish]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            productName: product.id ? t(`product-${product.id}-name`) : null,
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <SummaryForm
          data={{
            list: created,
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
