import { gql } from "@apollo/client";

export const editCredGql = gql`
    mutation editCredOnContract(
        $contractId: String!
        $type: String!
        $description: String
        $plate: String
        $uid: String
        $visibleId: String
        $oldValue: OldCredValue
    ) {
    
        editCredOnContract(
            contractId: $contractId,
            type: $type,
            description: $description,
            plate: $plate,
            uid: $uid,
            visibleId: $visibleId,
            oldValue: $oldValue
        ) {
            credentials {
            type
            provider
            value
            description
            }
        }
    
    }
`