import { gql } from '@apollo/client';

export type PoolContractAndSubResponse = {
  _id: string;
  name1: string;
  name2: string | null;
  isEnabled: boolean;
  ospContractId: string;
  spacesAllocated: number;
  spacesOccupied: number;
  contractCount: number;
  productId: string;
  startDate: string;
  stopDate: string | null;
  reference: string | null;
  description: string | null;
  tenantContracts: Array<{ _id: string }>;
  rules: {
    cycleControl: {
      pastDaysCount: number;
      maxOpenedSessions: number;
    };
  } | null;
};

export const poolContractAndSubGQL = gql`
  query poolContractAndSub($contractId: String!) {
    poolContractAndSub(contractId: $contractId) {
      _id
      name1
      name2
      isEnabled
      ospContractId
      spacesAllocated
      spacesOccupied
      contractCount
      productId
      startDate
      stopDate
      reference
      description
      tenantContracts {
        _id
      }
      rules
    }
  }
`;
