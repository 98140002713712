import { gql } from '@apollo/client';

export const productsGql = gql`
  query products(
    $parkingId: String!
    $productPage: Int
    $productLimit: Int
    $order: String
    $category: ProductCategoryEnum
  ) {
    products(
      page: $productPage
      limit: $productLimit
      order: $order
      parkingId: $parkingId
      category: $category
    ) {
      list {
        _id
        name
        category
        parameters {
          durationValue
          durationUnit
          cents
          currency
          contractTag
        }
        rules {
          cycleControl {
            pastDaysCount
            maxOpenedSessions
          }
          credentialsTypes {
            types {
              type
              provider
            }
          }
        }
        externalPayload {
          ArticleCode
        }
        createdAt
      }

      paging {
        current
        count
        limit
      }
    }
  }
`;
