import { dispatch } from '@gimlite/router';
import {
  Wizard,
  WizardItemType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import {
  ChoiceCreateContractServiceContextType,
  choiceCreateContractService,
} from './choiceCreateContract.machine';
import { ActionStep } from './steps/action/action.step';

export type ChoiceCreateContractWizardContext = {
  parkingId: string | null;
  actions: {
    createNewSingleContract: boolean;
    createNewMasterPoolContract: boolean;
    createNewSubPoolContract: boolean;
    addOneMotoristToAnExistingPoolContract: boolean;
    addSeveralMotoristsToAnExistingPoolContract: boolean;
  };
  mode: 'tenant' | 'operator';
};

export const ChoiceCreateContractWizard = () => {
  const { t } = useTranslation();

  const { parkingId, actions, mode } = useSelector(
    choiceCreateContractService,
    ({ context }: { context: ChoiceCreateContractServiceContextType }) =>
      context,
  );

  return (
    <Wizard<ChoiceCreateContractWizardContext>
      config={{
        title: t(`theDifferentActionsLinkedToContracts`),
        withNavigation: false,
      }}
      data={{
        defaultContext: { parkingId, actions, mode },
      }}
      handleEvent={{
        done: async (data) => {
          console.log('done', { data });
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          dispatch('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      <Wizard.Item
        config={{
          title: t('action'),
          component: ActionStep,
        }}
      />
    </Wizard>
  );
};
