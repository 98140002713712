import { ListFlatInfo } from '@gimlite/watermelon/components/list/list.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Contract } from '../../common/types/entities/contract';
import { PoolUser } from '../../common/types/entities/pool-user';
import { PoolAdminLine } from '../line/pool-admin.line';

export type PoolAdminListProps = {
  contract: Contract;
  widget?: {
    title?: string;
    icon?: string;
  };
};

function renderPoolUser({ _id, firstName, lastName, username }: PoolUser) {
  return {
    _id,
    items: {
      component: (
        <PoolAdminLine
          data={{
            firstName,
            lastName,
            email: username,
          }}
        />
      ),
    },
  };
}

export const PoolAdminList = ({ widget, contract }: PoolAdminListProps) => {
  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
    >
      <ListFlatInfo<any>
        data={{
          list: contract.poolUsers?.map(renderPoolUser) || [],
          paging: {
            count: 20,
            current: 1,
            limit: 20,
          },
        }}
        config={{
          pagination: 'scroll',
          columns: [{ key: 'component', size: '1fr' }],
        }}
      />
    </Widget>
  );
};
