import {
  BreakdownsDetails,
  BreakdownList,
  iconGlobalEntity,
} from '@gimlite/osp';
import { RouterType, useNavigate } from '@gimlite/router';
import {
  useMyUrl,
  useTranslation,
  Page,
  Zone,
  
} from '@gimlite/watermelon';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const BreakdownsPage = ({ machine }: RouterType.Legacy) => {
  const navigate = useNavigate();
  const { getParamsUrlString } = useMyUrl({});
  const { t } = useTranslation();
  const { parkingId, breakdownId } = useParams();
  const [selected, setSelected] = useState<string | null>(breakdownId || null);

  useEffect(() => {
    if (selected) {
      navigate(`/${parkingId}/breakdowns/${selected}?${getParamsUrlString}`);
    }
  }, [selected]);

  useEffect(() => {
    setSelected(null);
  }, [parkingId]);

  return (
    parkingId && (
      <Page>
        <Zone
          config={{
            zones: [['list', 'details']],
            rows: ['1fr'],
            columns: ['1fr', '1fr'],
          }}
        >
          <Zone.Area
            config={{
              area: 'list',
            }}
          >
            <BreakdownList
              widget={{
                title: t('listOfDeviceFailures'),
                icon: iconGlobalEntity['breakdown'],
              }}
              parkingId={parkingId}
              setSelectedBreakdown={setSelected}
            />
          </Zone.Area>

          <Zone.Area
            config={{
              area: 'details',
            }}
          >
            <BreakdownsDetails
              widget={{ title: t('detailsOfDeviceFailure') }}
              breakdownId={selected}
            />
          </Zone.Area>
        </Zone>
      </Page>
    )
  );
};
