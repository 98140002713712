import {
  ActionInline,
  ActionInlineType,
} from '@gimlite/osp/components/action-inline/action-inline.component';
import { requestGQL, useTranslation } from '@gimlite/watermelon';
import { stopContractGql } from '../../common/gql/stop-contract.gql';
import { updateContractGql } from '../../common/gql/update-contract.gql';

export type InlineActionChangeEvent = {
  key: string;
  type: string;
  value?: boolean;
};

export declare namespace PoolContractActionType {
  type Props = {
    initialState?: Partial<PoolContractActionState>;
    onChange?: (value: PoolContractActionState) => void;
    config: {
      size: ActionInlineType.Config.Size;
    };
  };
}

export type PoolContractActionState = {
  id?: string;
  parkingId?: string;
  isStopped?: boolean;
  status?: boolean;
  stopContract?: boolean;
};

export const PoolContractAction = ({
  initialState,
  config: { size },
}: PoolContractActionType.Props) => {
  const { t } = useTranslation();
  const { isStopped, id, status } = initialState || {
    isStopped: false,
  };

  const handleUpdate = async ({
    value,
    type,
    key,
  }: InlineActionChangeEvent) => {
    if (type && id) {
      switch (key) {
        case 'stopContract':
          if (id) {
            await requestGQL({
              gql: stopContractGql,
              params: { id },
            });
          }

          break;

        case 'status':
          await requestGQL({
            gql: updateContractGql,
            params: {
              contractId: id,
              input: { isEnabled: value },
            },
          });
          break;
      }
    }
  };

  const getActions = (
    isStopped?: boolean,
    id?: string,
    status?: boolean,
  ): ActionInlineType.Data[] => {
    let actions: ActionInlineType.Data[] = [
      {
        key: 'status',
        text: t('contractStatus'),
        color: 'label',
        event: handleUpdate,
        switch: {
          isActive: initialState!!.status || false,
          text: { active: t('active'), inactive: t('suspended') },
          color: { active: 'success', inactive: 'warn' },
        },
      },
    ];

    if (!isStopped && id) {
      actions = [
        ...actions,
        {
          key: 'stopContract',
          text: t('stopContract'),
          color: 'error',
          event: handleUpdate,
          isCritical: true,
        },
      ];
    }

    return actions;
  };

  return (
    <ActionInline config={{ size }} data={getActions(isStopped, id, status)} />
  );
};
