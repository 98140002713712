import { useTranslation } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { DateTime } from 'luxon';
import { APBModeGlobalName } from '../../common/mapper/abp.mapper';

export declare namespace ContractWizardInfosType {
  type Props = {
    data: {
      productName?: string | null;
      APBMode: APBModeGlobalName | null;
      APBNext: boolean | null;
      state: boolean | null;
      startDate: string | null;
      endDate: string | null;
      reference: string | null;
      description: string | null;
      capacity?: number | null;
    };
  };
}

export const ContractWizardInfos = ({
  data: {
    productName,
    APBMode,
    APBNext,
    state,
    startDate,
    endDate,
    reference,
    description,
    capacity,
  },
}: ContractWizardInfosType.Props) => {
  const { t, lang } = useTranslation();

  return (
    <Wizard.CardResume
      data={{
        title: t('contractInformations'),
        resume: [
          {
            label: t('product'),
            value: productName ? productName : t('notDefined'),
            color: productName ? 'text' : 'disabled',
            show: productName !== undefined,
          },
          {
            label: t('APBMode'),
            value: APBMode ? t(APBMode.toLowerCase()) : t('notDefined'),
            color: APBMode ? 'text' : 'disabled',
          },
          {
            label: t('APBNext'),
            value: APBNext ? t('active') : t('suspend'),
            color: APBNext ? 'success' : 'warn',
            show: typeof APBNext === 'boolean' && !!state,
          },
          {
            label: t('state'),
            value: state ? t('active') : t('suspend'),
            color: state ? 'success' : 'warn',
          },
          {
            label: t('startDate'),
            value: startDate
              ? DateTime.fromISO(startDate).toFormat('dd/MM/yy HH:mm:ss')
              : t('notDefined'),
            color: startDate ? 'text' : 'disabled',
          },
          {
            label: t('endDate'),
            value: endDate
              ? DateTime.fromISO(endDate).toFormat('dd/MM/yy HH:mm:ss')
              : t('notDefined'),
            color: endDate ? 'text' : 'disabled',
          },
          {
            label: t('reference'),
            value: reference ? reference : t('notDefined'),
            color: reference ? 'text' : 'disabled',
          },
          {
            label: t('description'),
            value: description ? description : t('notDefined'),
            color: description ? 'text' : 'disabled',
          },
          {
            label: t('capacity'),
            value: capacity ? `${capacity}` : t('notDefined'),
            color: capacity ? 'text' : 'disabled',
            show: capacity !== undefined,
          },
        ],
      }}
    />
  );
};
