import { Router, type RouterType } from '@gimlite/router';
import {
  useRootStore,
  navigationService,
  notifService,
  translationService,
  wizardService,
} from '@gimlite/watermelon';
import { useSelector } from '@xstate/react';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { appService } from './app.machine';
import { guestPathToPage, userPathToPage } from './sitemap';

export const CustomRouter = observer(() => {
  const { AuthStore } = useRootStore();

  const store = useSelector(appService, ({ context }: any) => context);

  const sitemap = useMemo(() => {
    return AuthStore.token ? userPathToPage : guestPathToPage;
  }, [AuthStore.token]);

  return (
    <Router
      context="browser"
      sitemap={sitemap as RouterType.SiteMap[]}
      store={store}
      machine={appService as any}
      modulesMachines={[
        notifService,
        wizardService,
        navigationService,
        translationService,
      ]}
    />
  );
});
