import { Icon, Size as SizeGlobal } from '@gimlite/watermelon';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import { Action, ActionType } from '@gimlite/osp';
import './action-inline.component.scss';

export declare namespace ActionInlineType {
  type Props = {
    className?: string;
    data: ActionInlineType.Data[];
    config?: {
      size?: ActionInlineType.Config.Size;
    };
  };

  type Data = ActionType.Data;

  namespace Config {
    type Size = Extract<SizeGlobal, 'list' | 'xlarge'>;
  }
}

export const ActionInline = ({
  data,
  config: { size = 'list' } = {},
  className = '',
}: ActionInlineType.Props) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`action-inline ${className}`}
      style={{
        visibility: !!data.find(({ show = true }) => show !== false)
          ? 'inherit'
          : 'hidden',
      }}
    >
      {data && Array.isArray(data) && data.length > 0 && (
        <Popover
          data={<Action data={data} />}
          config={{ placement: 'bottomRight' }}
        >
          <div
            onClick={(e) => {
              // e.stopPropagation()
            }}
            className="action-inline__pointer"
          >
            <Icon
              config={{
                type: 'faEllipsisVerticalSolid',
                color: 'label',
                size: size,
              }}
            ></Icon>
          </div>
        </Popover>
      )}
    </div>
  );
};
