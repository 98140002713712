import { IconType } from '@gimlite/watermelon';

export const iconGlobalEntity: Record<string, IconType.Config.Type> = {
  motoristContractPool: 'customCardUserBCDoubleLine',
  motoristContractSolo: 'faAddressCardSolid',
  subPoolContract: 'customCardUserTLSingleLine',
  masterPoolContract: 'customCardUserBRSingleLine',
  session: 'faClockSolid',
  motorist: 'faUserSolid',
  incident: 'faCircleExclamationSolid',
  breakdown: 'faTriangleExclamationSolid',
  car: 'faCarSolid',
  contractTinyCard: 'faAddressCardSolid',
  expanded: 'faChevronDownSolid',
  collapsed: 'faChevronRightSolid',
};
