import {
  ActionInline,
  ActionInlineType,
} from '@gimlite/osp/components/action-inline/action-inline.component';
import { requestGQL, useTranslation } from '@gimlite/watermelon';
import { useState } from 'react';
import { sendConfirmaionMail } from '../../common/gql/send-confirmation-mail.gql';
import { stopContractGql } from '../../common/gql/stop-contract.gql';
import { switchApbNextGql } from '../../common/gql/switch-apb-next.gql';
import { updateContractGql } from '../../common/gql/update-contract.gql';
import { InlineActionChangeEvent } from './pool-contract.action';

export declare namespace MotoristContractActionType {
  type Props = {
    initialState?: MotContractActionState;
    onChange?: (value: MotContractActionState) => void;
    config: { size: ActionInlineType.Config.Size };
  };
}

export type MotContractActionState = {
  id?: string;
  status?: boolean;
  parkingId?: string;
  APBNext?: boolean;
  isStopped?: boolean;
  freeflag?: boolean | null;
  motoristId?: string;
  category?: string;
};

export const MotoristContractAction = ({
  initialState,
  config: { size },
}: MotoristContractActionType.Props) => {
  const { t } = useTranslation();
  const { isStopped, id, parkingId, motoristId, APBNext, status, category } =
    initialState || {};
  const [apb, setApb] = useState<boolean>(APBNext || false);

  const handleUpdate = async ({
    value,
    type,
    key,
  }: InlineActionChangeEvent) => {
    if (type && id) {
      switch (key) {
        case 'stopContract':
          if (id) {
            await requestGQL({
              gql: stopContractGql,
              params: { id },
            });
          }

          break;

        case 'APBNext':
          const { apbNext } = await requestGQL({
            gql: switchApbNextGql,
            params: { contractId: id, parkingId },
          });
          setApb(!apbNext);

          break;

        case 'sendInvitation':
          await requestGQL({
            gql: sendConfirmaionMail,
            params: {
              input: { contractId: id, motoristId },
            },
          });
          break;

        case 'status':
          await requestGQL({
            gql: updateContractGql,
            params: { contractId: id, input: { isEnabled: value } },
          });
          break;
      }
    }
  };

  const getActions = (
    isStopped?: boolean,
    id?: string,
    status?: boolean,
  ): ActionInlineType.Data[] => {
    let actions: ActionInlineType.Data[] = [
      {
        key: 'status',
        text: t('contractStatus'),
        color: 'label',
        event: handleUpdate,
        switch: {
          isActive: initialState!!.status || false,
          text: { active: t('active'), inactive: t('suspended') },
          color: { active: 'success', inactive: 'warn' },
        },
      },
    ];

    if (parkingId && !isStopped && status) {
      actions = [
        ...actions,
        {
          key: 'APBNext',
          text: t('APBNext'),
          color: 'label',
          event: handleUpdate,
          switch: {
            isActive: apb,
            text: { active: t('active'), inactive: t('suspended') },
            color: { active: 'success', inactive: 'warn' },
          },
        },
      ];
    }

    if (
      id &&
      !isStopped &&
      ((category && category === 'MOTORIST_POOL') || !category)
    ) {
      actions = [
        ...actions,
        {
          key: 'stopContract',
          text: t('stopContract'),
          color: 'error',
          isCritical: true,
          event: handleUpdate,
        },
      ];
    }

    if (motoristId) {
      actions = [
        ...actions,
        {
          key: 'sendInvitation',
          text: t('sendInvitation'),
          color: 'label',
          event: handleUpdate,
        },
      ];
    }

    return actions;
  };

  return (
    <ActionInline config={{ size }} data={getActions(isStopped, id, status)} />
  );
};
