import { EventObject, assign, createMachine, interpret } from 'xstate';
import { AddOneMotoristToAnExistingPoolContractWizardContext } from './addOneMotoristToAnExistingPoolContract.wizard';

export type AddOneSeveralMotoristToAnExistingPoolContractServiceContextType = {
  parkingId: AddOneMotoristToAnExistingPoolContractWizardContext['parkingId'];
  skipSubContract: boolean;
  mode: AddOneMotoristToAnExistingPoolContractWizardContext['mode'];
  error: string | null;
};

const addOneSeveralMotoristToAnExistingPoolContractServiceContext: AddOneSeveralMotoristToAnExistingPoolContractServiceContextType =
  {
    parkingId: null,
    skipSubContract: false,
    mode: 'operator',
    error: null,
  };

const machine = createMachine({
  id: 'addOneSeveralMotoristToAnExistingPoolContract',
  initial: 'off',
  context: addOneSeveralMotoristToAnExistingPoolContractServiceContext,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
          actions: assign({
            parkingId: (
              _,
              { parkingId }: EventObject & { parkingId: string },
            ) => parkingId,
            mode: (
              _,
              {
                mode,
              }: EventObject & {
                mode: AddOneMotoristToAnExistingPoolContractWizardContext['mode'];
              },
            ) => {
              return (
                mode ||
                addOneSeveralMotoristToAnExistingPoolContractServiceContext.mode
              );
            },
          }),
        },
      } as any,
    },
    idle: {
      on: {
        KILL: {
          target: 'off',
        },
        SKIP_SUB_CONTRACT: {
          actions: assign({
            skipSubContract: (_, { payload }: { payload: boolean }) => {
              return payload;
            },
          }),
        },
      } as any,
    },
    failure: {
      on: {
        RETRY: {
          actions: assign({ error: null }),
          target: 'idle',
        },
      },
    },
  },
});

export const addOneMotoristToAnExistingPoolContractService =
  interpret(machine).start();
