import { gql } from "@apollo/client";

export const contractSessionsGql = gql`
    query contractSessions(
        $contractId: String!, 
        $category:String!, 
        $sessionPage: Int, 
        $sessionLimit: Int,
        $status: [String]
        $type: [String]
        $device: [String]
    ) {

        contractSessions(
            contractId: $contractId, 
            category: $category, 
            page: $sessionPage, 
            limit: $sessionLimit,
            status: $status
            type: $type
            device: $device
        ) {

            list {
                _id
                entryDate
                exitDate
                inconsistencyReasons
                isInconsistent
                parkingId

                parking {
                    _id
                    accessPoints {
                        _id
                        shortCode
                    }
                }

                events {
                    accessPointId
                    createdAt
                    state
                    reason
                    credentials {
                        type
                        provider
                        value
                        description
                    }
                }
                
                motorist {
                    _id
                    firstName
                    lastName
                }
                
                product {
                    name
                    category
                }
            }
            
            paging {
                count
                current
                limit
            }
        }
    }
`