import { ContractMotoristLine } from '@gimlite/osp/composition/line/contract-motorist.line';
import { MotoristLine } from '@gimlite/osp/composition/line/motorist.line';
import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationForm } from '../../../../form/confirmation/confirmation.form';
import { CreateNewSingleContractWizardContext } from '../../createNewSingleContract.wizard';

export const ConfirmationStep = ({
  context: {
    product: { id: productId },
    contract,
    motorist: { create },
    credentials,
    motoristSearchSelect,
  },
}: WizardItemType.Config.Component<CreateNewSingleContractWizardContext>) => {
  const { t, lang } = useTranslation();

  const [allFeatures, setAllFeatures] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const features: JSX.Element[] = [];

    if (!motoristSearchSelect) {
      features.push(
        <MotoristLine
          data={{
            firstName: create.firstName,
            lastName: create.lastName,
            email: create.username,
            status: 'UNVERIFIED',
          }}
          config={{ action: false, icon: true }}
        />,
      );
    }

    features.push(
      <ContractMotoristLine
        data={{
          firstName: create.firstName,
          lastName: create.lastName,
          contractId: t('willBeGenerated'),
          status: contract.state ? 'ACTIVE' : 'SUSPENDED',
        }}
        config={{ action: false }}
      />,
    );

    setAllFeatures(features);
  }, [lang]);

  return (
    <Zone
      config={{
        gap: {
          y: 1,
          x: 1,
        },
        zones: [['info', 'list']],
        rows: ['1fr'],
        columns: ['min-content', '1fr'],
      }}
    >
      <Zone.Area config={{ area: 'info' }}>
        <ContractWizardInfos
          data={{
            productName: productId ? t(`product-${productId}-name`) : null,
            APBMode: contract.APBMode,
            APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
            state: contract.state,
            startDate: contract.startDate,
            endDate: contract.endDate,
            description: contract.description,
            reference: contract.reference,
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'list' }}>
        <ConfirmationForm
          data={{
            list: allFeatures.map((node) => {
              return {
                _id: uuidv4(),
                items: {
                  component: node,
                },
              };
            }),
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
