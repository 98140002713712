import { ContractWizardInfos } from '@gimlite/osp/composition/wizard-infos/contract.wizard-infos';
import {
  Checkbox,
  Col,
  Input,
  Row,
  Select,
  Space,
  countries,
  languages,
} from '@gimlite/watermelon';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { CreateNewMasterPoolContractWizardContext } from '../../createNewMasterPoolContract.wizard';

export const TenantStep = ({
  context: { product, contract, tenant, poolAdmin },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateNewMasterPoolContractWizardContext>) => {
  const { t, lang } = useTranslation();

  return (
    <Form
      data={{ value: tenant }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: ({
          username,
          firstName,
          lastName,
          phone,
          lang,
          ...rest
        }: any) => {
          submit({
            tenant: { ...rest },
            poolAdmin: { username, firstName, lastName, phone, lang },
          });
        },
        validate: (value: boolean) => {
          validate(value);
        },
      }}
    >
      <Zone
        config={{
          gap: {
            y: 1,
            x: 1,
          },
          zones: [['info', 'form']],
          rows: ['1fr'],
          columns: ['min-content', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'info' }}>
          <ContractWizardInfos
            data={{
              productName: product.id ? t(`product-${product.id}-name`) : null,
              APBMode: contract.APBMode,
              APBNext: contract.APBMode !== 'NONE' ? contract.APBNext : null,
              state: contract.state,
              startDate: contract.startDate,
              endDate: contract.endDate,
              description: contract.description,
              reference: contract.reference,
              capacity: contract.capacity,
            }}
          />
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          <Zone
            config={{
              gap: {
                y: 1,
                x: 1,
              },
              zones: [['part1', 'part2']],
              rows: ['min-content', '1fr'],
              columns: ['1fr', '1fr'],
            }}
          >
            <Zone.Area config={{ area: 'part1' }}>
              <Zone
                config={{
                  zones: [['identity'], ['address']],
                  rows: ['min-content', '1fr'],
                  columns: ['1fr'],
                }}
              >
                <Zone.Area config={{ area: 'identity' }}>
                  <Widget config={{ title: t('identity'), shadow: false }}>
                    <Col>
                      <Row>
                        <Form.Item
                          data={{ defaultValue: tenant.name1 }}
                          config={{
                            name: 'name1',
                            label: t('name1'),
                            way: 'vertical',
                            rules: [{ required: true }],
                          }}
                        >
                          <Input
                            config={{
                              width: 'full',
                            }}
                          />
                        </Form.Item>
                        <Space config={{ way: 'horizontal', count: 2 }} />
                        <Form.Item
                          data={{ defaultValue: tenant.name2 }}
                          config={{
                            name: 'name2',
                            label: t('name2'),
                            way: 'vertical',
                          }}
                        >
                          <Input
                            config={{
                              width: 'full',
                            }}
                          />
                        </Form.Item>
                      </Row>
                    </Col>
                    <Space config={{ count: 2 }} />
                  </Widget>
                </Zone.Area>

                <Zone.Area config={{ area: 'address' }}>
                  <Widget config={{ title: t('address'), shadow: false }}>
                    <Col>
                      <Form.Item
                        data={{ defaultValue: tenant.address1 }}
                        config={{
                          name: 'address1',
                          label: t('address1'),
                          way: 'vertical',
                        }}
                      >
                        <Input
                          config={{
                            width: 'full',
                          }}
                        />
                      </Form.Item>

                      <Space config={{ count: 2 }} />

                      <Form.Item
                        data={{ defaultValue: tenant.address2 }}
                        config={{
                          name: 'address2',
                          label: t('address2'),
                          way: 'vertical',
                        }}
                      >
                        <Input
                          config={{
                            width: 'full',
                          }}
                        />
                      </Form.Item>

                      <Space config={{ count: 2 }} />
                      <Row>
                        <Form.Item
                          data={{ defaultValue: tenant.zipCode }}
                          config={{
                            name: 'zipCode',
                            label: t('zipCode'),
                            way: 'vertical',
                          }}
                        >
                          <Input
                            config={{
                              width: 'small',
                            }}
                          />
                        </Form.Item>
                        <Space config={{ way: 'horizontal', count: 2 }} />

                        <Form.Item
                          data={{ defaultValue: tenant.city }}
                          config={{
                            name: 'city',
                            label: t('city'),
                            way: 'vertical',
                          }}
                        >
                          <Input
                            config={{
                              width: 'full',
                            }}
                          />
                        </Form.Item>
                      </Row>
                      <Space config={{ count: 2 }} />
                      <Form.Item
                        data={{
                          defaultValue: tenant.country,
                        }}
                        config={{
                          name: 'country',
                          label: t('country'),
                          way: 'vertical',
                        }}
                      >
                        <Select
                          data={{
                            items: lang
                              ? countries[lang].map(({ label, value }) => ({
                                  label,
                                  value: value.ISO3,
                                }))
                              : [],
                          }}
                          config={{
                            width: 'full',
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Widget>
                </Zone.Area>
              </Zone>
            </Zone.Area>

            <Zone.Area config={{ area: 'part2' }}>
              <Zone
                config={{
                  zones: [['configuration'], ['poolAdmin']],
                  rows: ['min-content', '1fr'],
                  columns: ['1fr'],
                }}
              >
                <Zone.Area config={{ area: 'configuration' }}>
                  <Widget config={{ title: t('configuration'), shadow: false }}>
                    <Col>
                      <Form.Item
                        data={{
                          defaultValue: tenant.hasAdminPool,
                        }}
                        config={{
                          name: 'hasAdminPool',
                          label: t('isThereAnAdminPool'),
                          way: 'horizontal',
                        }}
                      >
                        <Checkbox
                          config={{
                            size: 'xlarge',
                          }}
                        />
                      </Form.Item>
                      <Space config={{ count: 2 }} />
                      <Form.Item
                        data={{
                          defaultValue: tenant.canCreateSubContract,
                        }}
                        config={{
                          name: 'canCreateSubContract',
                          label: t('contractPartnerCanCreateSubContract'),
                          way: 'horizontal',
                        }}
                      >
                        <Checkbox
                          config={{
                            size: 'xlarge',
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Widget>
                </Zone.Area>

                <Zone.Area config={{ area: 'poolAdmin' }}>
                  {tenant.hasAdminPool ? (
                    <Widget config={{ title: t('poolAdmin'), shadow: false }}>
                      <Col>
                        <Form.Item
                          data={{ defaultValue: poolAdmin.username }}
                          config={{
                            name: 'username',
                            label: t('email'),
                            rules: [{ required: true }],
                            way: 'vertical',
                          }}
                        >
                          <Input
                            config={{
                              width: 'full',
                            }}
                          />
                        </Form.Item>
                        <Space config={{ count: 2 }} />
                        <Row>
                          <Form.Item
                            data={{ defaultValue: poolAdmin.firstName }}
                            config={{
                              name: 'firstName',
                              label: t('firstName'),
                              rules: [{ required: true }],
                              way: 'vertical',
                            }}
                          >
                            <Input
                              config={{
                                width: 'small',
                              }}
                            />
                          </Form.Item>
                          <Space config={{ way: 'horizontal', count: 2 }} />
                          <Form.Item
                            data={{ defaultValue: poolAdmin.lastName }}
                            config={{
                              name: 'lastName',
                              label: t('lastName'),
                              way: 'vertical',
                            }}
                          >
                            <Input
                              config={{
                                width: 'full',
                              }}
                            />
                          </Form.Item>
                        </Row>
                        <Space config={{ count: 2 }} />
                        <Form.Item
                          data={{ defaultValue: poolAdmin.phone }}
                          config={{
                            name: 'phone',
                            label: t('phone'),
                            way: 'vertical',
                          }}
                        >
                          <Input
                            config={{
                              width: 'full',
                            }}
                          />
                        </Form.Item>
                        <Space config={{ count: 2 }} />
                        <Form.Item
                          data={{
                            defaultValue: poolAdmin.lang,
                          }}
                          config={{
                            name: 'lang',
                            label: t('language'),
                            rules: [{ required: true }],
                            way: 'vertical',
                          }}
                        >
                          <Select
                            data={{
                              items: lang ? languages[lang] : [],
                            }}
                            config={{
                              width: 'full',
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Widget>
                  ) : (
                    <></>
                  )}
                </Zone.Area>
              </Zone>
            </Zone.Area>
          </Zone>
        </Zone.Area>
      </Zone>
    </Form>
  );
};
