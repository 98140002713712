import { gql } from '@apollo/client';
import { APBModeGlobalName } from '../mapper/abp.mapper';

export type CreatePoolResponse = {
  _id: string;
  ospContractId: string;
  name1: string;
  name2: string | null;
  address1: string | null;
  address2: string | null;
  zipcode: string | null;
  city: string | null;
  country: string | null;
  isEnabled: boolean;
  createdAt: string;
  defaultPoolId: string;
};

export type CreatePool = {
  input: {
    tenant: {
      name1: string;
      name2: string | null;
      address1: string | null;
      address2: string | null;
      city: string | null;
      zipcode: string | null;
      country: string | null;
      tenantCanCloseSession: boolean | null;
      tenantCanCreateSubContract: boolean | null;
    };
    pool: {
      parkingId: string | null;
      productId: string | null;
      startDate: string | null;
      stopDate: string | null;
      isEnabled: boolean | null;
      spacesAllocated: number | null;
      reference: string | null;
      description: string | null;
      antiPassBackMode: APBModeGlobalName | null;
    };
    user: {
      username: string | null;
      firstName: string | null;
      lastName: string | null;
      lang: string | null;
      phone: string | null;
    };
  };
};

export const createPoolGQL = gql`
  mutation createPool($input: CreatePoolDto!) {
    createPool(input: $input) {
      _id
      ospContractId
      name1
      name2
      address1
      address2
      zipcode
      city
      country
      createdAt
      defaultPoolId
      isEnabled
    }
  }
`;
