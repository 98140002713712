import { useTranslation } from '@gimlite/watermelon';
import {
  Timeline,
  TimelineType,
} from '../../components/timeline/timeline.component';

export declare namespace PedestrianAccessSessionTimelineType {
  type Props = {
    data: {
      date: TimelineType.Data.Date;
      extra?: TimelineType.Data.Extra;
      device: TimelineType.Data.Device;
    };
  };
}

export const PedestrianAccessSessionTimeline = ({
  data: { date, extra, device },
}: PedestrianAccessSessionTimelineType.Props) => {
  const { t } = useTranslation();
  return (
    <Timeline
      config={{
        color: 'success',
      }}
      data={{
        icon: 'faPersonWalkingSolid',
        date,
        device,
        title: t('global-state-authorize-pedestrian'),
        extra,
      }}
    />
  );
};
