import { IEMMachineBuilder } from '@gimlite/watermelon';
import { loginGql } from '@gimlite/osp';

export type LoginResponse = {
  token: string;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
    operator: {
      _id: string;
      name: string;
      code: string;
    };
  };
};

export const loginService = new IEMMachineBuilder('token')
  .withCreate(loginGql)
  .run();
