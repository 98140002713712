import { gql } from '@apollo/client';
import { Motorist } from '../types/entities/motorist';

export type VerifyAndUpdateMotoristResponse = {
  motorist: Motorist;
};

export type VerifyAndUpdateMotorist = {
  input: {
    token: string;
    motorist: {
      username?: string;
      firstName?: string;
      lastName?: string;
      password?: string;
      credentials?: Credential[];
      birthDate?: string;
      address1?: string;
      address2?: string;
      zipcode?: string;
      city?: string;
      country?: string;
      phone?: string;
      lang?: string;
      gender?: string;
    };
  };
};

export const verifyAndUpdateMotoristGQL = gql`
  mutation verifyAndUpdateMotorist($input: SetVerifyDto!) {
    verifyAndUpdateMotorist(input: $input) {
      motorist {
        _id
        username
        firstName
        lastName
        address1
        address2
        isAnonymous
        zipcode
        city
        state
        country
        phone
        lang
        gender
      }
    }
  }
`;
