import { MainUse } from './main';
import { breakdownsService } from './pages/breakdowns/breakdowns.machine';
import { BreakdownsPage } from './pages/breakdowns/view/breakdowns.page';
import { contractsService } from './pages/contracts/contracts.machine';
import { ContractPage } from './pages/contracts/view/contracts.page';
import { DashboardPage } from './pages/dashboard/view/dashboard.page';
import { incidentsService } from './pages/incidents/incidents.machine';
import { IncidentPage } from './pages/incidents/view/incidents.page';
import { loginService } from './pages/login/login.machine';
import { LoginPage } from './pages/login/view/login.page';
import { MotoristService } from './pages/motorist-confirm/motorist-confirm.machine';
import { MotoristConfirm } from './pages/motorist-confirm/view/motorist-confirm';
import { MotoristConfirmLite } from './pages/motorist-confirm/view/motorist-confirm-lite';
import { motoristsService } from './pages/motorists/motorists.machine';
import { MotoristPage } from './pages/motorists/view/motorists.page';
import { OutOfServicePage } from './pages/out-of-service/view/out-or-service';
import { ProductPage } from './pages/products/products.page';
import { sessionsService } from './pages/sessions/sessions.machine';
import { SessionPage } from './pages/sessions/view/session.page';

export const outOfOrderPathToPage = [
  {
    path: '/verificator',
    Component: MotoristConfirm,
    machine: MotoristService,
  },
  {
    path: '*',
    Component: OutOfServicePage,
  },
];

export const guestPathToPage = [
  {
    path: '/verificator',
    Component: MotoristConfirm,
    machine: MotoristService,
  },
  {
    path: '*',
    Component: LoginPage,
    machine: loginService,
  },
];

export const userPathToPage = [
  {
    path: '/',
    Component: MainUse,
    children: [
      {
        path: '/:parkingId/dashboard',
        Component: DashboardPage,
      },
      {
        path: '/verificator',
        Component: MotoristConfirmLite,
        machine: MotoristService,
      },
      {
        path: '/:parkingId/sessions',
        Component: SessionPage,
        machine: sessionsService,
      },
      {
        path: '/:parkingId/sessions/:sessionId',
        Component: SessionPage,
        machine: sessionsService,
      },
      {
        path: '/:parkingId/contracts',
        Component: ContractPage,
        machine: contractsService,
      },
      {
        path: '/:parkingId/contracts/:contractId',
        Component: ContractPage,
        machine: contractsService,
      },
      {
        path: '/:parkingId/breakdowns',
        Component: BreakdownsPage,
        machine: breakdownsService,
      },
      {
        path: '/:parkingId/breakdowns/:breakdownId',
        Component: BreakdownsPage,
        machine: breakdownsService,
      },
      {
        path: '/:parkingId/incidents',
        Component: IncidentPage,
        machine: incidentsService,
      },
      {
        path: '/:parkingId/incidents/:incidentId',
        Component: IncidentPage,
        machine: incidentsService,
      },
      {
        path: '/:parkingId/products',
        Component: ProductPage,
      },
      {
        path: '/motorists',
        Component: MotoristPage,
        machine: motoristsService,
      },
      {
        path: '/motorists/:motoristId',
        Component: MotoristPage,
        machine: motoristsService,
        handle: {
          navKey: '/motorists',
        },
      },
    ],
  },
];
